import React, { useRef, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { HiOutlineInformationCircle } from "react-icons/hi";
import Papa from "papaparse";
import { requiredColumnsDelete, documentTypes } from "./data";
import Loading from "components/Loading";
import { post } from "utils";

const DeleteEmployFile = () => {
  // Hooks
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [results, setResults] = useState([]);

  const sendToBackend = (data) => {
    post("deleteMultipleUsers", JSON.stringify(data))
      .then((res) => {
        const { data } = res;
        setLoading(false);
        setResults(data);
        resetFileInput();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setResults([
          "Sucedió un error intentando eliminar los datos, por favor contactar al gerente de cuenta.",
        ]);
      });
  };

  const preValidation = (results) => {
    const errors = [];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberPattern = /^\d+$/;

    if (results?.data?.length > 0) {
      const firstRowKeys = Object.keys(results.data[0]);
      requiredColumnsDelete.forEach((column) => {
        if (!firstRowKeys.includes(column)) {
          errors.push(`Falta la columna requerida: ${column}`);
        }
      });

      firstRowKeys.forEach((key) => {
        if (!requiredColumnsDelete.includes(key)) {
          errors.push(`Columna no reconocida: ${key}`);
        }
      });

      results.data.forEach((row, index) => {
        if (
          row.tipo_de_documento &&
          !documentTypes.includes(row.tipo_de_documento)
        ) {
          errors.push(
            `Fila ${index + 2}: 'tipo_de_documento' inválido. ${
              row.tipo_de_documento
            }`
          );
        }

        if (!row.correo_electronico) {
          errors.push(`Fila ${index + 2}: 'Email' es obligatorio.`);
        }

        if (!emailPattern.test(row.correo_electronico)) {
          errors.push(
            `Fila ${index + 2}: 'correo_electronico' inválido. ${
              row.correo_electronico
            }`
          );
        }

        if (!numberPattern.test(row.numero_de_documento)) {
          errors.push(
            `Fila ${
              index + 2
            }: 'numero_de_documento' debe contener solo números. ${
              row.numero_de_documento
            }`
          );
        }
        row.numero_de_documento = row.numero_de_documento.toString();
      });
    } else {
      errors.push("El archivo está vacío o no se pudo leer correctamente.");
    }

    if (errors.length === 0) {
      sendToBackend(results.data);
    } else {
      setErrors(errors);
      setLoading(false);
      resetFileInput();
    }
  };

  const fileInput = useRef(null);

  const handleLabelClick = () => {
    fileInput.current.click();
  };

  const handleFileInput = (e) => {
    setErrors([]);
    setResults([]);
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== "text/csv") {
      setErrors((prevErrors) => [
        ...prevErrors,
        "Por favor, sube solo archivos CSV.",
      ]);
      return;
    }

    Papa.parse(file, {
      complete: function (results) {
        setLoading(true);
        preValidation(results);
      },
      header: true,
      dynamicTyping: true,
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setResults([]);
    setErrors([]);
    const file = e.target.files[0];

    if (!file) return;

    if (file.type !== "text/csv") {
      setErrors((prevErrors) => [
        ...prevErrors,
        "Por favor, sube solo archivos CSV.",
      ]);
      return;
    }
    Papa.parse(file, {
      complete: function (results) {
        setLoading(true);
        preValidation(results);
      },
      header: true,
      dynamicTyping: true,
    });
  };

  const resetFileInput = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <label
        onClick={handleLabelClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer"
      >
        <div className="flex justify-center">
          <BsCloudUpload className="h-11 w-11 text-gray-400 text-center" />
        </div>
        <span className="mt-2 block text-md font-semibold text-gray-900">
          Arrastra y suelta aquí o selecciona un archivo desde tu ordenador.
        </span>
        <span className="mt-2 block text-sm text-gray-700">
          Solo los archivos CSV son compatibles.
        </span>
      </label>
      <input
        type="file"
        ref={fileInput}
        onChange={handleFileInput}
        style={{ display: "none" }}
        accept=".csv"
      />

      {errors.length > 0 && (
        <div className="py-4">
          <h2 className="text-red-400 font-semibold">Errores</h2>
          <p className="text-sm text-gray-500">
            El archivo que cargaste no fue posible importarlo debio a los
            siguientes errores encontrados
          </p>
        </div>
      )}

      {errors.map((elem) => (
        <div className="mb-3">
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <MdCancel className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{elem}</h3>
              </div>
            </div>
          </div>
        </div>
      ))}
      {results.map((elem) => (
        <div className="my-3">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <HiOutlineInformationCircle
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-700">{elem}</h3>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeleteEmployFile;
