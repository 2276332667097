import React, { useState } from "react";
import PieChartExtend from "components/chart/PieChartOutline";
import { bankInformation } from "const/account";
import Activity from "./Activity";
import Card from "pages/dashboard/Card/Card";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";
import { AiOutlinePlusCircle } from "react-icons/ai";
import FormAddBank from "components/Form/FormAddBank";

function MainFill({ dataUser, dataBank, activity, dataCompany }) {
  // Modal Form to add Bank Account
  const [openFormBank, setOpenFormBank] = useState(false);

  const handleOpenFormAddBank = () => setOpenFormBank(true);
  return (
    <div className="col-span-1">
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1" id="days-worked">
          <div className="light-shadow text-center bg-white rounded-md">
            <div className="p-3 text-left">
              <h2
                className="text-base font-medium text-indigo-800"
                id="recent-hires-title"
              >
                Días Trabajados
              </h2>
            </div>
            <div className="pb-2">
              <PieChartExtend paymentPlan={dataCompany?.paymentType} />
            </div>
          </div>
        </div>
        <Activity activity={activity} />
        <div className="col-span-1" id="account-used">
          <div className="light-shadow text-center bg-white rounded-md">
            <div className="p-3 text-left">
              <h2
                className="text-base font-medium text-indigo-800"
                id="bankInfo"
              >
                Cuenta Bancaria
              </h2>
              {dataBank && dataBank.accountNumber ? (
                <Card
                  bankName={bankInformation[dataBank?.bank]}
                  name={`${dataUser.firstName} ${dataUser.lastName}`}
                  lastFourDigits={dataBank?.accountNumber}
                />
              ) : (
                <>
                  <FormAddBank
                    openFormBank={openFormBank}
                    setOpenFormBank={setOpenFormBank}
                    userId={dataUser.id}
                  />
                  <WarningAlertTailwind
                    body={"Aún no tienes cuenta bancaria asociada a tu usuario"}
                  />
                  <button
                    className="mt-3 hidden md:block cursor-pointer flex w-full items-center justify-center rounded-md 
              border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 
              shadow-sm hover:bg-gray-50"
                    onClick={handleOpenFormAddBank}
                  >
                    <span className="flex items-center justify-between">
                      Agregar cuenta bancaria
                      <AiOutlinePlusCircle className="ml-3 text-lg" />
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFill;
