/********************************************************
 * API request method provider leveraging the fetch API
 ********************************************************/

const request =
  (method) =>
  async (
    endpoint,
    body,
    url = process.env.REACT_APP_MAIN_BACK_CALL,
    debug = true
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    // CONFIGURE: headers
    const headers = {
      "content-type": "application/json",
    };
    if (token) headers["Authorization"] = `Bearer ${token}`;

    // CONIGURE: body
    if (body && typeof body === "object") body = JSON.stringify(body);

    const res = await fetch(`${url}/${endpoint}`, { method, body, headers });
    // Parse the response
    try {
      const data = await res.json();
      if (String(res.status)[0] !== "2") {
        throw data || "Unknown Error";
      }
      return data;
    } catch (e) {
      e?.message &&
        console.error(`1=> src > request > request.js 👉 Error: ${e?.message}`);
      return e.response?.data?.error;
    }
  };

export const del = request("DELETE");
export const get = request("GET");
export const post = request("POST");
export const put = request("PUT");
export const patch = request("PATCH");
