import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";

export default function ResponsiveDatePickers({
  value,
  setValue,
  control,
  name,
  endDate = dayjs(),
}) {
  const ruleValidate = {
    required: true,
  };

  if (name === "beginDate") {
    ruleValidate.validate = (value) => {
      return value <= endDate;
    };
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <DatePicker
            label=""
            openTo="year"
            views={["year", "month", "day"]}
            value={field.value}
            onChange={(newValue) => {
              setValue(newValue);
              field.onChange(newValue);
            }}
            InputProps={{
              style: {
                height: "44px",
              },
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        )}
        rules={ruleValidate}
      />
    </LocalizationProvider>
  );
}
