import React from "react";
import { BsArrowRight } from "react-icons/bs";

function BoxColor({ number, title, action, borderColor, showLink }) {
  return (
    <div className="col-span-1">
      <div className={`bg-white p-4 ${borderColor}`}>
        <div>
          <p className="text-4xl">{number}</p>
          <p>{title}</p>
        </div>
        <div className="mt-6 flex align-items-as justify-between text-blue-400">
          <div className="cursor-pointer text-sm" onClick={action}>
            {!showLink ? <p>Ver todos los adelantos</p> : <p>Ver más</p>}
          </div>
          <div className="cursor-pointer" onClick={action}>
            <BsArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxColor;
