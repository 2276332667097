import React, { useState } from "react";
import RefreshBreadcrumbs from "components/breadCrumbs/RefreshBreadcrumbs";
import CreateUser from "common/Employee/CreateUser";
import userService from "services/user.service";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function ContentCreateEmployee() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Usuarios",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Crear",
    },
  ];

  const [loading, setLoading] = useState(false);

  let { companyId } = useParams();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    data.company = companyId;
    userService
      .createUser(data)
      .then((res) => {
        if (res?.status !== 201) {
          throw new Error(data.message);
        }
        toast.success(res.data);
        setLoading(false);
        navigate(`/dashboard/${companyId}/usuarios/`);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
        navigate(`/dashboard/${companyId}/usuarios/`);
      });
  };

  return (
    <div>
      <div className="mt-2">
        <RefreshBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <h1 className="text-xl font-bold">Crear Usuario</h1>
      </div>
      <CreateUser
        isAdmin={false}
        onSubmit={onSubmit}
        companies={[companyId]}
        loading={loading}
      />
    </div>
  );
}

export default ContentCreateEmployee;
