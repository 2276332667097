import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import Loading from "components/Loading";
import { useForm } from "react-hook-form";
import { login } from "slices/auth";
import { endpoints } from "const/roles";
import { ccType } from "const/documentType";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function LoginInputUser() {
  // Imports
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Aux Variables
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const onSubmit = (data, event) => {
    setLoading(true);
    event.preventDefault();

    const userData = {
      document: data.document,
      typeOfDocument: data.typeOfDocument,
      password: data.password,
      role: endpoints.User,
    };

    dispatch(login(userData))
      .unwrap()
      .then(() => {
        navigate("/adelantos");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });

    if (isLoggedIn) {
      return <Navigate to="/adelantos" />;
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label for="document">Documento de Identidad</label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <select
                {...register("typeOfDocument", {
                  required: true,
                })}
                className="h-full bg-white rounded-md border-transparent bg-transparent py-0 pl-3  
                transition-all focus-within:shadow-lg"
              >
                {ccType.map((data) => (
                  <option value={data.val}>{data.name}</option>
                ))}
              </select>
            </div>
            <input
              {...register("document", {
                required: true,
                pattern: /^[0-9]+$/,
              })}
              placeholder="Ingresa tu documento de identidad"
              className="block w-full rounded-md bg-white overflow-hidden border-gray-300 bor-rad ruj-2 pl-16 
              transition-all focus-within:shadow-lg p-3"
            />
          </div>
          {errors?.document?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.document?.type === "pattern" && (
            <p className="text-red-400">
              El documento solo puede contener números
            </p>
          )}
        </div>
        <div className="mb-4">
          <label>Contraseña</label>
          <input
            {...register("password", {
              required: true,
            })}
            type="password"
            placeholder="Ingresa una contraseña"
            className="bg-white w-full p-3 overflow-hidden items-center bor-rad ruj-2 transition-all flex focus-within:shadow-lg"
          />
          {errors?.password?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
        </div>
        <div className="flex justify-end">
          <Link to="/recuperacionclave" className="text-red-500 underline">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        <div className="mt-4">
          <button
            disabled={loading}
            type="submit"
            className={`py-3 px-8 w-full ${
              loading ? "bg-red-300" : "bg-red-400 "
            } text-white 
          rounded-lg shadow-lg hover:bg-red-300 focus:ring-4 
          focus:ring-red-100 focus:outline-none`}
          >
            Ingresar
          </button>
        </div>
      </form>
      {message && (
        <div className="my-4">
          <p className="text-lg text-red-400 font-semibold">{message}</p>
        </div>
      )}
    </div>
  );
}

export default LoginInputUser;
