import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";
import { useSearchParams, useNavigate } from "react-router-dom";
import authService from "services/auth.service";
import { toast } from "react-toastify";

function EnableAccount() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const onSubmit = (data) => {
    setLoading(true);

    const codeOob = searchParams.get("oobCode");

    const userData = {
      password: data.password,
      code: codeOob,
    };

    authService
      .changePassword(userData)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setLoading(false);
        toast.success(data);
        navigate(`/login`);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
        navigate(`/login`);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-4">
          <div className="mb-4">
            <label>Nueva Contraseña</label>
            <input
              {...register("password", {
                required: true,
                minLength: 8,
              })}
              type="password"
              placeholder="Ingresa una contraseña"
              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
            />
            {errors?.password?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
            {errors?.password?.type === "minLength" && (
              <p className="text-red-400">
                La constraseña debe contener al menos 8 caracteres
              </p>
            )}
          </div>
          <div className="mb-4">
            <label>Confirmar Nueva Contraseña</label>
            <input
              {...register("passwordConfirmation", {
                required: "Este campo es obligatorio",
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || "Las contraseña no coincide ";
                  },
                },
              })}
              type="password"
              placeholder="Confirmar la contraseña"
              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
            />
            {errors.passwordConfirmation && (
              <p className="text-red-400">
                {errors.passwordConfirmation.message}
              </p>
            )}
          </div>
        </div>
        {loading && (
          <div className="flex justify-center my-4">
            <Loading />
          </div>
        )}
        <button
          type="submit"
          disabled={loading}
          className={`py-4 px-8 w-full ${
            loading ? "bg-red-300" : "bg-red-400 "
          } text-white 
                rounded-lg shadow-lg hover:bg-red-300 focus:ring-4 
                focus:ring-red-100 focus:outline-none`}
        >
          Ingresar
        </button>
      </form>
    </>
  );
}

export default EnableAccount;
