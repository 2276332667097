import React, { useMemo, useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

import advancesService from "services/advances.service";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import AlertModal from "components/modal/AlertModal";
import { toast } from "react-toastify";
import FormatNumber from "components/FormatNumber";

import { advanceStatus, sendAdvanceStatus } from "const/advanceStatus";
import Loading from "components/Loading";
import Chip from "@mui/material/Chip";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

function ContentAdvance() {
  const theme = useTheme();

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHomePage = useCallback(() => {
    setLoading(true);
    advancesService
      .getAllAdvances()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setContent(data.transactions);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  return (
    <div>
      {loading && <Loading />}
      <div className="pb-2 flex justify-between border-b-2 border-pink-600">
        <h1 className="text-xl">Adelantos</h1>
      </div>
      <div className="mt-3">
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableEmployee
            data={content}
            getHomePage={getHomePage}
            setLoading={setLoading}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableEmployee = ({ data, setLoading, getHomePage }) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [currentAdvance, setCurrentAdvance] = useState("");

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const navigate = useNavigate();

  const detailTransaction = (data) => {
    navigate(`${data.id}`, { state: data });
  };

  const openModalAction = (id, modalAction) => {
    if (modalAction === 1) {
      setOpenApprove(true);
    } else {
      setOpenComplete(true);
    }
    setCurrentAdvance(id);
  };

  const updateAdvance = (status) => {
    setLoading(true);
    setOpenComplete(false);
    setOpenApprove(false);

    const data = {
      status: status,
    };

    advancesService
      .updateAdvance(data, currentAdvance)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getHomePage();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "advanceAmount", //access nested data with dot notation
        header: "Valor de adelanto",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorKey: "companyInfo.name",
        header: "Empresa",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "userName",
        header: "Nombre Usuario",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorFn: (row) =>
          new Date(dayjs.tz(row.advanceDate, "America/Panama")), //convert to Date for sorting and filtering
        header: "Fecha", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => row.status, //convert to Date for sorting and filtering
        header: "Estado", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Pendiente", value: "PENDING" },
          { text: "En Progreso", value: "ACTIVE" },
          { text: "Cancelado", value: "DENIED" },
          { text: "Completado", value: "COMPLETED" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => advanceStatus[cell.getValue()], //render Date as a string
      },
    ],
    []
  );

  const approveAdvance = {
    title: "Aprobar Adelanto",
    logo: <AiOutlineCheckCircle className="text-6xl text-green-600" />,
    subTitle: "¿Está seguro de querer aprobar el adelanto?.",
    confirmation: "Aprobar",
    showCancelBtn: true,
  };

  const completeAdvance = {
    title: "Completar Adelanto",
    logo: <AiOutlineCheckCircle className="text-6xl text-green-600" />,
    subTitle: "¿Está seguro de querer completar el adelanto?.",
    confirmation: "Completar",
    showCancelBtn: true,
  };

  return (
    <>
      <AlertModal
        open={openApprove}
        setOpen={setOpenApprove}
        info={approveAdvance}
        deleteFunction={() => {
          updateAdvance(sendAdvanceStatus.ACTIVE);
        }}
      />
      <AlertModal
        open={openComplete}
        setOpen={setOpenComplete}
        info={completeAdvance}
        deleteFunction={() => {
          updateAdvance(sendAdvanceStatus.COMPLETED);
        }}
      />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowActions
        enableColumnFilterModes
        positionActionsColumn="last"
        initialState={{ showGlobalFilter: true }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "0.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "0.8rem",
          },
        }}
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: "0.5rem",
            }}
          >
            {!row.original.deleteUser ? (
              <>
                <FaRegEye
                  onClick={() => detailTransaction(row.original)}
                  className="text-xl color-mid mr-2"
                />
                <ChipLabel
                  status={row.original.status}
                  id={row.original?.id}
                  openModalAction={openModalAction}
                />
              </>
            ) : (
              <span>Usuario Eliminado</span>
            )}
          </div>
        )}
      />
    </>
  );
};

function ChipLabel({ status, id, openModalAction }) {
  const statusDisplay = {
    PENDING: {
      label: "Aprobar",
      color: "primary",
      status: "ACTIVE",
      modalAction: 1,
    },
    ACTIVE: {
      label: "Completar",
      color: "success",
      status: "COMPLETED",
      modalAction: 2,
    },
  };

  const isInStatus = ["PENDING", "ACTIVE"].indexOf(status) >= 0;
  const infoAdvance = statusDisplay[status];

  return (
    <>
      {isInStatus && (
        <div className="mr-2">
          <Chip
            label={infoAdvance.label}
            onClick={() => openModalAction(id, infoAdvance.modalAction)}
            color={infoAdvance.color}
            variant="outlined"
          />
        </div>
      )}
    </>
  );
}

export default ContentAdvance;
