import React, { useMemo, useState } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Button } from "components/button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";

import AlertModal from "components/modal/AlertModal";
import { toast } from "react-toastify";
import userService from "services/user.service";
import Loading from "components/Loading";
import Chip from "@mui/material/Chip";
import FlyoutMenu from "components/flyoutMenu/FlyoutMenu";

const menuTable = [
  {
    name: "Importar Usuarios",
    description: "Importar de forma masiva empleados",
    href: "#",
    icon: TbEdit,
  },
];

function ContentEmployee({ data, renderCols, getEmployeeData }) {
  const theme = useTheme();
  return (
    <div>
      <div className="pb-2 flex justify-between items-center border-b-2 border-pink-600">
        <h1 className="text-xl">Usuarios</h1>
        <FlyoutMenu menu={menuTable} />
      </div>
      <div className="mt-3">
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableEmployee
            data={data}
            renderCols={renderCols}
            getEmployeeData={getEmployeeData}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableEmployee = ({ data, renderCols, getEmployeeData }) => {
  const [openDisable, setOpenDisable] = useState(false);
  const [openEnable, setOpenEnable] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const detailUser = (user) => {
    navigate(`${user.id}`, {
      state: {
        from: location.pathname,
      },
    });
  };

  const editDetailUser = (user) => {
    navigate(`${user.id}/editar`, { state: { from: location.pathname } });
  };

  const handleOpenDisable = (userId) => {
    setOpenDisable(true);
    setCurrentUser(userId);
  };

  const handleOpenEnable = (userId) => {
    setOpenEnable(true);
    setCurrentUser(userId);
  };

  const changeStatusUser = (status) => {
    setLoading(true);
    setOpenDisable(false);
    setOpenEnable(false);
    userService
      .changeStatusUser(currentUser, status)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getEmployeeData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const deleteUsers = () => {
    setLoading(true);
    setDeleteUser(false);
    const parseUsers = Object.keys(rowSelection);
    userService
      .deleteUsers(parseUsers)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getEmployeeData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  //should be memoized or stable
  const columns = useMemo(() => renderCols, []);

  const disableUserModal = {
    title: "Desactivar Usuario",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    subTitle: "¿Está seguro de querer desactivar el usuario?",
    confirmation: "Desactivar",
    showCancelBtn: true,
    bgColor: "bg-red-100",
  };

  const deleteUserModal = {
    title: "Eliminar Usuario",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    subTitle:
      "¿Está seguro de querer eliminar todos los usuarios seleccionados?. Esta acción no se podrá deshacer.",
    confirmation: "Eliminar",
    showCancelBtn: true,
    bgColor: "bg-red-100",
  };

  const enableUserModal = {
    title: "Activar Usuario",
    subTitle: "¿Está seguro de querer activar el usuario?",
    logo: <AiOutlineCheckCircle className="text-5xl text-green-400" />,
    confirmation: "Activar",
    showCancelBtn: true,
    bgColor: "bg-green-100",
  };

  return (
    <>
      {loading && <Loading />}
      <AlertModal
        open={openDisable}
        setOpen={setOpenDisable}
        info={disableUserModal}
        deleteFunction={() => {
          changeStatusUser(false);
        }}
      />
      <AlertModal
        open={deleteUser}
        setOpen={setDeleteUser}
        info={deleteUserModal}
        deleteFunction={() => {
          deleteUsers();
        }}
      />
      <AlertModal
        open={openEnable}
        setOpen={setOpenEnable}
        info={enableUserModal}
        deleteFunction={() => {
          changeStatusUser(true);
        }}
      />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowSelection
        enableRowActions
        getRowId={(row) => row.id} //give each row a more useful id
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ rowSelection }}
        enableColumnFilterModes
        positionActionsColumn="last"
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "0.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "0.8rem",
          },
        }}
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: "0.5rem",
            }}
          >
            <FaRegEye
              onClick={() => detailUser(row?.original)}
              className="text-xl color-mid mr-2"
            />
            <FaRegEdit
              onClick={() => editDetailUser(row?.original)}
              className="text-xl color-mid mr-2"
            />
            {row.original.status === "ACTIVE" ? (
              <div className="mr-2">
                <Chip
                  label="Desactivar"
                  onClick={() => handleOpenDisable(row.original?.id)}
                  color="error"
                  variant="outlined"
                />
              </div>
            ) : (
              <div className="mr-2">
                <Chip
                  label="Activar"
                  onClick={() => handleOpenEnable(row.original?.id)}
                  color="success"
                  variant="outlined"
                />
              </div>
            )}
          </div>
        )}
        renderTopToolbarCustomActions={() => (
          <div className="mb-2 flex flex-col md:flex-row ">
            <div className="mr-0 md:mr-4 mb-4 md:mb-0">
              <Button
                onClick={() => navigate("crear")}
                buttonSize="btn--wide-button"
                buttonColor="second-button"
              >
                Crear Usuario
              </Button>
            </div>
            {Object.keys(rowSelection).length > 0 && (
              <div>
                <Button
                  onClick={() => setDeleteUser(true)}
                  buttonSize="btn--wide-button"
                  buttonColor="thrid-button"
                  classesCustom={`${
                    Object.keys(rowSelection)?.length === 0 ? "opacity-50" : ""
                  }`}
                  isDisabled={`${
                    Object.keys(rowSelection).length === 0 ? "1" : "0"
                  }`}
                >
                  Eliminar Usuarios
                </Button>
              </div>
            )}
          </div>
        )}
      />
    </>
  );
};

export default ContentEmployee;
