import React, { useEffect, useState, useMemo, useRef } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Button } from "components/button/Button";
import { bankInformation, displayAccountTypeInfo } from "const/account";
import { ccType } from "const/documentType";
import InputPhone from "components/formComponent/InputPhone";
import userService from "services/user.service";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import { roles } from "const/roles";
import Loading from "components/Loading";

function DetailEditEmployee({ role }) {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Usuarios",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Editar",
    },
  ];

  let { userId } = useParams();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState("");

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      setLoading(true);
      const result = await Promise.resolve(userService.getUserById(userId));

      // 👇️ only update state if component is mounted
      if (isMounted) {
        setLoading(false);
        if (result?.status !== 200) {
          throw new Error(result.message);
        }
        let { user, bankInfo } = result.data;
        user = { ...user, ...bankInfo };
        setUser(user);
        setInitial(getInitial(user.firstName, user.lastName));
        reset(user);
      }
    }

    fetchData().catch((err) => {
      toast.error(err.message);
      setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const getInitial = (firstName, lastName) => {
    const splitName = `${firstName.toUpperCase()[0]}${
      lastName.toUpperCase()[0]
    }`;
    return splitName;
  };

  const navigate = useNavigate();

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      return user;
    }, [user]),
  });

  const location = useLocation();
  const salaryFull = useRef({});

  // WATCH VARIABLES
  salaryFull.current = watch("fullIncome", "");

  const onSubmit = (data) => {
    setLoading(true);

    const userInfo = {};
    const bankInfo = {};

    if (user.firstName !== data.firstName) {
      userInfo.firstName = data.firstName;
    }
    if (user.middleName !== data.middleName) {
      userInfo.middleName = data.middleName;
    }
    if (user.lastName !== data.lastName) {
      userInfo.lastName = data.lastName;
    }
    if (user.typeOfDocument !== data.typeOfDocument) {
      userInfo.typeOfDocument = data.typeOfDocument;
    }
    if (user.documentNumber !== data.documentNumber) {
      userInfo.documentNumber = data.documentNumber;
    }
    if (user.phone !== data.phone) {
      userInfo.phone = data.phone;
    }
    if (user.bank !== data.bank) {
      bankInfo.bank = data.bank;
    }
    if (user.accountType !== data.accountType) {
      bankInfo.accountType = data.accountType;
    }
    if (user.accountNumber !== data.accountNumber) {
      bankInfo.accountNumber = data.accountNumber;
    }
    if (user.email !== data.email) {
      userInfo.email = data.email;
    }

    const bankError =
      "La información bancaria no puede estar vacía. Por favor ingrese todos los campos de la cuenta bancaria si desea actualizarla";

    if (
      (!data.bank || !data.accountType || !data.accountNumber) &&
      user.accountNumber
    ) {
      setLoading(false);
      toast.error(bankError);
      return;
    }

    if (user.netSalary !== data.netSalary) {
      if (user.paymentType === "BIWEEKLY") {
        userInfo.netSalary = Math.round(data.netSalary / 2);
      } else {
        userInfo.netSalary = data.netSalary;
      }
    }

    if (user.fullIncome !== data.fullIncome) {
      if (user.paymentType === "BIWEEKLY") {
        userInfo.fullIncome = Math.round(data.fullIncome / 2);
      } else {
        userInfo.fullIncome = data.fullIncome;
      }
    }

    const dataUpdate = {
      user: userInfo,
      bank: bankInfo,
      oldUserData: user,
    };

    userService
      .editUser(userId, dataUpdate)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setLoading(false);
        toast.success(data);
        navigate(`${location.state.from}`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        navigate(`${location.state.from}`);
      });
  };

  const cancelCreation = () => {
    navigate(`${location.state.from}`);
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <div className="flex align-items-as">
          <div className="res-circle mr-4">
            <div className="circle-txt font-bold">{initial}</div>
          </div>
          <h1 className="text-2xl font-bold">
            {user.firstName} {user.lastName}
          </h1>
        </div>
      </div>
      <div className="mb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <div className="bg-white p-4 rounded mb-8">
              <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
                <h2 className="text-lg">Información Personal</h2>
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                <div className="col-span-1 ">
                  <div className="mb-4">
                    <label for="firstName">Primer Nombre (*)</label>
                    <input
                      {...register("firstName", {
                        required: true,
                      })}
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Ingresa el primer nombre"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.firstName?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label for="middleName">Segundo Nombre</label>
                    <input
                      {...register("middleName")}
                      type="text"
                      name="middleName"
                      id="middleName"
                      placeholder="Ingresa el segundo nombre"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                  </div>

                  <div className="mb-4">
                    <label for="lastName">Apellidos (*)</label>
                    <input
                      {...register("lastName", {
                        required: true,
                      })}
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Ingresa los apellidos"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.lastName?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label for="email">Correo Electrónico (*)</label>
                    <input
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                      })}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Correo Electrónico"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.email?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <p className="text-red-400">
                        Correo Electrónico no es válido
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-1 ">
                  {role === roles.Admin && (
                    <div className="mb-4">
                      <label for="typeOfDocument">Tipo de documento (*)</label>
                      <select
                        className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
                        {...register("typeOfDocument", {
                          required: true,
                        })}
                      >
                        <option value="" selected disabled>
                          Por favor seleccione el tipo de documento
                        </option>
                        {ccType.map((data) => (
                          <option value={data.val}>{data.name}</option>
                        ))}
                      </select>
                      {errors?.typeOfDocument?.type === "required" && (
                        <p className="text-red-400">
                          Este campo es obligatorio
                        </p>
                      )}
                    </div>
                  )}
                  {role === roles.Admin && (
                    <div className="mb-4">
                      <label for="documentNumber">
                        Número de Documento (*)
                      </label>
                      <input
                        {...register("documentNumber", {
                          required: true,
                          pattern: /^[0-9]+$/,
                        })}
                        type="text"
                        name="documentNumber"
                        id="documentNumber"
                        placeholder="Número de Documento de Identidad"
                        className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                      />
                      {errors?.documentNumber?.type === "required" && (
                        <p className="text-red-400">
                          Este campo es obligatorio
                        </p>
                      )}
                      {errors?.documentNumber?.type === "pattern" && (
                        <p className="text-red-400">
                          El documento de identidad solo puede contener números
                        </p>
                      )}
                    </div>
                  )}
                  <div className="mb-4">
                    <label for="phone" className="mb-1">
                      Celular
                    </label>
                    <InputPhone control={control} errors={errors} />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded mb-8">
              <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
                <h2 className="text-lg">Información Bancaria</h2>
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                <div className="col-span-1 ">
                  <div className="mb-4">
                    <label for="accountType">Tipo de Cuenta</label>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("accountType", {
                        required: false,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione un tipo de cuenta
                      </option>
                      {Object.keys(displayAccountTypeInfo).map((key, index) => {
                        return (
                          <option value={key}>
                            {displayAccountTypeInfo[key]}
                          </option>
                        );
                      })}
                    </select>
                    {errors?.accountType?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label for="accountNumber">Número de cuenta</label>
                    <input
                      {...register("accountNumber", {
                        required: user.accountType && user.bank,
                        pattern: /^[0-9]+$/,
                      })}
                      type="text"
                      name="accountNumber"
                      id="accountNumber"
                      placeholder="Ingresa el número de cuenta"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.accountNumber?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.accountNumber?.type === "pattern" && (
                      <p className="text-red-400">
                        El número de cuenta solo puede contener números
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="mb-4">
                    <label for="bank">Banco</label>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("bank", {
                        required: false,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione un banco
                      </option>
                      {Object.entries(bankInformation).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {errors?.bank?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded mb-8">
              <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
                <h2 className="text-lg">Salario</h2>
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                <div className="col-span-1 ">
                  <div className="mb-4">
                    <label for="fullIncome">Salario Bruto (*)</label>
                    <Controller
                      name={"fullIncome"}
                      control={control}
                      {...register("fullIncome", {
                        required: true,
                      })}
                      render={(props) => {
                        return (
                          <CurrencyInput
                            prefix="$"
                            onValueChange={(e) => props.field.onChange(e)}
                            placeholder={"Salario Neto"}
                            value={props.field.value}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            decimalsLimit={2}
                          />
                        );
                      }}
                    />
                    {errors?.incomeFull?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="mb-4">
                    <label for="netSalary">Salario Neto (*)</label>
                    <Controller
                      name={"netSalary"}
                      control={control}
                      {...register("netSalary", {
                        required: true,
                        validate: (value) =>
                          value <= parseFloat(salaryFull.current),
                      })}
                      render={(props) => {
                        return (
                          <CurrencyInput
                            prefix="$"
                            onValueChange={(e) => props.field.onChange(e)}
                            placeholder={"Salario Neto"}
                            value={props.field.value}
                            groupSeparator=","
                            decimalSeparator="."
                            allowDecimals={false}
                            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            decimalsLimit={2}
                          />
                        );
                      }}
                    />
                    {errors?.netSalary?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.netSalary?.type === "validate" && (
                      <p className="text-red-400">
                        El salario neto no puedo ser mayor que el salario bruto
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <div className="flex justify-center my-4">
                <Loading />
              </div>
            )}
            <div className="flex justify-end">
              <div className="mr-4">
                <Button
                  onClick={cancelCreation}
                  disabled={loading}
                  buttonSize="btn--wide-button"
                  buttonColor="thrid-button"
                  classesCustom={` ${loading ? "opacity-50" : ""} `}
                >
                  Cancelar
                </Button>
              </div>
              {isDirty && (
                <div>
                  <Button
                    type="submit"
                    buttonSize="btn--wide-button"
                    buttonColor="second-button"
                    classesCustom={` ${loading ? "opacity-50" : ""} `}
                  >
                    Guardar Cambios
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DetailEditEmployee;
