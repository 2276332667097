import React, { useState } from "react";
import { data } from "./data";
import Sidebar from "components/sidebar/Sidebar";
import Header from "components/header/Header";
import { FiLayout, FiHelpCircle } from "react-icons/fi";
import { MdOutlineDashboardCustomize, MdOutlinePayments } from "react-icons/md";

const menus = [
  {
    title: "Adelantos",
    src: MdOutlineDashboardCustomize,
    route: "/adelantos",
  },
  {
    title: "Super Adelantos",
    src: MdOutlinePayments,
    gap: true,
    route: "/superadelantos",
  },
  {
    title: "Historial",
    src: FiLayout,
    gap: true,
    route: "/historial",
  },
  {
    title: "Ayuda",
    src: FiHelpCircle,
    route: "/ayuda",
  },
];

const regexMenu = [
  {
    name: "Adelantos",
    regex: /^(\/)adelantos\b(\/?)$/,
  },
  {
    name: "Super Adelantos",
    regex: /^(\/)superadelantos\b(\/?)([^\n]*)$/,
  },
  {
    name: "Historial",
    regex: /^(\/)historial\b(\/?)([^\n]*)$/,
  },
  {
    name: "Ayuda",
    regex: /^(\/)ayuda\b(\/?)$/,
  },
];

function Dashboard(type) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  return (
    <div className="h-full bg-gray-100">
      <div className="h-full">
        <div className="min-h-full">
          {/** SIDEBAR*/}
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            isActive={isActive}
            menus={menus}
            regexCollection={regexMenu}
            theme={"2"}
            secondaryMenu={[]}
          />

          {/** NAVBAR */}
          <header className="bg-white fixed w-screen z-40 border-gray-light">
            <Header
              isActive={isActive}
              setisActive={setisActive}
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
            />
          </header>
          <div
            className={`${
              isActive ? "pl-0" : "lg:pl-64"
            }  h-screen w-full flex flex-col`}
          >
            <div className="flex-grow bg-gray-100 mt-6 pt-12 p-6 md:p-12">
              {data[`${type.action}`]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
