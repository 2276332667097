import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function SimpleAreaChart({ dataMonthly }) {
  const { dataInfo } = dataMonthly;

  const DataFormater = (val) => {
    const formattedValue1 = val.toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formattedValue1;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="box-content p-2 box-border bg-white">
          <p>Día: {data.name}</p>
          <p>
            Valor de Adelanto:
            {data["Valor Adelanto"].toLocaleString("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        width={500}
        height={400}
        data={dataInfo}
        margin={{
          top: 10,
          right: 0,
          left: 40,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={DataFormater} />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="Valor Adelanto"
          stroke="#5ac09c"
          fill="#5ac09c"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default SimpleAreaChart;
