export const banks = [
  "bancamiaS.A.",
  "bancoAgrario",
  "bancoAvVillas",
  "bancoBtgPactual",
  "bancoCajaSocialBcscSa",
  "bancoCooperativoCoopcentral",
  "bancoCredifinancieraSa.",
  "bancoDaviviendaSa",
  "bancoDeBogota",
  "bancoDeOccidente",
  "bancoFalabellaS.A.",
  "bancoFinandinaS.A.",
  "bancoGnbSudameris",
  "bancoJ.P.MorganColombiaS.A",
  "bancoMundoMujer",
  "bancoPichincha",
  "bancoPopular",
  "bancoSantanderDeNegociosCo",
  "bancoSerfinanzaS.A",
  "bancoWS.A.",
  "bancoldexS.A.",
  "bancolombia",
  "bancoomeva",
  "bbvaColombia",
  "citibank",
  "coltefinancieraS.A",
  "confiar",
  "coofinepCooperativaFinancier",
  "cooperativaFinancieraDeAnti",
  "cootrafaCooperativaFinancier",
  "daviplata",
  "dingTecnipagosSa",
  "financieraJuriscoopS.A.Comp",
  "girosYFinanzasCf",
  "iris",
  "itau",
  "itauAntesCorpbanca",
  "jfkCooperativaFinanciera",
  "luloBankS.A.",
  "mibancoS.A.",
  "movii",
  "nequi",
  "pibank",
  "powwi",
  "rappipay",
  "scotiabankColpatriaS.A",
  "ualá",
];

export const documentTypes = ["CC", "CE", "PT", "PE"];
export const accountTypes = ["ahorros", "corriente"];

export const requiredColumnsAddEdit = [
  "primer_nombre",
  "segundo_nombre",
  "apellidos",
  "correo_electronico",
  "tipo_de_documento",
  "numero_de_documento",
  "celular",
  "salario_bruto",
  "salario_neto",
  "porcentaje_personalizado",
  "tipo_de_cuenta",
  "numero_de_cuenta",
  "ingreso_a_la_empresa",
  "banco",
];

export const requiredColumnsDelete = [
  "correo_electronico",
  "tipo_de_documento",
  "numero_de_documento",
];
