import React, { useState } from "react";
import dayjs from "dayjs";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={"53%"}
        dy={8}
        class="font-text-svg"
        textAnchor="middle"
        fill={"#3730A3"}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function PieChartExtend({ paymentPlan }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isBiweekly = paymentPlan === "BIWEEKLY";

  const COLORS = ["#3730A3", "#d7d7d7"];

  // Current Day
  let daysWorked = dayjs().date();
  // Total Of Working days
  let totalDaysWorked = dayjs().daysInMonth();

  // If payment is biweekly then total days of work are half o less depending the month
  // If payment is biweekly then current day is days of the month less 15
  if (isBiweekly) {
    if (daysWorked <= 15) {
      totalDaysWorked = 15;
    } else {
      daysWorked = dayjs().date() - 15;
      totalDaysWorked = dayjs().daysInMonth() - 15;
    }
  }

  const percentageDaysWorked = (100 * daysWorked) / totalDaysWorked;

  const data = [
    {
      name: daysWorked,
      value: percentageDaysWorked,
      label: "Trabajados",
      color: "#434190",
    },
    {
      name: daysWorked,
      value: 100 - percentageDaysWorked,
      label: "No Trabajados",
      color: "#00C49F",
    },
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height={130}>
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={60}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default PieChartExtend;
