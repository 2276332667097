import axios from "axios";
import authHeader from "./auth-header";

const getAllAdvances = async () => {
  try {
    const res = await axios.get("/advances", { headers: authHeader() });
    return res;
  } catch (error) {
    console.log(error);
    return error.response?.data?.error;
  }
};

const getAllAdvancesByCompany = async (companyId) => {
  try {
    const res = await axios.get(`/companies/${companyId}/advances`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const updateMultipleAdvance = async (dataUpdate, rowSelection) => {
  try {
    let keys = Object.keys(rowSelection);
    const data = {
      update: dataUpdate,
      advances: keys,
    };
    const res = await axios.put(`/advances`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const updateAdvance = async (dataUpdate, advanceId) => {
  try {
    const data = {
      update: dataUpdate,
    };
    const res = await axios.put(`/advances/${advanceId}`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const createAdvance = async (data) => {
  try {
    const res = await axios.post("/advances", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getAdvanceById = async (advanceId) => {
  try {
    const res = await axios.get(`/advances/${advanceId}`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getTransactionsByUser = async () => {
  try {
    const res = await axios.get(`/advances/user`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getTransactionByStatus = async (status) => {
  try {
    const res = await axios.get(`/advances/status/${status}`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getUrlSignedURL = async (idAdvance) => {
  try {
    const res = await axios.get(`/advances/${idAdvance}/contract`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const advancesService = {
  getAllAdvances,
  getAdvanceById,
  updateMultipleAdvance,
  createAdvance,
  getTransactionsByUser,
  getTransactionByStatus,
  getAllAdvancesByCompany,
  updateAdvance,
  getUrlSignedURL,
};

export default advancesService;
