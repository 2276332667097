import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function Eyetoggle({ children }) {
  const [showFullNumber, setShowFullNumber] = useState(false);

  const hideChildren = () => {
    const sizeChildren = children.length - 4;
    const response = `${"* ".repeat(sizeChildren)}${children.slice(-4)}`;
    return response;
  };

  return (
    <div className="flex-1">
      <div className="flex items-center">
        <div className="md:w-full">
          {showFullNumber ? children : hideChildren()}
        </div>
        <div>
          {showFullNumber ? (
            <AiOutlineEyeInvisible
              onClick={() => setShowFullNumber(false)}
              className="ml-2 text-2xl color-mid"
            />
          ) : (
            <AiOutlineEye
              onClick={() => setShowFullNumber(true)}
              className="ml-2 text-2xl color-mid"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Eyetoggle;
