import React from "react";
import formatFunction from "const/formatFunction";
import Eyetoggle from "components/eyeToggle/EyeToggle";

function InputInfo({ val, field, type, islast }) {
  const format = type ? formatFunction.formatInput(type, val) : val;

  return (
    <div className={`${islast ? " " : "border-b-2 border-gray-200"} md:w-auto`}>
      <div className="py-4">
        <div className="grid md:grid-cols-2">
          <div className="col-span-1 flex flex-col md:flex-row">
            <p className="font-semibold flex-1">{field}:</p>
            {type === "ACCOUNT" ? (
              <Eyetoggle>{format}</Eyetoggle>
            ) : (
              <p className="flex-1 ">{format}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputInfo;
