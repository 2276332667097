import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "slices/auth";
import { clearMessage } from "slices/message";
import { endpoints } from "const/roles";

function LoginAdmin() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const onSubmit = (data, event) => {
    setLoading(true);
    event.preventDefault();

    const userData = {
      email: data.email,
      password: data.password,
      role: endpoints.Admin,
    };

    dispatch(login(userData))
      .unwrap()
      .then(() => {
        navigate("/dashboard/admin");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });

    if (isLoggedIn) {
      return <Navigate to="/dashboard/admin" />;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-4">
          <div className="mb-4">
            <label>Correo Electrónico</label>
            <input
              {...register("email", {
                required: true,
                pattern: /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
              })}
              placeholder="Ingrese un correo electrónico"
              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
            />
            {errors?.email?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
            {errors?.email?.type === "pattern" && (
              <p className="text-red-400">Correo Electrónico no es válido</p>
            )}
          </div>
          <div className="mb-4">
            <label>Contraseña</label>
            <input
              {...register("password", {
                required: true,
              })}
              type="password"
              placeholder="Ingresa una contraseña"
              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
            />
            {errors?.password?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
          </div>
        </div>
        {loading && (
          <div className="flex justify-center my-4">
            <Loading />
          </div>
        )}
        <button
          type="submit"
          disabled={loading}
          className={`py-4 px-8 w-full ${
            loading ? "bg-red-300" : "bg-red-400 "
          } text-white 
                rounded-lg shadow-lg hover:bg-red-300 focus:ring-4 
                focus:ring-red-100 focus:outline-none`}
        >
          Ingresar
        </button>
      </form>
      {message && (
        <div className="my-4">
          <p className="text-lg text-red-400 font-semibold">{message}</p>
        </div>
      )}
    </>
  );
}

export default LoginAdmin;
