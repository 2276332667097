import { IoMdNotificationsOutline } from "react-icons/io";
import React, { useState } from "react";
import Panel from "pages/dashboard/Panel/Panel";
import { Link } from "react-router-dom";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";

export default function Activity({ activity }) {
  const [open, setOpen] = useState(false);
  const firstTwoItems = activity.slice(0, 2);

  return (
    <section aria-labelledby="recent-hires-title">
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="p-3">
          <h2 className="text-base font-medium text-indigo-800" id="activity">
            Actividad
          </h2>
          {activity.length > 0 ? (
            <div>
              <div className="mt-6 flow-root">
                <ul className="-my-5 divide-y divide-gray-200">
                  {firstTwoItems.map((elem) => (
                    <li key={elem.handle} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <IoMdNotificationsOutline className="text-indigo-800" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900">
                            {elem.message}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {elem.date}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6">
                <div
                  onClick={() => setOpen(!open)}
                  className="hidden md:block cursor-pointer flex w-full items-center justify-center rounded-md 
              border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 
              shadow-sm hover:bg-gray-50"
                >
                  Ver todas
                </div>
                <Link
                  to="/adelantos"
                  className="md:hidden cursor-pointer flex w-full items-center justify-center rounded-md 
              border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 
              shadow-sm hover:bg-gray-50"
                >
                  Ver historial de adelantos
                </Link>
              </div>
              <Panel setOpen={setOpen} open={open} activity={activity} />
            </div>
          ) : (
            <WarningAlertTailwind
              body={"Aún no has solicitado ningún adelanto"}
            />
          )}
        </div>
      </div>
    </section>
  );
}
