import React from "react";
import cx from "classnames";

const BoxSelect = ({ title, description, imageRender, selected, onSelect }) => {
  return (
    <div
      onClick={onSelect}
      className={cx(
        "bg-white group relative bg-white shadow p-4 shadow-sm rounded-lg border-2 cursor-pointer",
        selected ? "border-indigo-800 border-2" : "border-gray-200"
      )}
    >
      <div className="my-3">
        <div className="flex justify-center">{imageRender}</div>
        <div className="mt-4 text-center space-y-1">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h3>
          <p className=" text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default BoxSelect;
