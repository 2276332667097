import React, { useRef } from "react";
import cx from "classnames";

import AddEditEmployFile from "./AddEditEmployFile";
import DeleteEmployFile from "./DeleteEmployFile";

import { FiDownload } from "react-icons/fi";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";

const guideLinks = {
  addEmployees: "/documents/CARGA_USUARIOS.xlsx",
  deleteEmployees: "/documents/ELIMINAR_USUARIOS.xlsx",
};

const UploadFile = ({ action }) => {
  const currentLink = guideLinks[action];
  const linkRef = useRef(null);
  const valString = {
    title: action === "addEmployees" ? "importar" : "eliminar",
    verb: action === "addEmployees" ? "importación" : "eliminación",
  };

  return (
    <div>
      <div className="my-4 space-y-5">
        <div>
          <ul className="grid  cursor-pointer grid-cols-1 gap-6 border-b border-gray-200 py-6 sm:grid-cols-2">
            <li key={1} className="flow-root">
              <a href="/import_guide" target="_blank" rel="noopener noreferrer">
                <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                  <div
                    className={cx(
                      "bg-emerald-400",
                      "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                    )}
                  >
                    <BsFileEarmarkSpreadsheet
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      <div className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <div className="flex gap-3">
                          <span>Guía de importación</span>
                          <AiOutlineArrowRight />
                        </div>
                      </div>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Esta guía te proporcionará los pasos detallados para
                      {valString.title} tus usuarios de forma efectiva y segura.
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li key={2} className="flow-root">
              <a href={currentLink} download>
                <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                  <div
                    className={cx(
                      "bg-pink-500",
                      "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                    )}
                  >
                    <FiDownload
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      <div className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <div className="flex gap-3">
                          <span>Descargar Archivo de Importación</span>
                          <AiOutlineArrowRight />
                        </div>
                      </div>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Este archivo de prueba te ayudará a familiarizarte con el
                      formato requerido para la {valString.verb} de usuarios
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-2 md:mt-4">
        <p>Selecciona un archivo</p>
        <div className="w-auto mt-3">
          <a href={currentLink} download ref={linkRef} className="hidden">
            <span className="hidden">Descargar archivo .xlsx</span>
          </a>
          {action === "addEmployees" ? (
            <AddEditEmployFile />
          ) : (
            <DeleteEmployFile />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
