import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "components/button/Button";
import { daysToCutOff } from "const/planType";
import CurrencyInput from "react-currency-input-field";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";

import { paymentPlan } from "const/paymentPlan";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import SelectOptionModal from "components/modal/SelectOptionModal";
import { useParams } from "react-router-dom";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";

function TabParamsEdit({
  isEdit,
  setisEdit,
  data,
  getCompanyData,
  isCompanyAdmin,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      percentageOfAdvance: data.percentageOfAdvance,
      paymentType: data?.paymentType,
      secondCutOffDate: data?.secondCutOffDate,
      secondPayDay: data?.secondPayDay,
      firstCutOffDate: data?.firstCutOffDate,
      firstPayDay: data?.firstPayDay,
      customMaxAdvance: data?.customMaxAdvance,
      commission: data?.commission,
    },
  });

  // Const
  const editInfo =
    "Para cambiar este campo por favor contacte al gerente de cuenta.";

  // Aux Variables
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [optionSelected, setOptionSelected] = useState("option_1");

  const { companyId } = useParams();

  const watchSelectedPaymentPlan = watch("paymentType");

  const onSubmit = (companyData) => {
    const percentageHasChange =
      companyData.percentageOfAdvance !== data.percentageOfAdvance;

    if (percentageHasChange) {
      setFormInfo(companyData);
      return handleOpen();
    }

    editParamsCompany(companyData);
  };

  const confirmResetPercentage = () => {
    formInfo.resetEmployeePercentage = optionSelected === "option_1";
    return editParamsCompany(formInfo);
  };

  const editParamsCompany = (companyData) => {
    setLoading(true);
    setOpen(false);

    const updateObject = {};

    if (companyData.customMaxAdvance !== data.customMaxAdvance) {
      updateObject.customMaxAdvance = parseFloat(companyData.customMaxAdvance);
    }

    if (companyData.commission !== data.commission) {
      updateObject.commission = parseFloat(companyData.commission);
    }

    if (companyData.percentageOfAdvance !== data.percentageOfAdvance) {
      updateObject.percentageOfAdvance = companyData.percentageOfAdvance;
      updateObject.resetEmployeePercentage =
        companyData.resetEmployeePercentage;
    }

    if (companyData.paymentType !== data.paymentType) {
      updateObject.paymentType = companyData.paymentType;
    }

    if (
      updateObject.paymentType === "BIWEEKLY" ||
      companyData.paymentType === "BIWEEKLY"
    ) {
      updateObject.firstPayDay = companyData.firstPayDay;
      updateObject.secondPayDay = companyData.secondPayDay;
      updateObject.firstCutOffDate = companyData.firstCutOffDate;
      updateObject.secondCutOffDate = companyData.secondCutOffDate;
    }

    if (
      updateObject.paymentType === "MONTHLY" ||
      companyData.paymentType === "MONTHLY"
    ) {
      updateObject.firstPayDay = companyData.firstPayDay;
      updateObject.firstCutOffDate = companyData.firstCutOffDate;
    }

    companyService
      .editCompanyById(companyId, updateObject)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getCompanyData();
        setLoading(false);
        setisEdit(!isEdit);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        setisEdit(!isEdit);
      });
  };

  const modalInfo = {
    title: "Cambiar Porcentaje",
    logo: <AiOutlineQuestionCircle className="text-6xl text-indigo-500" />,
    body: "¿Desea aplicar el nuevo porcentaje de adelanto a cada uno de los usuarios?",
    confirmation: "Confirmar",
    subTitle: "Cambio de porcentaje de adelanto",
    showCancelBtn: true,
    bgColor: "bg-indigo-100 ",
    options: [
      {
        id: "option_1",
        title: "Si, aplicar nuevo porcentaje a todos los usuarios",
      },
      {
        id: "option_2",
        title:
          "No, conservar los porcentajes personalizados aplicados a los usuarios",
      },
    ],
  };

  const handleOpen = () => setOpen(true);
  return (
    <>
      <SelectOptionModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        info={modalInfo}
        actionFunction={confirmResetPercentage}
        setOptionSelected={setOptionSelected}
      />
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex">
          <h2 className="text-lg">Parámetros</h2>
        </div>
        <div className="overflow-x-scroll md:overflow-auto">
          {!isCompanyAdmin && (
            <div className="border-b-2 border-gray-200 md:w-auto">
              <div className="py-4">
                <div className="grid md:grid-cols-3">
                  <div className="col-span-1">
                    <p className="font-semibold flex-1">Comisión:</p>
                  </div>
                  <Controller
                    name={"commission"}
                    control={control}
                    {...register("commission", {
                      required: true,
                    })}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          prefix="$"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder={"Comisión"}
                          value={props.field.value}
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={false}
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                          decimalsLimit={2}
                        />
                      );
                    }}
                  />
                  <div className="col-span-1 md:col-span-2">
                    {errors?.customMaxAdvance?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isCompanyAdmin && (
            <div className="border-b-2 border-gray-200 md:w-auto">
              <div className="py-4">
                <div className="grid md:grid-cols-3">
                  <div className="col-span-1">
                    <p className="font-semibold flex-1">
                      Adelanto tope antes del 1%:
                    </p>
                  </div>
                  <Controller
                    name={"customMaxAdvance"}
                    control={control}
                    {...register("customMaxAdvance", {
                      required: true,
                    })}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          prefix="$"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder={"Adelanto Tope"}
                          value={props.field.value}
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={false}
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                          decimalsLimit={2}
                        />
                      );
                    }}
                  />
                  <div className="col-span-1 md:col-span-2">
                    {errors?.customMaxAdvance?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">
                    Porcentaje máximo de adelanto:
                  </p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <Controller
                    name="percentageOfAdvance"
                    control={control}
                    {...register("percentageOfAdvance", {
                      required: true,
                      min: 20,
                      max: 50,
                    })}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          suffix="%"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder="Ingrese el porcentaje máximo de adelanto"
                          groupSeparator=","
                          decimalSeparator="."
                          value={props.field.value}
                          allowDecimals={false}
                          className="bg-white  w-full md:w-1/2 p-2 overflow-hidden 
                        items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                          decimalsLimit={2}
                        />
                      );
                    }}
                  />
                  {errors?.percentageOfAdvance?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.percentageOfAdvance?.type === "min" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser mínimo del 20%
                    </p>
                  )}
                  {errors?.percentageOfAdvance?.type === "max" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser máximo del 50%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Modalidad de pago:</p>
                </div>
                {isCompanyAdmin ? (
                  <WarningAlertTailwind body={editInfo} />
                ) : (
                  <div>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                   items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("paymentType", {
                        required: true,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione la modalidad de pago
                      </option>
                      {paymentPlan.map((data) => (
                        <option value={data.val}>{data.name}</option>
                      ))}
                    </select>
                    {errors?.paymentType?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3 gap-4">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">
                    Primera fecha de corte:
                  </p>
                </div>
                {isCompanyAdmin ? (
                  <WarningAlertTailwind body={editInfo} />
                ) : (
                  <div>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("firstCutOffDate", {
                        required: true,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione la primera fecha de corte
                      </option>
                      {Object.entries(daysToCutOff).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {errors?.firstCutOffDate?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {watchSelectedPaymentPlan === "BIWEEKLY" && (
            <div className="border-b-2 border-gray-200 md:w-auto">
              <div className="py-4">
                <div className="grid md:grid-cols-3">
                  <div className="col-span-1">
                    <p className="font-semibold flex-1">
                      Segunda fecha de corte:
                    </p>
                  </div>
                  {isCompanyAdmin ? (
                    <WarningAlertTailwind body={editInfo} />
                  ) : (
                    <div>
                      <select
                        className="bg-white w-full p-2 overflow-hidden 
 items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                        {...register("secondCutOffDate", {
                          required: true,
                        })}
                      >
                        <option value="" selected disabled>
                          Por favor seleccione la segunda fecha de corte
                        </option>
                        {Object.entries(daysToCutOff).map(([key, value]) => {
                          return <option value={key}>{value}</option>;
                        })}
                      </select>
                      {errors?.secondCutOffDate?.type === "required" && (
                        <p className="text-red-400">
                          Este campo es obligatorio
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">
                    Primera fecha de liquidación:
                  </p>
                </div>
                {isCompanyAdmin ? (
                  <WarningAlertTailwind body={editInfo} />
                ) : (
                  <div>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("firstPayDay", {
                        required: true,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione la fecha de liquidación
                      </option>
                      {Object.entries(daysToCutOff).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {errors?.firstPayDay?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {watchSelectedPaymentPlan === "BIWEEKLY" && (
            <div className="border-b-2 border-gray-200 md:w-auto">
              <div className="py-4">
                <div className="grid md:grid-cols-3">
                  <div className="col-span-1">
                    <p className="font-semibold flex-1">
                      Segunda fecha de liquidación:
                    </p>
                  </div>
                  {isCompanyAdmin ? (
                    <WarningAlertTailwind body={editInfo} />
                  ) : (
                    <div>
                      <select
                        className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                        {...register("secondPayDay", {
                          required: true,
                        })}
                      >
                        <option value="" selected disabled>
                          Por favor seleccione la fecha de liquidación
                        </option>
                        {Object.entries(daysToCutOff).map(([key, value]) => {
                          return <option value={key}>{value}</option>;
                        })}
                      </select>
                      {errors?.secondPayDay?.type === "required" && (
                        <p className="text-red-400">
                          Este campo es obligatorio
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-4">
          <div className="mr-4">
            <Button
              buttonSize="btn--wide-button"
              buttonColor="thrid-button"
              onClick={() => setisEdit(!isEdit)}
            >
              Cancelar
            </Button>
          </div>
          {isDirty && (
            <div>
              <Button
                buttonSize="btn--wide-button"
                type="submit"
                buttonColor="second-button"
              >
                Guardar Cambios
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default TabParamsEdit;
