// AUTHENTICATION SERVICES
import axios from "axios";
import { endpoints } from "../const/roles";

const login = async (userData) => {
  try {
    const res = await axios.post(`/login${userData.role}`, userData);

    let userRequest = null;

    if (!res?.data?.token) {
      throw new Error("Error Getting User Token");
    }

    const tokenFB = `Bearer ${res?.data?.token}`;

    if (userData.role !== endpoints.User) {
      userRequest = await axios.get(`/get${userData.role}/${userData.email}`, {
        headers: { Authorization: tokenFB },
      });

      if (!userRequest) {
        throw new Error("Error Getting User Information By Email");
      }
    } else {
      userRequest = res;
    }

    const userDataStorage = {
      firstName: userRequest?.data?.firstName,
      role: userRequest?.data?.role,
      token: res?.data?.token,
      legkgtrm: userRequest?.data?.acceptTerms,
    };

    localStorage.setItem("user", JSON.stringify(userDataStorage));
    return userDataStorage;
  } catch (error) {
    const sendError = error.response?.data?.error;
    return sendError;
  }
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("isFocused");
};

const changePassword = async (userData) => {
  try {
    const res = await axios.post("/changePassword", userData);
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const recoverPassword = async (userData) => {
  try {
    const res = await axios.post("/recoverPassword", userData);
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const authService = {
  login,
  logout,
  changePassword,
  recoverPassword,
};

export default authService;
