import React from "react";
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

function SimplePieChart({ dataMonthly, users }) {
  const { duplicates } = dataMonthly;

  const RADIAN = Math.PI / 180;
  const percentage = Math.round((100 * duplicates) / users);

  const data = [
    { name: "% de empleados que adelantaron", value: percentage },
    { name: "% de empleados que no adelantaron", value: 100 - percentage },
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)} %`}
      </text>
    );
  };

  const COLORS = ["#1e1777", "#6677af"];

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart width={400} height={400}>
          <Legend layout="horizontal" verticalAlign="top" align="center" />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            labelLine={false}
            cy="50%"
            outerRadius={80}
            label={renderCustomizedLabel}
            fill="#9298ff"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}

export default SimplePieChart;
