import React, { useState } from "react";
import TabParamsDetail from "./TabParamsDetails";
import TabParamsEdit from "./TabParamsEdit";

function TabParams({ dataParams, getUserData }) {
  const [edit, setEdit] = useState(false);

  return (
    <div className="w-full">
      {edit ? (
        <TabParamsEdit
          isEdit={edit}
          setisEdit={setEdit}
          data={dataParams}
          getUserData={getUserData}
        />
      ) : (
        <TabParamsDetail isEdit={edit} setisEdit={setEdit} data={dataParams} />
      )}
    </div>
  );
}

export default TabParams;
