import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import Footer from "pages/landing/FooterSocialMedia";
import LogoMorado from "assets/images/LogoColorMorado.png";

function PoliticasDeDatos() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      color: "inherit",
      name: "Inicio",
      link: true,
    },
    {
      link: false,
      key: "2",
      color: "text.primary",
      name: "Acerca de",
    },
    {
      link: false,
      key: "3",
      color: "text.primary",
      name: "Política de tratamiento de datos",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="p-6 flex justify-between">
        <div className="flex align-items-as">
          <div className="mr-6 hidden md:block">
            <Link to="/">
              <img
                src={LogoMorado}
                alt="logo"
                className="relative w-auto md:cursor-pointer h-10"
              />
            </Link>
          </div>
          <CustomizedBreadcrumbs elements={breadcrumbs} />
        </div>
      </div>
      <div className="flex justify-center text-lg bg-curuba">
        <div className="w-screen h-full md:w-3/5 color-mid mb-8 text-justify">
          <div className="bg-white p-4 md:p-8 md:mt-10 shadow-md rounded-md">
            <div className="flex align-items-as mt-8 mb-8">
              <h1 className="text-3xl md:text-4xl font-bold text-center">
                POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES ANDAPAY S.A.S.
              </h1>
            </div>
            <div>
              <p>
                De conformidad con lo dispuesto en la Ley Estatutaria 1581 de
                2012 y su Decreto Reglamentario 1377 de 2013, ANDAPAY SAS,
                sociedad por acciones simplificada, con número de identificación
                tributaria 901.646.843-3, quien para todos los efectos se
                denominará ANDAPAY, le informa que la recolección de sus datos
                personales se realizará de acuerdo con lo señalado en nuestra
                POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES.
              </p>
              <div className="mx-10">
                <ol type="I" className="roman-ol">
                  <li className="auto-li font-semibold my-4">OBJETIVO</li>
                  <p>
                    Establecer los criterios para la recolección,
                    almacenamiento, uso, circulación y supresión de los datos
                    personales tratados por ANDAPAY.
                  </p>
                  <li className="auto-li font-semibold my-4">ALCANCE</li>
                  <p>
                    Esta política aplica para toda la información personal
                    registrada en las bases de datos de ANDAPAY, quien actúa en
                    calidad de responsable del tratamiento de los datos
                    personales.
                  </p>
                  <li className="auto-li font-semibold my-4">DEFINICIONES</li>
                  <p>
                    Las expresiones descritas a continuación, tendrán el
                    significado que se les otorgue. Los términos no definidos
                    tendrán el significado que la ley o la jurisprudencia
                    aplicable en Colombia les otorgue. A pesar de lo anterior, a
                    continuación, se definen los términos más relevantes de esta
                    Política:
                  </p>
                  <div className="mt-4">
                    <ul className="list-decimal list-inside ml-4 ">
                      <li className="auto-li">
                        Autorización: Consentimiento previo, expreso e informado
                        del Titular para llevar a cabo el tratamiento de datos
                        personales.
                      </li>
                      <li className="auto-li">
                        Aviso de privacidad: Comunicación verbal o escrita
                        dirigida a los Titulares de los datos personales que
                        están siendo tratados por la empresa, en la cual se le
                        informa acerca de la existencia de las políticas de
                        tratamiento de datos personales que le serán aplicadas,
                        la forma de acceder a la mismas, y las finalidades para
                        las cuales serán usados sus datos personales.
                      </li>
                      <li className="auto-li">
                        Base de datos personales: Conjunto organizado de datos
                        personales que son objeto de tratamiento por una persona
                        natural o jurídica.
                      </li>
                      <li className="auto-li">
                        Dato sensible: Es aquel dato personal que afecta la
                        intimidad del Titular y cuyo uso incorrecto podría
                        generar discriminación. Son considerados datos sensibles
                        entre otros, los datos de salud, los datos de
                        orientación sexual, origen racial y étnico, opiniones
                        políticas, convicciones religiosas, filosóficas o
                        morales.
                      </li>
                      <li className="auto-li">
                        Dato privado: Es aquel dato personal que por su carácter
                        íntimo o reservado es relevante para el Titular.
                      </li>
                      <li className="auto-li">
                        Dato personal: Cualquier información concerniente o
                        vinculada a personas naturales determinadas o
                        determinables.
                      </li>
                      <li className="auto-li">
                        Encargado del tratamiento: Persona natural o jurídica,
                        de carácter público o privado que por sí misma o en
                        asocio con otros, realiza el tratamiento de datos
                        personales por cuenta del responsable.
                      </li>
                      <li className="auto-li">
                        Responsable del tratamiento: Persona natural o jurídica
                        de carácter público o privado que por sí misma o en
                        asocio con otro u otros decide sobre el tratamiento de
                        datos personales.
                      </li>
                      <li className="auto-li">
                        Titular: Es la persona natural a quien se refieren los
                        Datos Personales, cuya información podrá reposar en una
                        Base de Datos y quien es el sujeto del derecho de hábeas
                        data.
                      </li>
                      <li className="auto-li">
                        Tratamiento: Cualquier operación o conjunto de
                        operaciones sobre datos personales, tales como la
                        recolección, almacenamiento, uso, circulación o
                        supresión.
                      </li>
                    </ul>
                  </div>
                  <li className="auto-li font-semibold my-4">OBLIGACIONES</li>
                  <p>
                    Esta política es de obligatorio y estricto cumplimiento para
                    ANDAPAY.
                  </p>
                  <li className="auto-li font-semibold my-4">
                    RESPONSABLE DEL TRATAMIENTO
                  </li>
                  <p>
                    ANDAPAY SAS, sociedad por acciones simplificada, con número
                    de identificación tributaria 901.646.843-3.
                  </p>
                  <li className="auto-li font-semibold my-4">
                    DATOS UTILIZADOS
                  </li>
                  <p>ANDAPAY utilizará los siguientes datos personales:</p>
                  <ul className="list-decimal list-inside ml-4 ">
                    <li className="auto-li">
                      Identificación completa (nombres completos y cédula de
                      ciudadanía, PPT y/o pasaporte).
                    </li>
                    <li className="auto-li">
                      Dirección y/o datos de ubicación.
                    </li>
                    <li className="auto-li">Correo electrónico.</li>
                    <li className="auto-li">Edad.</li>
                    <li className="auto-li">Género.</li>
                    <li className="auto-li">Teléfono celular.</li>
                    <li className="auto-li">
                      Datos de la cuenta bancaria en la cual recibe su nómina.
                    </li>
                  </ul>
                  <li className="auto-li font-semibold my-4">
                    TRATAMIENTO Y FINALIDAD
                  </li>
                  <p>
                    ANDAPAY le informa en calidad de responsable de sus datos,
                    será el encargado del tratamiento y en tal virtud, podrá
                    recolectar, almacenar, usar sus datos para las siguientes
                    finalidades:
                  </p>
                  <ul className="list-decimal list-inside ml-4 ">
                    <li className="auto-li">
                      Efectuar las gestiones pertinentes para el desarrollo del
                      objeto social de la compañía, en lo que tiene que ver con
                      el cumplimiento del objeto del contrato celebrado con el
                      titular de la información.
                    </li>
                    <li className="auto-li">
                      Realizar invitaciones a eventos y ofrecer nuevos
                      servicios.
                    </li>
                    <li className="auto-li">
                      Efectuar encuestas de satisfacción respecto de los
                      servicios ofrecidos por ANDAPAY.
                    </li>
                    <li className="auto-li">
                      Suministrar información de contacto al área comercial y/o
                      red de distribución de mercados y cualquier tercero con el
                      cual ANDAPAY tenga vínculo contractual para el desarrollo
                      de actividades de este tipo para la ejecución de las
                      mismas.
                    </li>
                    <li className="auto-li">
                      Contactar al titular/usuario a través de medios
                      telefónicos o correos electrónicos para realizar
                      encuestas, estudios y/o confirmación de datos personales
                      necesarios para la ejecución de una relación contractual.
                    </li>
                    <li className="auto-li">
                      Contactar al titular/usuario a través de medios
                      electrónicos- SMS o chat para el envío de noticias
                      relacionadas con campañas de fidelización o mejora del
                      servicio.
                    </li>
                    <li className="auto-li">
                      Gestionar todas las acciones tendientes a tramitar el
                      avance de nómina el titular/usuario.
                    </li>
                    <li className="auto-li">
                      Gestión a nivel interno de peticiones, quejas y reclamos
                      (“PQR”) y procesos propios de servicio al cliente de
                      ANDAPAY.
                    </li>
                  </ul>
                  <li className="auto-li font-semibold my-4">
                    DERECHOS DE LOS TITULARES
                  </li>
                  <p>
                    Como titular de sus datos personales Usted tiene derecho a:
                  </p>
                  <ul className="list-decimal list-inside ml-4 ">
                    <li className="auto-li">
                      Acceder de forma gratuita a los datos proporcionados que
                      hayan sido objeto de tratamiento.
                    </li>
                    <li className="auto-li">
                      Conocer, actualizar y rectificar su información frente a
                      datos parciales, inexactos, incompletos, fraccionados, que
                      induzcan a error, o aquellos cuyo tratamiento esté
                      prohibido o no haya sido autorizado.
                    </li>
                    <li className="auto-li">
                      Solicitar prueba de la autorización otorgada.
                    </li>
                    <li className="auto-li">
                      Presentar ante la Superintendencia de Industria y Comercio
                      (SIC) quejas por infracciones a lo dispuesto en la
                      normatividad vigente.
                    </li>
                    <li className="auto-li">
                      Revocar la autorización y/o solicitar la supresión del
                      dato, siempre que no exista un deber legal o contractual
                      que impida eliminarlos.
                    </li>
                    <li className="auto-li">
                      Abstenerse de responder las preguntas sobre datos
                      sensibles. Tendrá carácter facultativo las respuestas que
                      versen sobre datos sensibles o sobre datos de las niñas y
                      niños y adolescentes.
                    </li>
                  </ul>
                  <li className="auto-li font-semibold my-4">
                    ATENCIÓN DE PETICIONES, QUEJAS, CONSULTAS Y RECLAMOS
                  </li>
                  <p>
                    El área de PQR es la dependencia que tiene a cargo dar
                    trámite a las solicitudes de los titulares para hacer
                    efectivos sus derechos. Los podrá ejercer a través de los
                    canales o medios dispuestos por ANDAPAY para la atención al
                    público como el correo electrónico gerencia@andapay.com.co.
                  </p>
                  <li className="auto-li font-semibold my-4">MODIFICACIONES</li>
                  <p>
                    Toda modificación sustancial de esta Política tendrá que ser
                    comunicada previamente a los Titulares por medio de
                    mecanismos eficientes, tales como correos electrónicos. Por
                    modificación sustancial se entienden, entre otras, las
                    siguientes situaciones:
                  </p>
                  <ul className="list-decimal list-inside ml-4 ">
                    <li className="auto-li">
                      Modificación en la identificación del área, dependencia o
                      persona encargada de atender las consultas y reclamos.
                    </li>
                    <li className="auto-li">
                      Modificación evidente de las finalidades que puedan
                      afectar la autorización. En este caso ANDAPAY obtendrá una
                      nueva autorización.
                    </li>
                  </ul>
                  <li className="auto-li font-semibold my-4">VIGENCIA</li>
                  <p>
                    La presente Política para el Tratamiento de Datos Personales
                    rige a partir del 1 de octubre del año 2022. Las bases de
                    datos en las que se registrarán los datos personales tendrán
                    una vigencia igual al tiempo en que se mantenga y utilice la
                    información para las finalidades descritas en esta política.
                    Los datos personales proporcionados se conservarán mientras
                    no se solicite su supresión por el interesado y siempre que
                    no exista un deber legal de conservarlos.
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PoliticasDeDatos;
