import axios from "axios";
import authHeader from "./auth-header";

// COMPANY

const changeStatusCompany = async (companyId, status) => {
  try {
    const actionTaken = status ? "disable" : "enable";
    const res = await axios.get(
      `/companies/${companyId}/action/${actionTaken}`,
      {
        headers: authHeader(),
      }
    );
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const editCompanyById = async (companyId, info) => {
  try {
    const updateInfo = {
      data: info,
    };
    const res = await axios.put(`/companies/${companyId}`, updateInfo, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const createCompany = async (companyData) => {
  try {
    const res = await axios.post("/companies", companyData, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getCompanyById = async (companyId) => {
  try {
    const res = await axios.get(`/companies/${companyId}`, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getCurrentCompany = async () => {
  try {
    const res = await axios.get(`/company`, {
      headers: authHeader(),
    });
    return res?.data;
  } catch (err) {
    console.log(err);
  }
};

const createCompanyAdmin = async (data) => {
  try {
    const res = await axios.post(`/companies/${data.companyId}/admin`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const deleteAdmin = async (data) => {
  try {
    const res = await axios.delete(
      `/companies/${data.company}/admin/${data.id}`,
      {
        headers: authHeader(),
      }
    );
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getCompanyAdmin = async () => {
  try {
    const res = await axios.get("/companies/admin/detail", {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const editCompanyAdmin = async (adminId, info) => {
  try {
    const sendInfo = {
      data: info,
    };
    const res = await axios.put(`/companies/admin/${adminId}`, sendInfo, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getCompanyByAdmin = async () => {
  try {
    const res = await axios.get("/companies/admin/company", {
      headers: authHeader(),
    });
    return res?.data;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getHomePage = async () => {
  try {
    const res = await axios.get("/adminCompany/home", {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const companyService = {
  createCompanyAdmin,
  deleteAdmin,
  changeStatusCompany,
  createCompany,
  editCompanyById,
  getCompanyAdmin,
  editCompanyAdmin,
  getCurrentCompany,
  getCompanyById,
  getCompanyByAdmin,
  getHomePage,
};

export default companyService;
