import React, { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import { useLocation } from "react-router-dom";
import InputInfo from "components/inputInfo/InputInfo";
import { Button } from "components/button/Button";
import { bankInformation } from "const/account";
import { advanceStatus } from "const/advanceStatus";
import advancesService from "services/advances.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";

import { sendAdvanceStatus } from "const/advanceStatus";

function DetailAdvance() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Adelantos",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Detalle",
    },
  ];

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const info = location.state;
    setContent(info);
  }, [location.state]);

  const getSignedUrl = () => {
    setLoading(true);
    advancesService
      .getUrlSignedURL(content.id)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        setLoading(false);
        const { data } = res;
        window.location.href = data;
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <div className="flex md:justify-between md:align-items-as flex-col md:flex-row">
          <div className="flex align-items-as">
            <div className="res-circle mr-4">
              <div className="circle-txt font-bold">AD</div>
            </div>
            <h1 className="text-2xl font-bold">Adelanto</h1>
          </div>
          <div className="mt-4 md:mt-0">
            {content?.status !== sendAdvanceStatus.DENIED && (
              <Button
                buttonSize="btn--wide-button"
                buttonColor="purple-btn"
                onClick={getSignedUrl}
              >
                Descargar Contrato
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8 light-shadow">
        <div className="pb-2 mb-4 curuba-border w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-xl">Información sobre adelanto</h2>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputInfo
              val={`${content?.advanceAmount}`}
              field={"Valor de adelanto"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${parseFloat(content.comissionFee)}`}
              field={"Comisión"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${content?.finalAmount} `}
              field={"Valor total a descontar"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${advanceStatus[content?.status]}`}
              field={"Estado"}
            />
            <InputInfo
              val={`${content?.advanceDate}`}
              field={"Fecha"}
              type={"DATE"}
              islast={true}
            />
          </div>
        </div>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8 light-shadow">
        <div className="pb-2 mb-4 curuba-border w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-xl">Información Bancaria</h2>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputInfo
              val={`${content?.bankInfo?.accountNumber}`}
              field={"Número de cuenta"}
              type={"ACCOUNT"}
            />
            <InputInfo
              val={`${content?.bankInfo?.accountType}`}
              field={"Tipo de cuenta"}
            />
            <InputInfo
              val={`${bankInformation[content?.bankInfo?.bank]}`}
              field={"Banco"}
              islast={true}
            />
          </div>
        </div>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8 light-shadow">
        <div className="pb-2 mb-4 curuba-border w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-xl">Información Personal</h2>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputInfo
              val={`${content?.userInfo?.typeOfDocument} ${content?.userInfo?.documentNumber}`}
              field={"Documento de indentidad"}
            />
            <InputInfo
              val={`${content?.userInfo?.email}`}
              field={"Correo Electrónico"}
            />
            <InputInfo
              val={`${content?.userInfo?.phone} `}
              field={"Celular"}
              type={"PHONE"}
              islast={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailAdvance;
