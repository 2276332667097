import React, { useState } from "react";
import TermsAndConditions from "./TermsAndConditions";

function DashboardContent() {
  const [userId, setUserId] = useState("");

  return <TermsAndConditions user={userId} />;
}

export default DashboardContent;
