import React, { useEffect, useState, useCallback } from "react";
import ContentEmployee from "common/Employee/ContentEmployee";
import userService from "services/user.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { useParams } from "react-router-dom";

const renderCols = [
  {
    accessorFn: (row) => `${row?.firstName} ${row?.middleName}`,
    header: "Nombre",
    filterFn: "fuzzy",
    enableColumnFilterModes: false,
  },
  {
    accessorKey: "lastName",
    header: "Apellidos",
    filterFn: "fuzzy",
    enableColumnFilterModes: false,
  },
  {
    accessorKey: "email",
    header: "Correo Electronico",
    filterFn: "fuzzy",
    enableColumnFilterModes: false,
  },
];

function WrapperUser() {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  let { companyId } = useParams();

  const getHomePage = useCallback(() => {
    setLoading(true);
    userService
      .getAllUsersByCompany(companyId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const usersData = res.data.users;
        setContent(usersData);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, [companyId]);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  return (
    <>
      {loading && <Loading />}
      <ContentEmployee
        data={content}
        renderCols={renderCols}
        getEmployeeData={getHomePage}
      />
    </>
  );
}

export default WrapperUser;
