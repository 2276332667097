import ContentHome from "./ContentHome";
import WrapperUser from "./Users/WrapperUser";
import ContentSettings from "./Company/ContentSetting";
import ContentProfile from "./Pofile/ContentProfile";
import ContentHelp from "common/ContentHelp";
import ContentCreateEmployee from "./Users/ContentCreateEmployee";
import DetailEmployee from "common/Employee/DetailEmployee";
import EditEmployee from "common/Employee/DetailEditEmployee";
import DetailAdvance from "./Advance/DetailAdvance";
import CreateCompanyAdmin from "common/CompanyAdmin/CreateCompanyAdmin";
import ContentAdvance from "./Advance/ContentAdvance";
import Import from "./Import/Import";

// Company Site
export const data = {
  home: <ContentHome />,
  users: <WrapperUser />,
  advancesGeneral: <ContentAdvance />,
  settings: <ContentSettings />,
  profile: <ContentProfile />,
  help: <ContentHelp />,
  createUsers: <ContentCreateEmployee />,
  detailUser: <DetailEmployee />,
  editUser: <EditEmployee role={1984} />,
  detailAdvance: <DetailAdvance />,
  createAdminCompany: <CreateCompanyAdmin />,
  import: <Import />,
};
