import React, { useState } from "react";

import { Button } from "components/button/Button";
import { useForm, Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import { asuntos, tipoChart } from "const/matters";
import Loading from "components/Loading";
import userService from "services/user.service";
import { toast } from "react-toastify";

import { useParams } from "react-router-dom";

function ReportUser({ getUserDetailPage }) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchSelectedType = watch("typeMatter");

  const { userId } = useParams();

  const onSubmitReport = (data) => {
    setLoading(true);
    userService
      .createReportUser(userId, data)
      .then((res) => {
        if (res.status !== 201) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getUserDetailPage();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmitReport)}>
        <div>
          <div className="mb-4">
            <label for="matter">Asunto:</label>
            <select
              className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
              {...register("matter", {
                required: true,
              })}
            >
              <option value="" selected disabled>
                Por favor seleccione un asunto a reportar
              </option>
              {Object.keys(asuntos).map((key, index) => {
                return <option value={key}>{asuntos[key]}</option>;
              })}
            </select>
            {errors?.matter?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
          </div>
          <div className="mb-4">
            <label for="typeMatter">Tipo:</label>
            <select
              className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
              {...register("typeMatter", {
                required: true,
              })}
            >
              <option value="" selected disabled>
                Seleccione si el reporte corresponde a un valor específico o a
                un porcentaje del salario
              </option>
              {Object.keys(tipoChart).map((key, index) => {
                return <option value={key}>{tipoChart[key]}</option>;
              })}
            </select>
            {errors?.typeMatter?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
          </div>
          {watchSelectedType === "amount" ? (
            <div className="mb-4">
              <label for="amount">Valor:</label>
              <Controller
                {...register("amount", {
                  required: watchSelectedType === "amount",
                })}
                name={"amount"}
                control={control}
                render={(props) => {
                  return (
                    <CurrencyInput
                      prefix="$"
                      onValueChange={(e) => props.field.onChange(e)}
                      placeHolder={"Ingrese el monto correspondiente"}
                      groupSeparator=","
                      decimalSeparator="."
                      value={props.field.value}
                      allowDecimals={false}
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                      decimalsLimit={2}
                    />
                  );
                }}
                rules={{
                  required: true,
                }}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-red-400">Este campo es obligatorio</p>
              )}
            </div>
          ) : (
            <div className="mb-4">
              <label for="percentage">Porcentaje:</label>
              <Controller
                name="percentage"
                {...register("percentage", {
                  required: watchSelectedType === "percentage",
                })}
                control={control}
                rules={{
                  required: true,
                  min: 0,
                  max: 100,
                }}
                render={(props) => {
                  return (
                    <CurrencyInput
                      suffix="%"
                      onValueChange={(e) => {
                        props.field.onChange(e);
                      }}
                      placeholder="Ingrese el procentaje del salario"
                      groupSeparator=","
                      value={props.field.value}
                      decimalSeparator="."
                      allowDecimals={false}
                      className="bg-white w-full p-2 overflow-hidden 
                items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      decimalsLimit={2}
                    />
                  );
                }}
              />
              {errors?.percentage?.type === "required" && (
                <p className="text-red-400">Este campo es obligatorio</p>
              )}
              {errors?.percentage?.type === "min" && (
                <p className="text-red-400">
                  El valor del porcentaje debe ser mínimo del 0%
                </p>
              )}
              {errors?.percentage?.type === "max" && (
                <p className="text-red-400">
                  El valor del porcentaje debe ser máximo del 100%
                </p>
              )}
            </div>
          )}
          <div className="mb-4">
            <label for="comment">Comentario:</label>
            <textarea
              {...register("comment", {
                required: true,
              })}
              rows="10"
              cols="30"
              className="bg-white w-full p-2 overflow-hidden 
        items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
            ></textarea>
            {errors?.comment?.type === "required" && (
              <p className="text-red-400">Este campo es obligatorio</p>
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            buttonSize="btn--wide-button"
            buttonColor="second-button"
            classesCustom={"md:w-auto w-full mb-4"}
          >
            Crear Reporte
          </Button>
        </div>
      </form>
    </>
  );
}

export default ReportUser;
