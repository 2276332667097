import cx from "classnames";

const SimpleDividerText = ({
  icon,
  title,
  description,
  fontTitle = "text-base",
  fontDescription = "text-sm",
}) => {
  return (
    <div className="border-b border-gray-300 pb-3 flex flex-col">
      <div className="flex">
        <h3 className={cx(fontTitle, "font-semibold leading-6 text-gray-900")}>
          {title}
        </h3>
        {icon}
      </div>
      {description && (
        <p className={cx(fontDescription, "mt-1 text-gray-500")}>
          {description}
        </p>
      )}
    </div>
  );
};

export default SimpleDividerText;
