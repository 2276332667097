import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "components/button/Button";
import InputPhone from "components/formComponent/InputPhone";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import commonService from "services/common.service";

function FormHeroSection({ setOpenForm }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // Aux Variables
  const [loading, setLoading] = useState(false);

  // Constants
  const error_message = "Ups! Intenta más tarde";

  const onSubmit = (data) => {
    setLoading(true);
    commonService
      .demoForm(data)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setLoading(false);
        setOpenForm(false);
      })
      .catch((err) => {
        toast.error(error_message);
        reset();
        setLoading(false);
        setOpenForm(false);
      });
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col text-sm">
        <div className="mb-4">
          <label>Nombre de la empresa</label>
          <input
            {...register("nameCompany", {
              required: true,
              maxLength: 40,
            })}
            placeholder="Ingrese el nombre de la empresa"
            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
          />
          {errors?.nameCompany?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.nameCompany?.type === "maxLength" && (
            <p className="text-red-400">
              El nombre de la empresa no puede exceder los 40 caracteres
            </p>
          )}
        </div>
        <div className="mb-4">
          <label>Número de trabajadores</label>
          <input
            {...register("numberEmployess", {
              required: true,
              min: 1,
            })}
            type="number"
            placeholder="Ingrese el número de trabajadores"
            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
          />
          {errors?.numberEmployess?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.numberEmployess?.type === "min" && (
            <p className="text-red-400">
              Debes contar con al menos un empleado
            </p>
          )}
        </div>
        <div className="mb-4">
          <label>Software de nómina</label>
          <input
            {...register("payrollprovider", {
              required: true,
            })}
            type="text"
            placeholder="Ingrese el nombre del Software de nómina"
            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
          />
          {errors?.payrollprovider?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
        </div>
        <div className="mb-4">
          <label>Correo Electrónico</label>
          <input
            {...register("email", {
              required: true,
              pattern: /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
            })}
            placeholder="Ingrese un correo electrónico"
            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
          />
          {errors?.email?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.email?.type === "pattern" && (
            <p className="text-red-400">Correo Electrónico no es válido</p>
          )}
        </div>
        <div className="w-full mb-4">
          <label>Celular</label>
          <InputPhone control={control} errors={errors} />
        </div>
      </div>
      {loading && (
        <div className="flex justify-center my-4">
          <Loading />
        </div>
      )}
      <div className="flex justify-end rounded-b-md ">
        <div className="mt-4 md:mt-0">
          <Button
            buttonSize="btn--wide-button"
            buttonColor="second-button"
            disabled={loading}
            type="submit"
            classesCustom={`w-full md:w-auto ${loading ? "opacity-50" : ""} `}
          >
            Enviar
          </Button>
        </div>
      </div>
    </form>
  );
}

export default FormHeroSection;
