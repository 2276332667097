import React, { useEffect } from "react";
import CustomizedAccordions from "components/accordion/AccordionGroup";
import img1 from "assets/images/searchIcon256.png";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import Footer from "pages/landing/FooterSocialMedia";
import LogoMorado from "assets/images/LogoColorMorado.png";
import { BsBriefcase } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import {
  groupCompanyOperacional,
  groupCompanyProducto,
  groupCompanySeguridad,
  groupEmployeeFuncionalidades,
  groupEmployeeProducto,
  groupEmployeeReporte,
  groupEmployeeVerificacion,
  groupEmployeeProblemas,
  breadcrumbs,
} from "./data";

function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="p-6 flex justify-between">
        <div className="flex align-items-as">
          <div className="mr-8 hidden md:block">
            <img
              src={LogoMorado}
              alt="logo"
              className="relative w-auto md:cursor-pointer h-10"
            />
          </div>
          <CustomizedBreadcrumbs elements={breadcrumbs} />
        </div>
      </div>
      <div className="bg-color-mid flex flex-col md:flex-row justify-center align-items-as ">
        <div className="mt-8 md:mt-0 text-center p-6 md:p-0">
          <h1 className="text-4xl md:text-5xl text-white">
            Preguntas Frecuentes
          </h1>
        </div>
        <div>
          <img
            className="relative w-auto h-56 md:h-auto"
            src={img1}
            alt="Chip"
          />
        </div>
      </div>
      <div className="flex justify-center  p-6 md:p-0 bg-curuba">
        <div className="w-screen h-full md:w-3/5 color-mid mb-8">
          <div>
            <div>
              <div className="flex align-items-as mt-8 mb-8">
                <BsBriefcase className="text-pink-600 text-2xl mr-2" />
                <h1 className="text-xl md:text-3xl font-bold">Empresas</h1>
              </div>
              <div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Operacional</h2>
                </div>
                <div className="mb-4">
                  {groupCompanyOperacional.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Producto</h2>
                </div>
                <div className="mb-4">
                  {groupCompanyProducto.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                      listContent={data.listContent}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Seguridad</h2>
                </div>
                <div className="mb-4">
                  {groupCompanySeguridad.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="flex align-items-as mt-8 mb-8">
                <FiUsers className="text-pink-600 text-2xl mr-2" />
                <h1 className="text-xl md:text-3xl font-bold">Empleados</h1>
              </div>
              <div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Funcionalidades</h2>
                </div>
                <div className="mb-4">
                  {groupEmployeeFuncionalidades.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8  border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Producto</h2>
                </div>
                <div className="mb-4">
                  {groupEmployeeProducto.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8  border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Reportes</h2>
                </div>
                <div className="mb-4">
                  {groupEmployeeReporte.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Verificación</h2>
                </div>
                <div className="mb-4">
                  {groupEmployeeVerificacion.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
                <div className="mt-2 pb-4 mb-8 border-b-2 border-pink-600 w-full">
                  <h2 className="text-xl font-semibold">Problemas</h2>
                </div>
                <div className="mb-4">
                  {groupEmployeeProblemas.map((data) => (
                    <CustomizedAccordions
                      question={data.question}
                      answer={data.answer}
                      panelName={data.panel}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
