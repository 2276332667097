import EnableAccount from "./EnableAccount";
import LoginAdmin from "./LoginAdmin";
import LoginUser from "./LoginUser";
import ResetPassword from "./ResetPassword";

export const data = {
  admin: <LoginAdmin />,
  user: <LoginUser />,
  enable: <EnableAccount />,
  reset: <ResetPassword />,
};
