export const roles = {
  User: 5150,
  AdminCompany: 1984,
  Admin: 1807,
};

export const endpoints = {
  User: "User",
  AdminCompany: "AdminCompany",
  Admin: "Admin",
};
