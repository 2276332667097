export const advanceStatus = {
  PENDING: "Pendiente",
  ACTIVE: "En Progreso",
  DENIED: "Cancelado",
  COMPLETED: "Completado",
};

export const sendAdvanceStatus = {
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  DENIED: "DENIED",
  COMPLETED: "COMPLETED",
};

export const companyStatus = {
  ACTIVE: "Activa",
  INACTIVE: "Desactivada",
};

export const userStatus = {
  ACTIVE: "Activado",
  INACTIVE: "Desactivado",
};
