import React, { useCallback } from "react";
import moradoLogo from "assets/images/morado48px.png";
import { FiLogOut } from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "slices/auth";
import { isOpen } from "slices/sidebar";
import { FaBars } from "react-icons/fa";

function Header({ isActive, setisActive, setSidebarOpen, sidebarOpen }) {
  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const prefix = currentUser?.role;
  let pageHelp = "/ayuda";
  if (prefix === 1984) {
    pageHelp = "/dashboard/ayuda";
  }

  // Function
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  if (!currentUser) {
    return <Navigate to="/login/admin" />;
  }

  return (
    <div className="flex justify-between align-items-as pt-1 pb-1">
      <div className="flex align-items-as">
        <FaBars
          onClick={() => {
            setisActive(!isActive);
            dispatch(isOpen(!isActive));
          }}
          className="ml-4 hidden lg:block"
        />
        <FaBars
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
            dispatch(isOpen(!sidebarOpen));
          }}
          className="ml-4 block lg:hidden"
        />
        <img
          className="w-10 ml-6 h-auto relative"
          src={moradoLogo}
          alt="Logo Purple Small"
        />
      </div>
      <div className="mr-12">
        <div className="flex items-center">
          <p className="text-lg mr-3">Hola {currentUser.firstName}!</p>
          {prefix !== 1807 && (
            <Link className="flex" to={pageHelp}>
              <BiHelpCircle className="text-2xl mr-3 hidden md:block" />
            </Link>
          )}
          <FiLogOut
            onClick={logOut}
            className="text-base hidden md:block cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
