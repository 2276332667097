function Zipzap() {
  return (
    <div className="bg-indigo-800">
      <div className="mx-auto max-w-7xl py-12 px-6 sm:py-16 lg:px-8 lg:py-20">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Fácil de usar
          </h2>
          <p className="mt-3 text-xl text-indigo-200 sm:mt-4">
            Nuestra plataforma está diseñada de forma intuitiva.
            <br />
            ¡Con tan solo tres clicks puedes realizar tu adelanto!
          </p>
        </div>
        <dl className="mt-10 text-center sm:mx-auto sm:grid sm:max-w-3xl sm:grid-cols-3 sm:gap-8">
          <div className="flex flex-col">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-indigo-200">
              De aceptación
            </dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-white">
              100%
            </dd>
          </div>

          <div className="mt-10 flex flex-col sm:mt-0">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-indigo-200">
              Clicks para realizar tu adelanto
            </dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-white">
              3
            </dd>
          </div>
          <div className="mt-10 flex flex-col sm:mt-0">
            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-indigo-200">
              De interés
            </dt>
            <dd className="order-1 text-5xl font-bold tracking-tight text-white">
              0%
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Zipzap;
