import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

export default function Panel({ open, setOpen, activity }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6 mt-10">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Actividad
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <AiOutlineClose
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200"></div>
                    <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {activity.map((elem, index) => (
                        <li key={`elem-${index}`}>
                          <div className="group cursor-pointer relative flex items-start md:items-center py-6 pr-5 pl-2 md:flex-row flex-col">
                            <div className="-m-1 block flex-1 p-1">
                              <div className="relative flex min-w-0 flex-1 items-center">
                                <div className="ml-2 truncate">
                                  <p className="truncate text-sm font-medium text-gray-900">
                                    {elem.message}
                                  </p>
                                  <p className="truncate text-sm text-gray-500">
                                    {elem.date}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <span
                              className="inline-flex items-center rounded-full bg-indigo-100 
                            px-2.5 py-0.5 text-xs font-medium text-indigo-800"
                            >
                              <svg
                                className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx={4} cy={4} r={3} />
                              </svg>
                              Adelanto
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
