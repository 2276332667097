import Main from "./Home/Main/MainWrapper";
import HistoryAdvances from "./Advance/HistoryAdvances";
import HelpUser from "./Help/HelpUser";
import DetailAdvance from "./Advance/DetailAdvance";
import SuperAdvance from "./Advance/SuperAdvance";

// User Site
export const data = {
  home: <Main />,
  advances: <HistoryAdvances />,
  help: <HelpUser />,
  detailAdvance: <DetailAdvance />,
  superadvances: <SuperAdvance />,
};
