import React, { useState, useEffect, useCallback } from "react";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import ContentProfileDetail from "./ContentProfileDetail";
import ContentProfileEdit from "./ContentProfileEdit";
import Loading from "components/Loading";

function ContentProfile() {
  const [isEdit, setisEdit] = useState(false);
  const [content, setContent] = useState([]);
  const [initial, setInitial] = useState("");
  const [loading, setLoading] = useState(false);

  const getInitial = (firstName, lastName) => {
    const splitName = `${firstName.toUpperCase()[0]}${
      lastName.toUpperCase()[0]
    }`;
    return splitName;
  };

  const getHomePage = useCallback(() => {
    setLoading(true);
    companyService
      .getCompanyAdmin()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setInitial(getInitial(data.firstName, data.lastName));
        setContent(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-4 mb-4">
        <div className="flex align-items-as">
          <div className="res-circle mr-4">
            <div className="circle-txt font-bold">{initial}</div>
          </div>
          <h1 className="text-2xl font-bold">
            {content.firstName} {content.lastName}
          </h1>
        </div>
      </div>
      {isEdit ? (
        <ContentProfileEdit
          isEdit={isEdit}
          setisEdit={setisEdit}
          data={content}
          getHomePage={getHomePage}
        />
      ) : (
        <ContentProfileDetail
          isEdit={isEdit}
          setisEdit={setisEdit}
          data={content}
        />
      )}
    </div>
  );
}

export default ContentProfile;
