export const bankInformation = {
  "bancamiaS.A.": "BANCAMIA S.A.",
  bancoAgrario: "BANCO AGRARIO",
  bancoAvVillas: "BANCO AV VILLAS",
  bancoBtgPactual: "BANCO BTG PACTUAL",
  bancoCajaSocialBcscSa: "BANCO CAJA SOCIAL BCSC SA",
  bancoCooperativoCoopcentral: "BANCO COOPERATIVO COOPCENTRAL",
  "bancoCredifinancieraSa.": "BANCO CREDIFINANCIERA SA.",
  bancoDaviviendaSa: "BANCO DAVIVIENDA SA",
  bancoDeBogota: "BANCO DE BOGOTA",
  bancoDeOccidente: "BANCO DE OCCIDENTE",
  "bancoFalabellaS.A.": "BANCO FALABELLA S.A.",
  "bancoFinandinaS.A.": "BANCO FINANDINA S.A.",
  bancoGnbSudameris: "BANCO GNB SUDAMERIS",
  "bancoJ.P.MorganColombiaS.A": "BANCO J.P. MORGAN COLOMBIA S.A",
  bancoMundoMujer: "BANCO MUNDO MUJER",
  bancoPichincha: "BANCO PICHINCHA",
  bancoPopular: "BANCO POPULAR",
  bancoSantanderDeNegociosCo: "BANCO SANTANDER DE NEGOCIOS CO",
  "bancoSerfinanzaS.A": "BANCO SERFINANZA S.A",
  "bancoWS.A.": "BANCO W S.A.",
  "bancoldexS.A.": "BANCOLDEX S.A.",
  bancolombia: "BANCOLOMBIA",
  bancoomeva: "BANCOOMEVA",
  bbvaColombia: "BBVA COLOMBIA",
  citibank: "CITIBANK",
  "coltefinancieraS.A": "COLTEFINANCIERA S.A",
  confiar: "CONFIAR",
  coofinepCooperativaFinancier: "COOFINEP COOPERATIVA FINANCIER",
  cooperativaFinancieraDeAnti: "COOPERATIVA FINANCIERA DE ANTI",
  cootrafaCooperativaFinancier: "COOTRAFA COOPERATIVA FINANCIER",
  daviplata: "DAVIPLATA",
  dingTecnipagosSa: "DING TECNIPAGOS SA",
  "financieraJuriscoopS.A.Comp": "FINANCIERA JURISCOOP S.A. COMP",
  girosYFinanzasCf: "GIROS Y FINANZAS CF",
  iris: "IRIS",
  itau: "ITAU",
  itauAntesCorpbanca: "ITAU antes Corpbanca",
  jfkCooperativaFinanciera: "JFK COOPERATIVA FINANCIERA",
  "luloBankS.A.": "LULO BANK S.A.",
  "mibancoS.A.": "MIBANCO S.A.",
  movii: "MOVII",
  nequi: "NEQUI",
  pibank: "PIBANK",
  powwi: "POWWI",
  rappipay: "RAPPIPAY",
  "scotiabankColpatriaS.A": "SCOTIABANK COLPATRIA S.A",
  ualá: "Ualá",
};

export const displayAccountTypeInfo = {
  ahorros: "Ahorros",
  corriente: "Corriente",
};
