import React from "react";
import Loading from "../Loading";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdOutlineClose } from "react-icons/md";

const style = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function SelectOptionModal({
  open,
  setOpen,
  info,
  actionFunction,
  loading,
  setOptionSelected,
}) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      {loading && <Loading />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="absolute rounded-md w-4/5 md:w-1/3">
          <div className="flex justify-between p-4 bg-gray-100 rounded-t-md align-items-as">
            <p className="text-base">{info.title}</p>
            <MdOutlineClose className="text-base" onClick={handleClose} />
          </div>
          <div className="p-4">
            <div
              className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${info.bgColor}`}
            >
              {info.logo}
            </div>
            <div className="mt-2">
              <p className="text-base font-semibols text-center">
                {info.subTitle}
              </p>
              <p className="text-base text-gray-500 text-center mt-2">
                {info.body}
              </p>
            </div>
            <div className="space-y-4 px-6 mt-4">
              {info.options.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input
                    id={option.id}
                    name="notification-method"
                    type="radio"
                    defaultChecked={option.id === "option_1"}
                    className="h-4 w-4 border-gray-300 text-indigo-700 focus:ring-indigo-500"
                    onClick={() => {
                      setOptionSelected(option.id);
                    }}
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-base text-gray-500 font-medium"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-500 
                px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 
                focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                onClick={actionFunction}
              >
                {info.confirmation}
              </button>
              {info.showCancelBtn && (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 
                text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none 
                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SelectOptionModal;
