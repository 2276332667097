import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import companyReducer from "./slices/company";
import sidebarReducer from "./slices/sidebar";
import advanceUserReducer from "./slices/advanceUser";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  company: companyReducer,
  sidebar: sidebarReducer,
  advanceUser: advanceUserReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
