import React, { useState } from "react";
import img1 from "assets/images/searchBrowse512.png";
import img2 from "assets/images/messageChat256.png";
import img3 from "assets/images/feedBack512.png";
import { whatsappLink } from "config/links";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { styleModal } from "const/modalStyle";
import { MdOutlineClose } from "react-icons/md";
import { Button } from "components/button/Button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import commonService from "services/common.service";
import Loading from "components/Loading";

function HelpUser() {
  // Modal Form Main Screen
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  // Aux Variables
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Constants
  const error_message = "Ups! Intenta más tarde";
  const success_message =
    "Gracias!. Estamos revisando tu información. Te contactáremos lo más pronto posible.";

  const onSubmit = (data) => {
    setLoading(true);
    handleCloseForm();
    const dataSend = {
      message: data.requestBody,
    };
    commonService
      .contactDashboardForm(dataSend)
      .then((res) => {
        if (res?.status !== 201) {
          throw new Error(res.message);
        }
        toast.success(success_message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(error_message);
        setLoading(false);
      });
  };

  const ScheduleDemoModal = () => {
    return (
      <div>
        <Modal
          open={openForm}
          onClose={handleCloseForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} className="absolute rounded-md w-4/5 md:w-1/3">
            <div className="flex flex-col p-4 bg-gray-100 rounded-t-md">
              <div className="flex justify-between">
                <p className="text-xl md:text-2xl">Contactános</p>
                <MdOutlineClose
                  className="text-lg md:text-2xl"
                  onClick={handleCloseForm}
                />
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full p-4 bg-white rounded-b-md">
                <div className="flex flex-col">
                  <div className="mb-4">
                    <label for="nameCompany">Motivo de solicitud</label>

                    <textarea
                      {...register("requestBody", {
                        required: true,
                      })}
                      rows="10"
                      cols="50"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.requestBody?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-end rounded-b-md ">
                  <div className="mt-4 md:mt-0">
                    <Button
                      buttonSize="btn--wide-button"
                      buttonColor="second-button"
                      classesCustom="w-full md:w-auto"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <ScheduleDemoModal />
      {loading && <Loading />}
      <div>
        <p>Ayuda</p>
        <div className="mt-4 mb-4">
          <h1 className="text-xl font-bold">Solicitar Ayuda</h1>
        </div>
        <div className="mb-6">
          <div className="bg-white p-4 rounded mb-8 light-shadow-4">
            <div className="grid gap-2 md:gap-0 md:grid-cols-3 text-center">
              <div className="col-span-1 p-4 ">
                <a href={whatsappLink} target="_blank" rel="noreferrer">
                  <div
                    className="flex flex-col 
                  justify-center align-items-as
                  rounded-md 
                  h-auto md:h-64  
                  py-8 md:py-4 
                  light-shadow"
                  >
                    <img
                      className="relative w-auto h-24"
                      src={img2}
                      alt="chat"
                    />
                    <p className="text-xl md:text-2xl mt-8 text-gray-500">
                      Chat en vivo
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-span-1 p-4 ">
                <Link to="/faq" target="_blank">
                  <div
                    className="flex flex-col 
                    justify-center align-items-as
                    rounded-md 
                    h-auto md:h-64  
                    py-8 md:py-4 
                    light-shadow"
                  >
                    <img
                      className="relative w-auto h-24"
                      src={img1}
                      alt="faq"
                    />
                    <p className="text-xl md:text-2xl mt-8 text-gray-500">
                      Preguntas Frecuentes
                    </p>
                  </div>
                </Link>
              </div>
              <div className="col-span-1 p-4 ">
                <div
                  className="flex flex-col 
                  justify-center align-items-as
                  rounded-md 
                  h-auto md:h-64  
                  py-8 md:py-4 
                  light-shadow"
                  onClick={handleOpenForm}
                >
                  <img
                    className="relative w-auto h-24"
                    src={img3}
                    alt="modal"
                  />
                  <p className="text-xl md:text-2xl text-gray-500 mt-8">
                    Contáctanos
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpUser;
