import React, { useState, useEffect } from "react";

// COMPONENTS
import Steps from "components/steps/Steps";
import SimpleDividerText from "components/simpleDividerText/SimpleDividerText";
import ButtonCustom from "components/buttonCustom/ButtonCustom";
import BoxSelect from "components/boxSelect/BoxSelect";

// ICONS
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

import { boxes, stepSilder } from "./data";
import { UploadFile } from "componentsV2/common/UploadFile";

const Import = () => {
  // STATE
  const [selectedBox, setSelectedBox] = useState("addEmployees");
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      id: "01",
      name: "Tipo",
      href: "#",
      status: "current",
    },
    {
      id: "02",
      name: "Subir",
      href: "#",
      status: "upcoming",
    },
  ]);

  // FUNCTIONS
  useEffect(() => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => {
        if (index < currentStep) {
          return { ...step, status: "complete" };
        } else if (index === currentStep) {
          return { ...step, status: "current" };
        } else {
          return { ...step, status: "upcoming" };
        }
      })
    );
  }, [currentStep]);

  const handleStepChange = async (step) => {
    if (selectedBox === "") {
      return;
    }
    setCurrentStep(step);
  };

  const handleSelect = (key) => {
    setSelectedBox(key);
  };

  return (
    <div>
      <div className="mt-3 pb-2 items-center flex justify-between border-b-2 border-pink-600">
        <h1 className="text-xl">Importación de datos</h1>
      </div>
      <div className="mt-3 bg-white w-full rounded mb-8">
        <Steps steps={steps} />
        <div className="px-2 md:px-6">
          <div className="mt-6">
            <SimpleDividerText
              {...{
                title: stepSilder[currentStep].title,
                description: stepSilder[currentStep].description,
              }}
            />
          </div>
          <div>
            {currentStep === 0 && (
              <div className="grid md:grid-cols-3 grid-flow-row gap-6 mt-4">
                {boxes.map((elem) => (
                  <BoxSelect
                    title={elem.title}
                    key={elem.key}
                    imageRender={elem.imageRender}
                    description={elem.description}
                    selected={selectedBox === elem.key}
                    onSelect={() => handleSelect(elem.key)}
                  />
                ))}
              </div>
            )}
            {currentStep === 1 && <UploadFile action={selectedBox} />}
          </div>
          <div className="flex flex-col md:flex-row md:justify-between mt-10 pb-10">
            <div>
              {currentStep > 0 && (
                <ButtonCustom
                  {...{
                    icon: (
                      <AiOutlineArrowLeft
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ),
                    iconPosition: "left",
                    width: ["w-full md:w-auto"],
                    backgroundColor: ["bg-transparent"],
                    border: ["border-indigo-500", "border-2"],
                    textColor: ["text-indigo-500"],
                    textAlign: "justify-center",
                  }}
                  onClick={() => handleStepChange(currentStep - 1)}
                >
                  Atrás
                </ButtonCustom>
              )}
            </div>
            <div>
              {currentStep < steps.length - 1 && (
                <ButtonCustom
                  {...{
                    icon: (
                      <AiOutlineArrowRight
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ),
                    iconPosition: "right",
                    width: ["w-full md:w-auto"],
                    backgroundColor: ["bg-transparent"],
                    border: ["border-indigo-500", "border-2"],
                    textAlign: "justify-center",
                    textColor: ["text-indigo-500"],
                    margin: ["mt-3 md:mt-0"],
                  }}
                  onClick={() => handleStepChange(currentStep + 1)}
                >
                  Siguiente
                </ButtonCustom>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
