import React, { useEffect, useState } from "react";
import logo from "assets/images/LogoColorBlanco.png";
import { data } from "./data";

function Login(type) {
  const [welcome, setWelcome] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    welcomeMessage(type);
  }, []);

  const welcomeMessage = () => {
    switch (type.role) {
      case "enable":
        setWelcome("Activa tu cuenta 🔑");
        break;
      case "reset":
        setWelcome("Recupera tu contraseña 🔑");
        break;
      default:
        setWelcome("¡Hola de nuevo! 👋");
        break;
    }
  };

  return (
    <div className="w-screen">
      <div className="grid md:grid-cols-3 h-screen">
        <div className="hidden md:block col-span-1 gradient-as p-12">
          <div className="h-full flex justify-center align-items-as">
            <img
              className="h-auto relative w-7/12 md:w-8/12"
              src={logo}
              alt="logo"
            />
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 bg-gray-50 flex justify-center h-full align-items-as">
          <div className="p-8 w-screen md:w-4/6 mb-24 md:mb-0 ">
            <div id="message-login">
              <p className="font-extrabold text-2xl lg:text-3xl">{welcome}</p>
            </div>
            <div className="form-login">{data[`${type.role}`]}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
