import React, { useMemo, useState } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tabs from "@mui/material/Tabs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Tab from "@mui/material/Tab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";
import { asuntos, tipoChart } from "const/matters";
import InputForm from "components/InputForm";
import { bankInformation, displayAccountTypeInfo } from "const/account";
import { advanceStatus } from "const/advanceStatus";
import ReportUser from "./ReportUser";
import { TabPanel, a11yProps } from "components/tabPanel/TabPanelFunction";
import FormatNumber from "components/FormatNumber";
import TabParams from "common/Employee/TabParams";
import { userStatus } from "const/advanceStatus";

function DetailUser({
  dataUser,
  dataReport,
  dataTransaction,
  getUserDetailPage,
}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="mb-6">
      <div className="bg-white p-4 rounded mb-8">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Información Personal" {...a11yProps(0)} />
              <Tab label="Cuenta Bancaria" {...a11yProps(1)} />
              <Tab label="Actividad" {...a11yProps(2)} />
              <Tab label="Reportes" {...a11yProps(3)} />
              <Tab label="Parámetros" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="w-full">
              <div className="overflow-x-scroll md:overflow-auto">
                <InputForm val={dataUser?.firstName} field={"Primer Nombre"} />
                {dataUser?.middleName && (
                  <InputForm
                    val={dataUser?.middleName}
                    field={"Segundo Nombre"}
                  />
                )}
                <InputForm val={dataUser?.lastName} field={"Apellidos"} />
                <InputForm
                  val={dataUser?.phone}
                  field={"Celular"}
                  type={"PHONE"}
                />
                <InputForm
                  val={`${dataUser?.typeOfDocument} ${dataUser?.documentNumber}`}
                  field={"Documento de identidad"}
                />
                <InputForm val={dataUser?.email} field={"Coreo Electrónico"} />
                <InputForm
                  val={dataUser?.netSalary}
                  field={"Salario Neto"}
                  type={"MONEY"}
                />
                <InputForm
                  val={dataUser?.fullIncome}
                  field={"Salario Bruto"}
                  type={"MONEY"}
                />
                <InputForm val={userStatus[dataUser.status]} field={"Estado"} />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="w-full">
              {dataUser?.bankInfo && dataUser?.bankInfo.accountNumber ? (
                <div className="overflow-x-scroll md:overflow-auto">
                  <InputForm
                    val={bankInformation[dataUser?.bankInfo?.bank]}
                    field={"Banco"}
                  />
                  <InputForm
                    val={dataUser?.bankInfo?.accountNumber}
                    field={"Número de Cuenta"}
                    type={"ACCOUNT"}
                  />
                  <InputForm
                    val={
                      displayAccountTypeInfo[dataUser?.bankInfo?.accountType]
                    }
                    field={"Tipo de Cuenta"}
                  />
                </div>
              ) : (
                <WarningAlertTailwind
                  body={"El usuario no ha ingresado una cuenta bancaria."}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ThemeProvider theme={createTheme(theme, esES)}>
              <TableAdvance data={dataTransaction ? dataTransaction : []} />
            </ThemeProvider>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ReportUser getUserDetailPage={getUserDetailPage} />
            <div className="my-2">
              <p className="text-xl mb-4">Historial de Soportes</p>
              <ThemeProvider theme={createTheme(theme, esES)}>
                <TableReports data={dataReport ? dataReport : []} />
              </ThemeProvider>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TabParams dataParams={dataUser} getUserData={getUserDetailPage} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

const TableAdvance = ({ data }) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "advanceAmount",
        header: "Valor de adelanto",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: "comissionFee",
        header: "Comisión",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorKey: "finalAmount",
        header: "Valor total a descontar",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorFn: (row) => new Date(row.advanceDate), //convert to Date for sorting and filtering
        header: "Fecha", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => row.status, //convert to Date for sorting and filtering
        header: "Estado", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Pendiente", value: "PENDING" },
          { text: "En Progreso", value: "ACTIVE" },
          { text: "Cancelado", value: "DENIED" },
          { text: "Completado", value: "COMPLETED" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => advanceStatus[cell.getValue()], //render Date as a string
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableColumnFilterModes
        positionActionsColumn="last"
        initialState={{ showGlobalFilter: true }}
      />
    </>
  );
};

const TableReports = ({ data }) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.matter, //convert to Date for sorting and filtering
        header: "Asunto", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Crédito de Libranza", value: "matterTypeA" },
          { text: "Embargo de salario", value: "matterTypeB" },
          { text: "Licencia No Remunerada", value: "matterTypeC" },
          { text: "Descuentos", value: "matterTypeD" },
          { text: "Incapacidades Médicas", value: "matterTypeE" },
          { text: "Otros", value: "matterTypeF" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => asuntos[cell.getValue()], //render Date as a string
      },
      {
        accessorFn: (row) => row.typeMatter, //convert to Date for sorting and filtering
        header: "Tipo", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Porcentaje", value: "percentage" },
          { text: "Valor", value: "amount" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => tipoChart[cell.getValue()], //render Date as a string
      },
      {
        accessorKey: "amount",
        header: "Valor",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorFn: (row) => new Date(row.advanceDate), //convert to Date for sorting and filtering
        header: "Fecha de creación", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableColumnFilterModes
        positionActionsColumn="last"
        initialState={{ showGlobalFilter: true }}
      />
    </>
  );
};

export default DetailUser;
