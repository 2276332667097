export const groupCompanyOperacional = [
  {
    question: "¿Quién paga los adelantos de nómina que realicen los empleados?",
    subGroup: "Operacional",
    panel: "panel1",
    answer:
      "AndaPay paga el 100% del valor de los adelantos de nómina de tus empleados, esto significa que tu liquidez no se verá afectada.",
  },
  {
    question: "¿Es fácil operar la plataforma de AndaPay?",
    subGroup: "Operacional",
    panel: "panel2",
    answer:
      "¡Sí!, la plataforma es intuitiva y fácil de usar. Adicionalmente, AndaPay te brindará una capacitación tanto a la empresa como a los empleados de cómo funciona la plataforma.",
  },
  {
    question: "¿Cuánto se demora la implementación de la plataforma?",
    subGroup: "Operacional",
    panel: "panel3",
    answer:
      "Una vez se firme el convenio, la empresa estará habilitada en la plataforma de AndaPay en menos de 24 horas.",
  },
  {
    question:
      "¿Cómo sabremos cuanto tenemos que pagarle a los trabajadores a final de mes?",
    subGroup: "Operacional",
    panel: "panel4",
    answer:
      "Nos conectamos a tu software de nómina para que los adelantos sean descontados de forma automática del pago que le debes hacer a tus empleados a final del mes.",
  },
  {
    question: "¿Qué pasa cuando un empleado se va de la empresa?",
    subGroup: "Operacional",
    panel: "panel5",
    answer:
      "Desde el módulo del empleador tú podrás eliminar a los empleados que ya no hagan parte de la compañía, desde ese momento dicho empleado quedará inhabilitado de la plataforma.",
  },
  {
    question:
      "¿Qué pasa cuando contratamos a un empleado o cuando hay un ajuste salarial?",
    subGroup: "Operacional",
    panel: "panel6",
    answer:
      "Desde el módulo del empleador podrás agregar empleados, modificar su información bancaria o realizar un ajuste salarial.",
  },
  {
    question: "¿Cuánto dinero pueden adelantar los trabajadores?",
    subGroup: "Operacional",
    panel: "panel7",
    answer:
      "Tus empleados únicamente podrán adelantar un porcentaje de su salario laborado. Cabe destacar que tú como empleador fijas dicho porcentaje y que tus empleados solo pueden adelantar los días laborados.",
  },
];

export const groupCompanyProducto = [
  {
    question: "¿Qué beneficios ofrece AndaPay para la empresa?",
    subGroup: "Producto",
    panel: "panel1",
    answer:
      "La flexibilidad de cobro promueve el bienestar laboral de tus empleados, mejorando el ambiente laboral y la productividad de tus empleados. Esto ayudará a disminuir la rotación de personal en tu compañía. Adicionalmente, nuestro servicio es completamente gratis para tu empresa, nosotros ponemos la plata para los adelantos, así que no se afectará tu flujo de caja.",
  },
  {
    question: "¿Qué servicios ofrecen?",
    subGroup: "Producto",
    panel: "panel2",
    answer: "Ofrecemos tres servicios:",
    listContent: [
      {
        liItem: "AndaPay",
        liContent:
          "Tus empleados podrán adelantar su salario de forma inmediata y con la comisión más baja del mercado.",
      },
      {
        liItem: "AndaEdu",
        liContent:
          "Tus colaboradores tendrán acceso a educación financiera personalizada, esto los ayudará a mejorar sus hábitos financieros.",
      },
      {
        liItem: "AndaPlan",
        liContent:
          "Tus trabajadores podrán organizar sus finanzas, de tal forma que puedan establecer metas con ahorros programados e identificar gastos hormiga. *Pregunta a nuestros asesores para acceder a este servicio.",
      },
    ],
  },
  {
    question: "¿Cuánto cuesta obtener el servicio de AndaPay?",
    subGroup: "Producto",
    panel: "panel3",
    answer: "Actualmente, contamos con dos modalidades:",
    listContent: [
      {
        liItem: "Plan Individual",
        liContent:
          "El servicio de AndaPay es completamente gratis para tu compañía y no afecta en ningún aspecto tu flujo de caja. En esta modalidad los empleados pagan una pequeña comisión por cada adelanto realizado.",
      },
      {
        liItem: "Plan Empresa",
        liContent:
          "El servicio de AndaPay es completamente gratis para tus empleados. En esta modalidad la empresa paga una tarifa fija mensual, independiente del número de adelantos, para adquirir los servicios de AndaPay. *Esta tarifa depende del número de colaboradores con los que cuente tu empresa.",
      },
      {
        liItem: "Plan Hybrid",
        liContent:
          "Tus trabajadores podrán organizar sus finanzas, de tal forma que puedan establecer metas con ahorros programados e identificar gastos hormiga. *Pregunta a nuestros asesores para acceder a este servicio.",
      },
    ],
  },
  {
    question: "¿Qué pasos hay que seguir para aliarte con AndaPay?",
    subGroup: "Producto",
    panel: "panel4",
    answer:
      "Debes llenar un formulario, luego uno de nuestros asesores se comunicará contigo cuanto antes, nuestro equipo te solicitará unos documentos y en menos de 24 horas podrás acceder a AndaPay.",
  },
];

export const groupCompanySeguridad = [
  {
    question:
      "¿Hay algún riesgo con el pago de los salarios de mis colaboradores?",
    subGroup: "Seguridad",
    panel: "panel1",
    answer:
      "No hay ningún riesgo. Realizaremos el pago directamente a la cuenta bancaria que tus empleados tienen asociada al pago de nómina. Los comprobantes de las transacciones serán la verificación de que el adelanto se realizó de forma exitosa. ",
  },
  {
    question:
      "¿Cómo manejan la política de tratamiento de datos de mi empresa y mis colaboradores?",
    subGroup: "Seguridad",
    panel: "panel2",
    answer:
      "Contamos con todos los requerimientos establecidos por la ley de políticas de privacidad y tratamiento de datos.",
  },
  {
    question: "¿Su plataforma es 100% segura?",
    subGroup: "Seguridad",
    panel: "panel3",
    answer: "Si, AndaPay tiene fuertes protocolos de seguridad ACA",
  },
];

export const groupEmployeeFuncionalidades = [
  {
    question: "¿Cuánto se demora la plataforma en activar mi cuenta?",
    subGroup: "Funcionalidades",
    panel: "panel1",
    answer:
      "Una vez suministrada la información por la empresa nos demoramos máximo 12 horas en activar la cuenta para todos los miembros del equipo de trabajo.",
  },
  {
    question: "¿AndaPay tiene acceso a mi cuenta bancaria?",
    subGroup: "Funcionalidades",
    panel: "panel2",
    answer:
      "No, únicamente tenemos acceso al número de cuenta para transferir el monto anticipado.",
  },
  {
    question: "¿Qué pasa si tengo múltiples cuentas bancaria?",
    subGroup: "Funcionalidades",
    panel: "panel3",
    answer:
      "AndaPay solo realizará los anticipos a la cuenta bancaria que este registrada como cuenta de nómina en la compañía.",
  },
  {
    question: "¿En cuánto tiempo puedo ver el adelanto reflejado en mi cuenta?",
    subGroup: "Funcionalidades",
    panel: "panel4",
    answer:
      "La transferencia se realiza máximo en 12 horas. El dinero se verá reflejado máximo al día hábil siguiente luego de haber solicitado el anticipo.",
  },
  {
    question: "¿Cuántos adelantos puedo hacer en el mes?",
    subGroup: "Funcionalidades",
    panel: "panel5",
    answer: "Puedes hacer todos los que desees.",
  },
];

export const groupEmployeeProducto = [
  {
    question: "¿El servicio prestado por AndaPay es un crédito?",
    subGroup: "Producto",
    panel: "panel1",
    answer:
      "No, el servicio es adelanto de nómina. Este no requiere estudio de crédito ni cobro de intereses.",
  },
];

export const groupEmployeeReporte = [
  {
    question: "¿Dónde puedo ver mis adelantos históricos?",
    subGroup: "Reportes",
    panel: "panel1",
    answer: "En la pestaña Historial luego una vez iniciado sesión.",
  },
];

export const groupEmployeeVerificacion = [
  {
    question: "¿Cómo calculan el monto máximo que puedo adelantar?",
    subGroup: "Verificación",
    panel: "panel1",
    answer:
      "El monto máximo a adelantar es un porcentaje del salario trabajado hasta el momento.",
  },
  {
    question: "¿Cómo sé si la empresa donde trabajo está afiliada con AndaPay?",
    subGroup: "Verificación",
    panel: "panel2",
    answer:
      "Puedes ingresar el nombre de tu empresa en el siguiente link: Andapay.com.co/empresas.",
  },
  {
    question: "Dónde puedo verificar los datos de mi cuenta bancaria.",
    subGroup: "Verificación",
    panel: "panel3",
    answer:
      "Una vez iniciada la sesión, a la derecha de la página principal (Versión Web) o deslizándose hacia abajo (Versión Móvil).",
  },
];

export const groupEmployeeProblemas = [
  {
    question: "¿Qué pasa si no he recibido una invitación de mi empresa?",
    subGroup: "Problemas",
    panel: "panel1",
    answer:
      "Una vez habilitado tu usuario recibirás una notificación a tu celular o correo electrónico.",
  },
  {
    question: "¿Dónde me puedo comunicar si presento algún problema?",
    subGroup: "Problemas",
    panel: "panel2",
    answer:
      "Te puedes comunicar con un asesor de AndaPay que te ayudará a solucionar tus problemas mediante este link: https://wa.me/message/EQKGZPEJKMUMG1.",
  },
  {
    question: "¿Qué pasa si cambio la información de mi cuenta bancaria?",
    subGroup: "Problemas",
    panel: "panel3",
    answer:
      "Debes informar a la persona encargada en tu empresa, nosotros nos pondremos en contacto para actualizar la información.",
  },
  {
    question:
      "¿Por qué al ingresar a la plataforma aparece un mensaje que dice: “pendiente de aprobación”?",
    subGroup: "Problemas",
    panel: "panel4",
    answer:
      "Esto es debido a que estamos verificando la información con la empresa. No te preocupes, este proceso no suele demorarse.",
  },
  {
    question: "¿Qué hago si olvidé mi contraseña?",
    subGroup: "Problemas",
    panel: "panel5",
    answer:
      "En el menú de inicio de sesión aparecerá el botón de “Olvide mi Contraseña” para que la recuperes. Te llegará un mensaje de texto al celular o correo para que puedas ingresar una nueva. ",
  },
  {
    question: "¿Cómo cambio la contraseña?",
    subGroup: "Problemas",
    panel: "panel6",
    answer:
      "Una vez iniciada la sesión, en ajustes de cuenta podrás modificar tu contraseña. ",
  },
  {
    question: "¿Cuál es mi usuario?",
    subGroup: "Problemas",
    panel: "panel7",
    answer:
      "Tu documento único de identidad. Cédula de ciudadanía o Cédula de extranjeria",
  },
];

export const breadcrumbs = [
  {
    underline: "hover",
    key: "1",
    color: "inherit",
    name: "Inicio",
    link: true,
  },
  {
    link: false,
    key: "2",
    color: "text.primary",
    name: "Ayuda",
  },
  {
    link: false,
    key: "3",
    color: "text.primary",
    name: "Preguntas Frecuentes",
  },
];
