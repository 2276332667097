import axios from "axios";
import authHeader from "./auth-header";

const getHomePage = async () => {
  try {
    const res = await axios.get("admin/home", {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getAllUsers = async () => {
  try {
    await axios.get("/users", { headers: authHeader() });
  } catch (error) {
    return error.response?.data?.error;
  }
};

const createReport = async (data) => {
  try {
    const res = await axios.post("/report", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getAllCompanies = async () => {
  try {
    const res = await axios.get("/companies", { headers: authHeader() });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const adminService = {
  getAllUsers,
  getAllCompanies,
  getHomePage,
  createReport,
};

export default adminService;
