import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { AiOutlineCalendar } from "react-icons/ai";
import Slider from "@mui/material/Slider";
import { BiNetworkChart } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md";
import { TbPageBreak } from "react-icons/tb";
import { FaHandHoldingUsd } from "react-icons/fa";
import Badge from "components/badge/Badge";
import AlertModal from "components/modal/AlertModal";
import personOne from "assets/images/person1.webp";
import personTwo from "assets/images/person2.webp";
import personThree from "assets/images/person3.webp";
import personFour from "assets/images/person4.webp";
import formatFunction from "const/formatFunction";

import { AiOutlineCheckCircle } from "react-icons/ai";

const PrettoSlider = styled(Slider)({
  color: "#ff6d4e",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#ff6d4e",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const transferFeatures = [
  {
    id: 1,
    name: "AndaPay",
    description:
      "Sabemos que tus obligaciones no dan espera y las alternativas tradicionales no son las mejores. ¡AndaPay te permite disponer de tu salario trabajado al instante, cuando lo necesites!",
    icon: BiNetworkChart,
  },
  {
    id: 2,
    name: "AndaEdu",
    description:
      "Brindamos contenido personalizado para que rompas las barreras del dinero. AndaEdu te apoya a mejorar tus hábitos de inversión, ahorro y gasto.",
    icon: MdOutlineSchool,
  },
  {
    id: 3,
    name: "AndaPlan",
    description:
      "¿Cómo planeas tu futuro? Con AndaPlan alcanzarás esas metas que tanto deseas y tendrás un colchon para los imprevistos.",
    icon: AiOutlineCalendar,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: "No es un crédito",
    description:
      "Es poder acceder a tu dinero trabajado en cualquier momento del mes y en cualquier lugar.",
    icon: TbPageBreak,
  },
  {
    id: 2,
    name: "Soluciona tus imprevistos",
    description:
      "Si tienes un imprevisto y no dispones de efectivo, podrás solicitar un adelanto en cuestión de segundos.",
    icon: FaHandHoldingUsd,
  },
];

const badges = [
  {
    title: "Adelanto a Antonio Castillo",
    subtitle: "$400.000 COP",
    badgeMessage: "Completado",
    badgeType: "positive",
    imageUrl: personTwo,
  },
  {
    title: "Adelanto a Laura Arocha",
    subtitle: "$2.000.000 COP",
    badgeMessage: "En Proceso",
    badgeType: "inProgress",
    imageUrl: personOne,
  },
  {
    title: "Adelanto a Lina Becerra",
    subtitle: "$150.000 COP",
    badgeMessage: "Completado",
    badgeType: "positive",
    imageUrl: personThree,
  },
  {
    title: "Adelanto a Eduardo Quintero",
    subtitle: "$700.000 COP",
    badgeMessage: "En Proceso",
    badgeType: "inProgress",
    imageUrl: personFour,
  },
];

const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 100,
    label: "$1.000.000",
  },
];

function Services() {
  const maxAdvance = 1000000;
  const [percentageInverse, setPercentageInverse] = useState(100);
  const [openApprove, setOpenApprove] = useState(false);

  const [valueAdvance, setValueAdvance] = useState(
    formatFunction.formatInput("MONEY", maxAdvance)
  );

  const handleChange = (event, newValue) => {
    const porcentageFrom = parseFloat((maxAdvance * newValue) / 100);
    const inverse = (porcentageFrom * 100) / maxAdvance;

    const display = formatFunction.formatInput("MONEY", porcentageFrom);
    setValueAdvance(display);
    setPercentageInverse(inverse);
  };

  const onInputChange = (e) => {
    let target = Number(e.target.value.replace(/[^0-9]+/g, ""));

    if (target > maxAdvance) {
      target = maxAdvance;
    }
    if (target < 0) {
      target = 0;
    }

    const inverse = (target * 100) / maxAdvance;
    setPercentageInverse(inverse);
    const display = formatFunction.formatInput("MONEY", target);
    setValueAdvance(display);
  };

  const approveAdvance = {
    title: "Confirmación de Adelanto",
    logo: <AiOutlineCheckCircle className="text-6xl text-green-600" />,
    subTitle: "Felicitaciones!",
    body: "Realizaste tu primer adelanto. ¿Estás listo para comenzar a usar andapay?",
    confirmation: "Comenzar",
    showCancelBtn: true,
  };

  return (
    <>
      <AlertModal
        open={openApprove}
        setOpen={setOpenApprove}
        info={approveAdvance}
        deleteFunction={() => {
          setOpenApprove(false);
        }}
      />
      <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
        <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
          <svg
            className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Revolucionamos el pago de nómina en Colombia
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
              Cobrar a fin de mes ya es cosa del pasado.
              <br />
              Nunca fue tan fácil y seguro adelantar tu salario.
            </p>
          </div>

          <div
            id="servicios"
            className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8"
          >
            <div className="relative">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Nuestros Servicios
              </h3>
              <p className="mt-3 text-lg text-gray-500 text-justify">
                AndaPay es una aplicación revolucionaria que le da la
                posibilidad a tus colaboradores de recibir su salario devengado
                cuando lo necesiten, estando a un click de distancia de la
                libertad financiera. Forma equipos más felices y productivos,
                dándoles un mayor control sobre su sueldo.
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg  leading-6 text-gray-900 font-semibold">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500 text-justify">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <div
                className="relative md:flex items-center 
            space-x-3 rounded-lg border border-gray-300 bg-white md:px-6 md:py-5 mb-4 
            shadow-sm md:ml-6 mx-auto"
              >
                <div className="col-span-1 lg:col-span-2">
                  <div className="grid lg:grid-cols-3 gap-4">
                    <div className="col-span-1">
                      <div className="h-24 text-center light-shadow-1 rounded-md violet-bg-as box-content">
                        <div className="flex align-items-as h-full justify-center">
                          <div>
                            <p className="text-sm color-light-violet">
                              Adelanto Máximo
                            </p>
                            <p className="text-white text-base font-extrabold">
                              $1.000.000
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 ">
                      <div className="h-24 text-center light-shadow-1 rounded-md violet-bg-as box-content">
                        <div className="flex align-items-as h-full justify-center">
                          <div>
                            <p className="text-sm color-light-violet">
                              Salario Neto
                            </p>
                            <p className="text-white text-base font-extrabold">
                              $2.000.000
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="h-24 text-center  light-shadow-1  rounded-md violet-bg-as box-content ">
                        <div className="flex align-items-as h-full justify-center">
                          <div>
                            <p className="text-sm color-light-violet">
                              Porcentaje Permitido
                            </p>
                            <p className="text-white text-base font-extrabold">
                              50%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white mt-4 rounded-md  light-shadow">
                    <div id="advance-info" className=" pt-4 lg:pt-8">
                      <p className="text-center mb-7 color-v font-bold text-xl lg:text-3xl">
                        Adelanta tu pago 👋
                      </p>
                      <div className="grid md:grid-cols-2 md:gap-8">
                        <div className="col-span-1 text-center md:text-right">
                          <p className="font-extrabold text-base lg:text-xl">
                            Valor de adelanto:
                          </p>
                        </div>
                        <div
                          className="col-span-1 text-center md:text-left"
                          id="valueAdvance"
                        >
                          <input
                            className="curuba-color font-extrabold text-xl lg:text-2xl text-center md:text-left bg-transparent"
                            type="text"
                            value={valueAdvance}
                            onChange={onInputChange}
                            id="inputAdvance"
                            name="inputAdvance"
                          />
                        </div>
                      </div>
                    </div>

                    <div id="slider-info">
                      <div className="flex justify-center px-4 md:px-0">
                        <div className="w-8 mt-3">
                          <PrettoSlider
                            onChange={handleChange}
                            aria-label="pretto slider"
                            defaultValue={100}
                            value={percentageInverse}
                            step={10}
                            marks={marks}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="ruj flex justify-center text-left">
                        <div className="w-4/5 lg:w-2/4 grid grid-cols-2">
                          <div className="col-span-1 color-v font-bold text-lg">
                            Detalle
                          </div>
                          <div className="col-span-1 text-right color-v font-bold text-lg">
                            Valor
                          </div>
                        </div>
                      </div>
                      <div className="ruj flex justify-center text-left">
                        <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
                          <div className="col-span-1">Salario Disponible:</div>
                          <div className="col-span-1 text-right">
                            <InputFormInfo val={2000000} type={"MONEY"} />
                          </div>
                        </div>
                      </div>
                      <div className="ruj flex justify-center text-left">
                        <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
                          <div className="col-span-1">Adelanto:</div>
                          <div className="col-span-1 text-right">
                            {valueAdvance}
                          </div>
                        </div>
                      </div>
                      <div className="ruj">
                        <div className="flex justify-center px-4 md:px-0 my-4 md:my-9">
                          <button
                            className="mx-4 w-full inline-flex justify-center rounded-md border
                         border-transparent bg-indigo-700 py-1 px-1 text-base font-medium 
                         text-white shadow-sm hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenApprove(true)}
                          >
                            🚀 Realizar Adelanto
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <svg
            className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>

          <div className="relative mt-12 sm:mt-16 lg:mt-24" id="usuarios">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Adelantos inmediatos
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Sin filas y sin burocracia, con AndaPay recibes tu adelanto en
                  máximo 24 horas. No necesitas historial crediticio, nuestra
                  tasa de aceptación es del 100%.
                </p>

                <dl className="mt-10 space-y-10">
                  {communicationFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                          <item.icon className="h-8 w-8" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">
                        {item.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0 px-4">
                {badges.map((badge) => (
                  <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 mb-4 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                    <div className="w-full flex justify-between">
                      <div className="flex h-full items-center justify-center">
                        <img
                          className="h-12 w-12 rounded-full relative"
                          src={badge.imageUrl}
                          alt=""
                        />
                        <div className="ml-4">
                          <p className="text-base font-medium text-gray-900">
                            {badge.title}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {badge.subtitle}
                          </p>
                          <div className="md:hidden mt-4">
                            <Badge type={badge.badgeType}>
                              {badge.badgeMessage}
                            </Badge>
                          </div>
                        </div>
                      </div>
                      <div className="md:block hidden">
                        <Badge type={badge.badgeType}>
                          {badge.badgeMessage}
                        </Badge>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function InputFormInfo({ val, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;
  return format;
}

export default Services;
