import React, { useState, useEffect } from "react";

// REACT HOOK FORMS
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

// ICONS
import { MdOutlineClose } from "react-icons/md";

// UTILS
import { post } from "utils";

export default function FloatingFooter() {
  const [showBanner, setShowBanner] = useState(false);
  const [showBannerComplete, setShowBannerComplete] = useState(true);
  const [openForm, setOpenForm] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  const handleBanner = () => setShowBannerComplete(false);

  useEffect(() => {
    const header = document.querySelector("#header");
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowBanner(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0,
      }
    );

    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  if (!showBanner) {
    return null;
  }

  const onSubmit = async (formData) => {
    try {
      const pdfUrl = "/documents/ABC_del_salario_bajo_demanda.pdf";

      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "nombre_del_archivo.pdf";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      handleCloseForm();
      handleBanner();
      reset();

      const res = await post("ebookEmail", JSON.stringify(formData));
      console.log(res);
      return;
    } catch (err) {
      console.log(err);
      toast.error("Ups! Intenta más tarde");
      reset();
      handleCloseForm();
      handleBanner();
    }
  };

  return (
    <>
      {showBannerComplete && (
        <div>
          {openForm ? (
            <div className="pointer-events-none z-40 fixed inset-x-0 bottom-0 px-6 pb-6">
              <div className="pointer-events-auto max-w-md rounded-xl bg-white px-6 shadow-lg ring-1 ring-gray-900/10">
                <div className="pt-3 w-full flex justify-end">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleCloseForm}
                  >
                    <span className="sr-only">Close</span>
                    <MdOutlineClose className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="pb-6">
                  <h1 className="text-xl font-semibold text-gray-700">
                    📕 Cuéntanos un poco sobre ti para que sepamos a quién y
                    dónde enviar nuestro libro electrónico.
                  </h1>
                  <div className="mt-4 flex items-center gap-x-5 md:block md:items-none md:gap-x-0">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="space-y-4"
                    >
                      <div className="text-sm">
                        <label>Nombre</label>
                        <input
                          {...register("name", {
                            required: true,
                            maxLength: 40,
                          })}
                          placeholder="Nombre"
                          name="name"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.name?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                        {errors?.name?.type === "maxLength" && (
                          <p className="text-red-400">
                            El nombre no puede exceder los 40 caracteres
                          </p>
                        )}
                      </div>
                      <div className="text-sm">
                        <label>Correo Electrónico</label>
                        <input
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                          })}
                          placeholder="Ingrese un correo electrónico"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.email?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                        {errors?.email?.type === "pattern" && (
                          <p className="text-red-400">
                            Correo Electrónico no es válido
                          </p>
                        )}
                      </div>
                      <div className="text-sm">
                        <label>Nombre de la empresa</label>
                        <input
                          {...register("nameCompany", {
                            required: true,
                            maxLength: 40,
                          })}
                          placeholder="Ingrese el nombre de la empresa"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.nameCompany?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                        {errors?.nameCompany?.type === "maxLength" && (
                          <p className="text-red-400">
                            El nombre de la empresa no puede exceder los 40
                            caracteres
                          </p>
                        )}
                      </div>
                      <div className="mt-4 flex items-center gap-x-5">
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                        >
                          Descargar
                        </button>
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={handleCloseForm}
                        >
                          Cancelar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="pointer-events-none z-40 fixed inset-x-0 bottom-0 px-6 pb-6">
              <div className="pointer-events-auto max-w-md rounded-xl bg-white px-6 shadow-lg ring-1 ring-gray-900/10">
                <div className="pt-3 w-full flex justify-end">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleBanner}
                  >
                    <span className="sr-only">Close</span>
                    <MdOutlineClose className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="pb-6">
                  <h1 className="text-2xl font-semibold text-gray-700">
                    Necesitas más información acerca del salario bajo demanda
                  </h1>
                  <p className="text-sm leading-6 text-gray-900">
                    Descargue nuestro e-book para obtener más información sobre
                    el salario bajo demanda y AndaPay.
                  </p>
                  <div className="mt-4 flex items-center gap-x-5">
                    <button
                      type="button"
                      onClick={handleOpenForm}
                      className="rounded-md bg-indigo-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                    >
                      📕 Descargue nuestro e-book
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
