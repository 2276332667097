import React from "react";
import formatFunction from "const/formatFunction";
import Eyetoggle from "./eyeToggle/EyeToggle";

function InputForm({ val, field, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;

  return (
    <div className="border-b-2 border-gray-200 md:w-auto">
      <div className="py-4">
        <div className="grid md:grid-cols-3">
          <div className="col-span-1">
            <p className="font-semibold flex-1">{field}:</p>
          </div>
          <div className="col-span-1 md:col-span-2">
            {type === "ACCOUNT" ? (
              <Eyetoggle>{format}</Eyetoggle>
            ) : (
              <p className="flex-1 ">{format}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default InputForm;
