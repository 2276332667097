import React, { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "components/button/Button";
import MaskedInput from "react-input-mask";
import InputPhone from "components/formComponent/InputPhone";
import { plan } from "const/planType";
import { useForm, Controller } from "react-hook-form";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";

function DetailEditEmployee() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Empresas",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Editar",
    },
  ];

  const navigate = useNavigate();

  const cancelCreation = () => {
    navigate("/dashboard/admin/empresas");
  };

  const [content, setContent] = useState([]);
  const [initial, setInitial] = useState("");
  const [loading, setLoading] = useState(false);

  const getInitial = (name) => {
    const splitName = `${name.toUpperCase()[0]}A`;
    return splitName;
  };

  const location = useLocation();

  useEffect(() => {
    const info = location.state;
    setContent(info);
    setInitial(getInitial(info.name));
  }, []);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: location.state.name,
      nit: location.state.nit,
      contactName: location.state.contactName,
      email: location.state.email,
      address: location.state.address,
      payrollSoftware: location.state.payrollSoftware,
      socialReason: location.state.socialReason,
      phone: location.state.phone,
      plan: location.state.plan,
      numberMaxOfUsers: location.state?.numberMaxOfUsers,
    },
  });

  const watchSelectedPlan = watch("plan");

  const onSubmit = (data) => {
    setLoading(true);

    const { plan, numberMaxOfUsers, ...dataCompany } = data;

    const dataUpdate = { ...dataCompany, status: location.state.status };
    const parseMaxOfUsers = parseInt(data.numberMaxOfUsers);
    if (content.plan !== data.plan) {
      dataUpdate.plan = data.plan;
    }

    if (
      dataUpdate.plan === "planB" ||
      (content.plan === "planB" && content.numberMaxOfUsers !== parseMaxOfUsers)
    ) {
      dataUpdate.plan = data.plan;
      dataUpdate.numberMaxOfUsers = parseMaxOfUsers;
    }

    companyService
      .editCompanyById(content.id, dataUpdate)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        navigate("/dashboard/admin/empresas");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigate("/dashboard/admin/empresas");
        toast.error(err.message);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <div className="flex align-items-as">
          <div className="res-circle mr-4">
            <div className="circle-txt font-bold">{initial}</div>
          </div>
          <h1 className="text-2xl font-bold">{content.name}</h1>
        </div>
      </div>
      <div className="mb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white p-4 rounded mb-8">
            <div className="w-full">
              <div className="overflow-x-scroll md:overflow-auto">
                {/**NAME*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="name">Nombre:</label>
                        </div>
                        <div className="flex md:hidden font-semibold">
                          <label for="name">Nombre:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("name", {
                                required: true,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.name?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**NIT*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="nit">NIT:</label>
                        </div>
                        <div className="font-semibold flex md:hidden">
                          <label for="nit">NIT:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <Controller
                              name="nit"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: true,
                                pattern:
                                  /^([0-9]{3}).([0-9]{3}).([0-9]{3})-([0-9])$/,
                              }}
                              render={({ field }) => (
                                <MaskedInput
                                  mask="999.999.999-9"
                                  maskChar=""
                                  value={field.value}
                                  onChange={field.onChange}
                                >
                                  {(inputProps) => (
                                    <input
                                      {...inputProps}
                                      placeholder="Ejemplo: XXX.XXX.XXX-Y"
                                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                                      type="text"
                                    />
                                  )}
                                </MaskedInput>
                              )}
                            />
                            {errors?.nit?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                            {errors?.nit?.type === "pattern" && (
                              <p className="text-red-400">
                                El valor ingresado no es correcto
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**CONTACT NAME*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="contactName">Nombre de Contacto:</label>
                        </div>
                        <div className="flex md:hidden font-semibold">
                          <label for="contactName">Nombre de Contacto:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("contactName", {
                                required: true,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.contactName?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**EMAIL*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="email">Correo Electrónico:</label>
                        </div>
                        <div className="font-semibold flex md:hidden">
                          <label for="email">Correo Electrónico:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.email?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                            {errors?.email?.type === "pattern" && (
                              <p className="text-red-400">
                                Correo Electrónico no es válido
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**SOCIAL REASON*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="name">Razón Social:</label>
                        </div>
                        <div className="flex md:hidden font-semibold">
                          <label for="name">Razón Social:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("socialReason", {
                                required: true,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.socialReason?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**ADDRESS*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="address">Dirección:</label>
                        </div>
                        <div className="font-semibold flex md:hidden">
                          <label for="address">Dirección:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("address", {
                                required: true,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.address?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**SOFTWARE*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="payrollSoftware">
                            Nombre de Software de Nómina:
                          </label>
                        </div>
                        <div className="flex md:hidden font-semibold">
                          <label for="payrollSoftware">
                            Nombre de Software de Nómina:
                          </label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <input
                              {...register("payrollSoftware", {
                                required: true,
                              })}
                              type="text"
                              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            />
                            {errors?.payrollSoftware?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**PHONE*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="phone">Celular:</label>
                        </div>
                        <div className="font-semibold flex md:hidden">
                          <label for="phone">Celular:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <InputPhone control={control} errors={errors} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**PLAN*/}
                <div className="border-b-2 border-gray-200 md:w-auto">
                  <div className="py-4">
                    <div className="grid md:grid-cols-1">
                      <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                        <div className="font-semibold md:w-1/3 md:block hidden">
                          <label for="plan">Modalidad de Plan:</label>
                        </div>
                        <div className="font-semibold flex md:hidden">
                          <label for="plan">Modalidad de Plan:</label>
                        </div>
                        <div className="flex-1">
                          <div className="md:w-3/5">
                            <select
                              className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                              {...register("plan", {
                                required: true,
                              })}
                            >
                              {plan.map((data) => (
                                <option value={data.val}>{data.name}</option>
                              ))}
                            </select>
                            {errors?.phone?.type === "required" && (
                              <p className="text-red-400">
                                Este campo es obligatorio
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {watchSelectedPlan === "planB" && (
                  <div className="border-b-2 border-gray-200 md:w-auto">
                    <div className="py-4">
                      <div className="grid md:grid-cols-1">
                        <div className="col-span-1 flex flex-col md:flex-row md:items-center">
                          <div className="font-semibold md:w-1/3 md:block hidden">
                            <label for="numberMaxOfUsers">
                              Número máximo de usuarios:
                            </label>
                          </div>
                          <div className="font-semibold flex md:hidden">
                            <label for="numberMaxOfUsers">
                              Número máximo de usuarios:
                            </label>
                          </div>
                          <div className="flex-1">
                            <div className="md:w-3/5">
                              <input
                                {...register("numberMaxOfUsers", {
                                  required: true,
                                  min: 1,
                                })}
                                type="number"
                                placeholder="Ingrese el número máximo de usuarios permitidos"
                                className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                              />
                              {errors?.numberMaxOfUsers?.type ===
                                "required" && (
                                <p className="text-red-400">
                                  Este campo es obligatorio
                                </p>
                              )}
                              {errors?.numberMaxOfUsers?.type === "min" && (
                                <p className="text-red-400">
                                  Debes contar con al menos un empleado
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="mr-4">
              <Button
                buttonSize="btn--wide-button"
                buttonColor="thrid-button"
                onClick={cancelCreation}
              >
                Cancelar
              </Button>
            </div>
            {isDirty && (
              <div>
                <Button
                  buttonSize="btn--wide-button"
                  buttonColor="second-button"
                  type="submit"
                >
                  Guardar Cambios
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default DetailEditEmployee;
