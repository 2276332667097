import React, { useState } from "react";
import Loading from "components/Loading";
import { useForm } from "react-hook-form";
import { ccType } from "const/documentType";
import authService from "services/auth.service";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

//MODALS
import AlertModal from "components/modal/AlertModal";

function ResetPassword() {
  // CONST
  const navigate = useNavigate();

  // STATE
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successrMsg, setSuccessMsg] = useState("");

  // REACT HOOK FORMS
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, event) => {
    setLoading(true);
    authService
      .recoverPassword(data)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setLoading(false);
        setSuccessMsg(
          `Hemos enviado un mensaje a tu correo asociado ${data} para que puedas reestablecer tu contraseña.`
        );
        setOpenSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMsg(error.message);
        setOpenError(true);
      });
  };

  const successModal = {
    title: "Recuperar Contraseña",
    logo: <AiOutlineCheckCircle className="text-6xl text-green-600" />,
    subTitle: successrMsg,
    confirmation: "Aceptar",
    bgColor: "bg-green-100",
  };

  const errorModal = {
    title: "Recuperar Contraseña",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    subTitle: errorMsg,
    confirmation: "Aceptar",
    bgColor: "bg-red-100",
  };

  return (
    <>
      {loading && <Loading />}
      <AlertModal
        open={openSuccess}
        setOpen={setOpenSuccess}
        loading={loading}
        info={successModal}
        deleteFunction={() => {
          setOpenSuccess(false);
          navigate("/login");
        }}
      />
      <AlertModal
        open={openError}
        setOpen={setOpenError}
        loading={loading}
        info={errorModal}
        deleteFunction={() => {
          setOpenError(false);
          navigate("/login");
        }}
      />
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="py-4">
            Ingresa tu documento de identificación y te enviaremos un mensaje a
            tu correo asociado para que puedas reestablecer tu contraseña.
          </p>
          <label for="document">Documento de Identidad</label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <select
                {...register("typeOfDocument", {
                  required: true,
                })}
                className="h-full bg-white rounded-md border-transparent bg-transparent py-0 pl-3  
                transition-all focus-within:shadow-lg"
              >
                {ccType.map((data) => (
                  <option value={data.val}>{data.name}</option>
                ))}
              </select>
            </div>
            <input
              {...register("document", {
                required: true,
                pattern: /^[0-9]+$/,
              })}
              placeholder="Ingresa tu documento de identidad"
              className="block w-full rounded-md bg-white overflow-hidden border-gray-300 bor-rad ruj-2 pl-16 
              transition-all focus-within:shadow-lg p-3"
            />
          </div>
          {errors?.document?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.document?.type === "pattern" && (
            <p className="text-red-400">
              El documento solo puede contener números
            </p>
          )}
        </div>
        <div>
          <button
            disabled={loading}
            type="submit"
            className={`py-3 px-8 w-full ${
              loading ? "bg-red-300" : "bg-red-400 "
            } text-white 
          rounded-lg shadow-lg hover:bg-red-300 focus:ring-4 
          focus:ring-red-100 focus:outline-none`}
          >
            Recuperar Contraseña
          </button>
        </div>
      </form>
    </>
  );
}

export default ResetPassword;
