import React from "react";
import { FaRegEdit } from "react-icons/fa";

function TabParamsDetail({ isEdit, setisEdit, data }) {
  return (
    <>
      <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
        <h2 className="text-lg">Parámetros </h2>
        <div
          className="flex justify-between items-center text-black trans-transform cursor-pointer"
          onClick={() => setisEdit(!isEdit)}
        >
          <span className="mr-4">Editar</span>
          <FaRegEdit className="text-green-600 text-xl" />
        </div>
      </div>
      <div className="overflow-x-scroll md:overflow-auto">
        <div className="border-b-2 border-gray-200 md:w-auto">
          <div className="py-4">
            <div className="grid md:grid-cols-3">
              <div className="col-span-1">
                <p className="font-semibold flex-1">
                  Porcentaje máximo de adelanto:
                </p>
              </div>
              <div className="col-span-1 md:col-span-2">
                {data.custompercentageOfAdvance || data.percentage}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabParamsDetail;
