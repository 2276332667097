import Loading from "components/Loading";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller, useForm } from "react-hook-form";
import { post } from "utils";
import { toast } from "react-toastify";
import formatFunction from "const/formatFunction";
import { ipLink } from "config/links";

const SuperAdvance = () => {
  const [loading, setLoading] = useState(false);
  const [maxAdvance, setMaxAdvance] = useState(0);
  const [maxAdvanceFormated, setMaxAdvanceFormated] = useState(0);
  const [comission, setComission] = useState("");
  const [ip, setIp] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      if (formData.timeInCompany) {
        const timeWorkingMap = {
          3: "Entre 3 y 4 meses",
          4: "Entre 4 y 5 meses",
          5: "Entre 5 y 6 meses",
          8: "Más de 6 meses",
        };
        formData.timeInCompany = timeWorkingMap[formData.timeInCompany];
      }

      const superAdvanceData = {
        ip: ip,
        ...formData,
      };
      await post("superadvances", JSON.stringify(superAdvanceData));
      toast.success("Formulario enviado");
    } catch (error) {
      toast.error("Error en el envio del formulario");
    } finally {
      setLoading(false);
    }
  };

  // HELPERS
  const calcularComision = (advance) => {
    const comisionBase = 8000;
    const tramoComision = 150000;
    if (advance <= tramoComision) {
      return comisionBase;
    }

    // Calcula el número de tramos completos de 150,000 en el monto
    const tramosCompletos = Math.floor(advance / tramoComision);

    // Calcula el monto restante después de los tramos completos
    const resto = advance % tramoComision;

    // Calcula la comisión total como la comisión base por cada tramo completo
    // más la comisión base si hay un resto (un tramo adicional parcial)
    const comisionTotal =
      comisionBase * (tramosCompletos + (resto > 0 ? 1 : 0));

    return comisionTotal;
  };

  const advanceRequested = watch("advanceRequested", false);

  const timeInCompanyUseWatch = watch("timeInCompany");
  const monthlySalaryUseWatch = watch("monthlySalary");

  const numAdvancesUseWatch = watch("numAdvances");
  const amountUseWatch = watch("amount");

  useEffect(() => {
    fetch(ipLink)
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let maximo = 0;
    let howManyAdvances = 0;
    if (numAdvancesUseWatch && numAdvancesUseWatch > 0) {
      howManyAdvances = 1;
    }

    if (!monthlySalaryUseWatch) {
      setMaxAdvance(0);
      return;
    }

    if (monthlySalaryUseWatch <= 2000000) {
      switch (howManyAdvances) {
        case 0:
          const maximosPorAntiguedadHasta2M0 = {
            3: 100000,
            4: 150000,
            5: 300000,
          };
          maximo =
            maximosPorAntiguedadHasta2M0[timeInCompanyUseWatch] || 350000; // Default a 350000 si > 6 meses
          break;
        case 1:
          // Cuando hay al menos un adelanto, el máximo es 350000 para 6 meses o más, o 200000 para 4 meses
          const maximosPorAntiguedadHasta2M1 = {
            3: 0,
            4: 200000,
            5: 350000,
          };
          maximo =
            maximosPorAntiguedadHasta2M1[timeInCompanyUseWatch] || 350000; // Default a 350000 si > 6 meses
          break;
        default:
          // Puedes manejar casos para más de 1 adelanto aquí si los hay
          break;
      }
    } else {
      switch (howManyAdvances) {
        case 0:
          // Aquí también utilizamos un mapeo directo para simplificar
          const maximosPorAntiguedadMas2M0 = {
            3: 200000,
            4: 250000,
            5: 500000,
          };
          maximo = maximosPorAntiguedadMas2M0[timeInCompanyUseWatch] || 500000; // Mantenemos el máximo previo si no hay coincidencia
          break;
        case 1:
          const maximosPorAntiguedadMas2M = { 3: 0, 4: 300000, 5: 500000 };
          maximo = maximosPorAntiguedadMas2M[timeInCompanyUseWatch] || 500000;
          break;
        default:
          // Manejo de más adelantos si necesario
          break;
      }
    }

    setMaxAdvance(maximo);
  }, [timeInCompanyUseWatch, monthlySalaryUseWatch, numAdvancesUseWatch]);

  useEffect(() => {
    const formated = formatFunction.formatInput("MONEY", maxAdvance);
    setMaxAdvanceFormated(formated);
  }, [maxAdvance]);

  useEffect(() => {
    if (!amountUseWatch) {
      setComission("");
      return;
    }

    const comision = calcularComision(amountUseWatch);
    const formated = formatFunction.formatInput("MONEY", comision);
    setComission(formated);
  }, [amountUseWatch]);

  return (
    <div>
      {loading && <Loading />}
      <div className="curuba-border">
        <h1 className="text-base text-gray-700">Super Adelantos</h1>
      </div>
      <div className="h-full w-full bg-white  my-4">
        <div className="px-4 md:px-0 py-4 mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h2 className="mt-4 text-3xl md:text-center font-bold tracking-tight text-gray-900">
            Bienvenido a la sección de Superadelantos de AndaPay
          </h2>
          <div className="my-4 md:my-6">
            <p>
              Estamos encantados de ofrecerte una solución financiera flexible y
              a tu alcance. Los Superadelantos son una herramienta innovadora
              que te permite solicitar adelantos de tu salario,
              independientemente de los días trabajados en el mes.
            </p>
            <p className="my-2 font-semibold text-xl">
              ¿Necesitas un impulso financiero para situaciones inesperadas?
            </p>
            <p>
              ¡Completa este breve formulario y descubre cómo los Superadelantos
              pueden beneficiarte!. Es rápido, sencillo y sin complicaciones.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl
            md:col-span-2"
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="mb-2">
                <label
                  htmlFor="monthly-salary"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ¿Cuál es tu salario mensual?
                </label>
                <div className="mt-2">
                  <Controller
                    name="monthlySalary"
                    control={control}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          prefix="$"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder={"Salario mensual"}
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={false}
                          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          decimalsLimit={2}
                          value={props.value}
                          name={props.name}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                  {errors?.monthlySalary?.type === "required" && (
                    <p className="text-sm text-red-400">
                      Este campo es obligatorio
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="monthly-salary"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ¿Cuánto tiempo llevas trabajando en la empresa?
                </label>

                <div className="mt-2">
                  <select
                    {...register("timeInCompany", {
                      required: true,
                    })}
                    name="timeInCompany"
                    id="timeInCompany"
                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="3">Entre 3 y 4 meses</option>
                    <option value="4">Entre 4 y 5 meses</option>
                    <option value="5">Entre 5 y 6 meses</option>
                    <option value="8">Más de 6 meses</option>
                  </select>
                  {errors?.timeInCompany?.type === "required" && (
                    <p className="text-sm text-red-400">
                      Este campo es obligatorio
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="advanceRequested"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ¿Has solicitado super adelantos de salario anteriormente?
                </label>
                <div className="flex gap-x-4">
                  <div className="flex items-center gap-x-2">
                    <input
                      id="advanceYes"
                      name="advanceRequested"
                      value="yes"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      {...register("advanceRequested", {
                        required: "Debes seleccionar una opción.",
                      })}
                    />
                    <label
                      htmlFor="advanceYes"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Si
                    </label>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <input
                      id="advanceNo"
                      name="advanceRequested"
                      value="no"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      {...register("advanceRequested", {
                        required: "Debes seleccionar una opción.",
                      })}
                    />
                    <label
                      htmlFor="advanceNo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors?.advanceRequested && (
                  <p className="text-sm text-red-400">
                    {errors.advanceRequested.message}
                  </p>
                )}
              </div>
              {advanceRequested === "yes" && (
                <div>
                  <div className="mb-2 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="numAdvances"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        ¿Cuántos super adelantos has realizado?
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="numAdvances"
                          id="numAdvances"
                          {...register("numAdvances", {
                            required: advanceRequested === "yes",
                          })}
                          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {errors?.numAdvances?.type === "required" && (
                          <p className="text-sm text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="amountAdvances"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        ¿Cuál ha sido la cantidad de los super adelantos?
                      </label>

                      <div className="mt-2">
                        <Controller
                          name="amountAdvances"
                          control={control}
                          render={(props) => {
                            return (
                              <CurrencyInput
                                prefix="$"
                                onValueChange={(e) => props.field.onChange(e)}
                                placeholder={"Cantidad de super adelantos"}
                                groupSeparator=","
                                decimalSeparator="."
                                allowDecimals={false}
                                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                decimalsLimit={2}
                              />
                            );
                          }}
                          rules={{ required: advanceRequested === "yes" }}
                        />
                        {errors?.amountAdvances?.type === "required" && (
                          <p className="text-sm text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-2">
                <label
                  htmlFor="monthly-salary"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ¿Qué cantidad te gustaría solicitar de superadelanto?
                </label>
                <span className="text-sm">
                  <span className="font-bold">Nota: </span>El máximo monto a
                  adelantar es: {maxAdvanceFormated}
                </span>
                <div className="mt-2">
                  <Controller
                    name="amount"
                    control={control}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          prefix="$"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder={"Cantidad de super adelanto"}
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={false}
                          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          decimalsLimit={2}
                        />
                      );
                    }}
                    rules={{ required: true, max: maxAdvance }}
                  />
                  {errors?.amount?.type === "required" && (
                    <p className="text-sm text-red-400">
                      Este campo es obligatorio
                    </p>
                  )}
                  {errors?.amount?.type === "max" && (
                    <p className="text-sm text-red-400">
                      El campo no puede exceder el monto máximo permitido
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="reason"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ¿Cuál sería el propósito principal de este superadelanto?
                  (Opcional)
                </label>
                <div className="mt-2">
                  <textarea
                    {...register("reason", {
                      required: false,
                    })}
                    id="reason"
                    name="reason"
                    rows={3}
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
              {comission && (
                <div>
                  <span className="font-semibold text-sm">
                    Política de comisiones:
                  </span>
                  <p className="text-sm">
                    La comisión del super adelanto es de {comission}
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Solicitar super adelanto
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuperAdvance;
