import React, { useState } from "react";
import "../dashboardStyles/styleDashboard.css";
import { data } from "./data";
import Sidebar from "components/sidebar/Sidebar";
import Header from "components/header/Header";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BsBriefcase } from "react-icons/bs";
import { FiUsers, FiLayout } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";

const menus = [
  {
    title: "Inicio",
    src: MdOutlineDashboardCustomize,
    route: "/dashboard/admin",
  },
  {
    title: "Empresas",
    src: BsBriefcase,
    gap: true,
    route: "/dashboard/admin/empresas",
  },
  {
    title: "Usuarios",
    src: FiUsers,
    route: "/dashboard/admin/usuarios",
  },
  {
    title: "Adelantos",
    src: FiLayout,
    route: "/dashboard/admin/adelantos",
    gap: true,
  },
  {
    title: "Reportes",
    src: HiOutlineDocumentReport,
    route: "/dashboard/admin/reportes",
    gap: true,
  },
];

const regexMenu = [
  {
    name: "Inicio",
    regex: /^(\/)dashboard\/admin\b(\/?)$/,
  },
  {
    name: "Empresas",
    regex: /^((\/)dashboard\/admin)\/empresas\b(\/?)([^\n]*)/,
  },
  {
    name: "Usuarios",
    regex: /^((\/)dashboard\/admin)\/usuarios\b(\/?)([^\n]*)/,
  },
  {
    name: "Adelantos",
    regex: /^((\/)dashboard\/admin)\/adelantos\b(\/?)([^\n]*)/,
  },
];

function DashboardAdmin(type) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  return (
    <div className="h-full bg-gray-100">
      <div className="h-full">
        <div className="min-h-full">
          {/** SIDEBAR*/}
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            isActive={isActive}
            menus={menus}
            regexCollection={regexMenu}
            secondaryMenu={[]}
            theme={"2"}
          />

          {/** NAVBAR */}
          <header className="bg-white fixed w-screen z-40 border-gray-light">
            <Header
              isActive={isActive}
              setisActive={setisActive}
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
            />
          </header>
          <div
            className={`${
              isActive ? "pl-0" : "lg:pl-64"
            }  h-screen w-full flex flex-col`}
          >
            <div className="flex-grow help-1 mt-6 pt-12 p-6 md:p-12">
              {data[`${type.action}`]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAdmin;
