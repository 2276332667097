import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { roles } from "../const/roles";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logout } from "../slices/auth";

const RequireAuth = ({ allowedRoles }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const location = useLocation();
  const roleRedirect = allowedRoles === roles.Admin ? "/login/admin" : "/login";

  if (!isLoggedIn) {
    return <Navigate to={roleRedirect} state={{ from: location }} replace />;
  } else if (!currentUser.legkgtrm && currentUser?.role === 5150) {
    return <Navigate to="/login/cpk" state={{ from: location }} replace />;
  }

  const token = currentUser.token;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      dispatch(logout());
      return <Navigate to={roleRedirect} state={{ from: location }} replace />;
    }
  }

  return currentUser?.role === allowedRoles ? (
    <Outlet />
  ) : currentUser?.user ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to={roleRedirect} state={{ from: location }} replace />
  );
};

export default RequireAuth;
