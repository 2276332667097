import React, { useEffect, useState, useCallback } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import InputInfo from "components/inputInfo/InputInfo";
import { Button } from "components/button/Button";
import { toast } from "react-toastify";
import advancesService from "services/advances.service";
import Loading from "components/Loading";
import { useParams } from "react-router-dom";

import { sendAdvanceStatus } from "const/advanceStatus";
import { bankInformation } from "const/account";
import { advanceStatus } from "const/advanceStatus";
import { put } from "utils";

function DetailAdvance() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Adelantos",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Detalle",
    },
  ];

  const [advance, setAdvance] = useState({});
  const [user, setUser] = useState({});
  const [bank, setBank] = useState({});
  const [loading, setLoading] = useState(false);

  const { advanceId } = useParams();

  const getHomePage = useCallback(async () => {
    setLoading(true);
    try {
      const res = await advancesService.getAdvanceById(advanceId);
      if (res?.status !== 200) {
        throw new Error(res.message);
      }
      const { bank, user, ...rest } = res?.data;
      setAdvance(rest);
      setUser(user);
      setBank(bank);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [advanceId]);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  const getSignedUrl = () => {
    setLoading(true);
    advancesService
      .getUrlSignedURL(advanceId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        setLoading(false);
        const { data } = res;
        window.open(data, "_blank");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  const cancelActiveAdvance = async () => {
    const cleanData = {
      status: "DENIED",
    };
    const uri = `cancelActiveTransaction/${advanceId}`;
    setLoading(true);
    try {
      const response = await put(uri, cleanData);
      if (!response) {
        throw new Error();
      }
      await getHomePage();
      toast.success(response);
    } catch (error) {
      toast.error(`Error al cancelar el adelanto`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <div className="flex md:justify-between md:align-items-as flex-col md:flex-row">
          <div className="flex align-items-as">
            <div className="res-circle mr-4">
              <div className="circle-txt font-bold">AD</div>
            </div>
            <h1 className="text-2xl font-bold">Adelanto</h1>
          </div>
          <div className="mt-4 md:mt-0 space-x-4">
            {advance?.status !== sendAdvanceStatus.DENIED && (
              <button
                type="button"
                className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={getSignedUrl}
              >
                Descargar Contrato
              </button>
            )}
            {advance?.status === sendAdvanceStatus.ACTIVE && (
              <button
                type="button"
                className="lg:ml-4 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={cancelActiveAdvance}
              >
                Cancelar Adelanto
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8">
        <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-lg">Información sobre adelanto</h2>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputInfo
              val={`${user?.firstName} ${user?.middleName} ${user?.lastName}`}
              field={"Persona que realizó el adelanto"}
            />
            <InputInfo
              val={`${user?.typeOfDocument} ${user?.documentNumber}`}
              field={"Documento de indentidad"}
            />
            <InputInfo val={`${user?.email}`} field={"Correo Electrónico"} />
            <InputInfo
              val={`${user?.phone} `}
              field={"Celular"}
              type={"PHONE"}
            />
            <InputInfo val={`${advance?.companyName}`} field={"Empresa"} />
            <InputInfo
              val={`${advance?.advanceAmount}`}
              field={"Valor de adelanto"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${parseFloat(advance.comissionFee)}`}
              field={"Comisión"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${advance?.finalAmount} `}
              field={"Valor total a descontar"}
              type={"MONEY"}
            />
            <InputInfo
              val={`${advance?.advanceDate}`}
              field={"Fecha"}
              type={"DATE"}
            />
            <InputInfo val={advanceStatus[advance?.status]} field={"Estado"} />
          </div>
        </div>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8">
        <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-lg">Información Bancaria</h2>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputInfo
              val={`${bank?.accountNumber}`}
              field={"Número de cuenta"}
              type={"ACCOUNT"}
            />
            <InputInfo val={`${bank?.accountType}`} field={"Tipo de cuenta"} />
            <InputInfo
              val={`${bankInformation[bank?.bank]}`}
              field={"Banco"}
              islast={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailAdvance;
