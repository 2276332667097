import React, { useState, useEffect, useCallback } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import TabParams from "common/Company/TabParams";
import { useParams } from "react-router-dom";
import ContentSettingDetail from "./ContentSettingDetail";
import ContentSettingEdit from "./ContentSettingEdit";

import { TabPanel, a11yProps } from "components/tabPanel/TabPanelFunction";
import TabAdmin from "common/Company/TabAdmin";

function ContentSettings() {
  const [isEdit, setisEdit] = useState(false);
  const [content, setContent] = useState([]);
  const [adminContent, setAdminContent] = useState([]);

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Empresa",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Configuración",
    },
  ];
  const { companyId } = useParams();

  const getHomePage = useCallback(() => {
    setLoading(true);
    companyService
      .getCompanyById(companyId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { company, admins } = res.data;
        setContent(company);
        setAdminContent(admins);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="my-4"></div>
      <div className="mb-6">
        <div className="bg-white p-4 rounded mb-8">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Información" {...a11yProps(0)} />
                <Tab label="Administradores" {...a11yProps(1)} />
                <Tab label="Parámetros" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="w-full">
                {isEdit ? (
                  <ContentSettingEdit
                    setisEdit={setisEdit}
                    data={content}
                    getHomePage={getHomePage}
                  />
                ) : (
                  <ContentSettingDetail
                    isEdit={isEdit}
                    setisEdit={setisEdit}
                    data={content}
                  />
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TabAdmin
                adminContent={adminContent}
                getCompanyData={getHomePage}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabParams
                dataParams={content}
                getCompanyData={getHomePage}
                isCompanyAdmin={true}
              />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ContentSettings;
