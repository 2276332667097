import React from "react";
import "./Button.css";

const SIZES = [
  "btn--medium-button",
  "btn--large-button",
  "btn--mobile-button",
  "btn--wide-button",
];

export const Button = ({
  children,
  type,
  onClick,
  buttonSize,
  buttonColor,
  classesCustom,
  isDisabled,
}) => {
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : "";
  const needtobeDisabled = isDisabled === "1";

  return (
    <button
      className={`button-37 ${classesCustom} ${buttonColor}  ${checkButtonSize} `}
      onClick={onClick}
      type={type}
      disabled={needtobeDisabled}
    >
      {children}
    </button>
  );
};
