import React, { useEffect, lazy, Suspense } from "react";

import Company from "./Company";
import Header from "./Header";
import Services from "./Services";
import Zipzap from "./Zipzap";
import FloatingFooter from "./FloatingFooter";

const Footer = lazy(() => import("./Footer"));
const Contact = lazy(() => import("./Contact"));

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Services />
      <Company />
      <Zipzap />
      <Suspense fallback={<div>Loading...</div>}>
        <Contact />
        <Footer />
      </Suspense>
      <FloatingFooter />
    </>
  );
}

export default Landing;
