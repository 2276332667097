import React, { useState, useEffect } from "react";
import "../dashboardStyles/styleDashboard.css";
import { data } from "./data";
import Sidebar from "components/sidebar/Sidebar";
import Header from "components/header/Header";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import {
  FiUsers,
  FiUser,
  FiSettings,
  FiLayout,
  FiUpload,
} from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { getCompanyAdmin } from "slices/company";
import { useDispatch, useSelector } from "react-redux";

const regexMenu = [
  {
    name: "Inicio",
    regex: /^(\/)dashboard\b(\/?)$/,
  },
  {
    name: "Usuarios",
    regex: /^(\/)dashboard\/([^\n]*)\b\/usuarios\b(\/?)([^\n]*)$/,
  },
  {
    name: "Adelantos",
    regex: /^(\/)dashboard\/([^\n]*)\b\/adelantos\b(\/?)([^\n]*)$/,
  },
  {
    name: "Perfil",
    regex: /^(\/)dashboard\/perfil$/,
  },
  {
    name: "Importación",
    regex: /^(\/)dashboard\/([^\n]*)\b\/import\b(\/?)([^\n]*)$/,
  },
  {
    name: "Configuración",
    regex: /^(\/)dashboard\/([^\n]*)\b$/,
  },
  {
    name: "Ayuda",
    regex: /^((\/)dashboard)\/ayuda\b(\/?)([^\n]*)/,
  },
];

function DashboardCompany(type) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  const { company: curentCompany } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const menus = [
    {
      title: "Inicio",
      src: MdOutlineDashboardCustomize,
      route: `/dashboard`,
    },
    {
      title: "Usuarios",
      src: FiUsers,
      gap: true,
      route: `/dashboard/${curentCompany.company}/usuarios`,
    },
    {
      title: "Adelantos",
      src: FiLayout,
      route: `/dashboard/${curentCompany.company}/adelantos`,
    },
  ];

  const secondaryMenu = [
    {
      title: "Configuración",
      src: FiSettings,
      route: `/dashboard/${curentCompany.company}`,
    },
    {
      title: "Importación",
      src: FiUpload,
      route: `/dashboard/import`,
      gap: true,
    },
    {
      title: "Perfil",
      src: FiUser,
      route: "/dashboard/perfil",
      gap: true,
    },
    {
      title: "Ayuda",
      src: BiHelpCircle,
      route: "/dashboard/ayuda",
    },
  ];

  useEffect(() => {
    dispatch(getCompanyAdmin());
  }, [dispatch]);

  return (
    <div className="h-full bg-gray-100">
      <div className="h-full">
        <div className="min-h-full">
          {/** SIDEBAR*/}
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            isActive={isActive}
            menus={menus}
            regexCollection={regexMenu}
            theme={"2"}
            secondaryMenu={secondaryMenu}
          />

          {/** NAVBAR */}
          <header className="bg-white fixed w-screen z-40 border-gray-light">
            <Header
              isActive={isActive}
              setisActive={setisActive}
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
            />
          </header>
          <div
            className={`${
              isActive ? "pl-0" : "lg:pl-64"
            }  h-screen w-full flex flex-col`}
          >
            <div className="flex-grow help-1 mt-6 pt-8 p-6 md:p-12 md:pt-8">
              {data[`${type.action}`]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCompany;
