/* This example requires Tailwind CSS v3.0+ */
import { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdOutlineClose } from "react-icons/md";
import { styleModal } from "const/modalStyle";
import FormHeroSection from "components/Form/FormHeroSection";
import { Dialog } from "@headlessui/react";

import pruebaImage from "assets/images/prueba.webp";
import logo from "assets/images/LogoColorBlanco.png";

const navigation = [
  { name: "Empresas", href: "#empresa" },
  { name: "Empleados", href: "#usuarios" },
  { name: "Productos", href: "#servicios" },
  { name: "Contáctenos", href: "#contacto" },
];
function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Modal Form Main Screen
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  const ScheduleDemoModal = () => {
    return (
      <div>
        <Modal
          open={openForm}
          onClose={handleCloseForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal} className="absolute rounded-md w-4/5 md:w-1/3">
            <div className="flex flex-col p-4 bg-gray-100 rounded-t-md">
              <div className="flex justify-between">
                <p className="text-xl">Agendar Demo</p>
                <MdOutlineClose className="text-lg" onClick={handleCloseForm} />
              </div>
              <div className="flex items-center">
                <p className="mr-2">
                  Para Empresas únicamente. ¿Eres empleado?
                </p>
                <Link to="/faq" className="text-blue-600">
                  Ve a soporte
                </Link>
              </div>
            </div>
            <div className="w-full p-4 bg-white rounded-b-md">
              <FormHeroSection setOpenForm={setOpenForm} />
            </div>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <ScheduleDemoModal />
      <div className="relative isolate overflow-hidden" id="header">
        <div
          style={{
            background: `linear-gradient(
              to bottom,
              rgba(20, 8, 44, 0.95),
              rgba(16, 32, 39, 0.8)
            ),
            url(${pruebaImage})`,
            "background-size": "cover",
            "background-position": "center",
          }}
          className="absolute inset-0 -z-10 h-full w-full "
        ></div>
        <div className="px-6 lg:px-8 md:min-h-screen">
          <nav
            className="flex items-center justify-between pt-6"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-10 md:h-16 w-auto relative"
                  src={logo}
                  alt=""
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-lg font-semibold leading-6 text-white"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Link
                to="/login"
                className="rounded-md bg-indigo-500 text-lg  px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Ingresa a la plataforma
              </Link>
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel
              focus="true"
              className="fixed inset-0 z-10 overflow-y-auto purple-dark px-6 py-6 lg:hidden"
            >
              <div className="flex items-center justify-between">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-10 md:h-16 w-auto relative"
                    src={logo}
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <AiOutlineClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <Link
                      to="/login"
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-white hover:bg-gray-400/10"
                    >
                      Ingresa a la plataforma
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-4xl md:text-6xl font-bold tracking-tight text-white ">
                ¿Ya lo trabajaste? <br /> ¡Ya lo ganaste!
              </h1>
              <p className="mt-6 text-2xl leading-8 text-gray-300 text-center">
                La plataforma que le brinda flexibilidad de cobro a tus
                empleados ayudándolos a romper las barreras financieras mediante
                el empoderamiento.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/login"
                  className="rounded-md bg-indigo-500 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Ingresa a la plataforma
                </Link>
                <button
                  onClick={handleOpenForm}
                  className="text-base font-semibold leading-7 text-white"
                >
                  Agendar Demo <span aria-hidden="true">→</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
          <svg
            className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
}

export default Header;
