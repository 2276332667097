import React, { useState } from "react";
import { Button } from "components/button/Button";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import InputPhone from "components/formComponent/InputPhone";
import { ccType } from "const/documentType";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";

function ContentProfileEdit({ isEdit, setisEdit, data, getHomePage }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email: data.email,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      phone: data.phone,
      documentNumber: data.document,
      typeOfDocument: data.documentType,
    },
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = (userData) => {
    setLoading(true);
    companyService
      .editCompanyAdmin(data.id, userData)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setisEdit(false);
        getHomePage();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        setisEdit(false);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white w-auto p-4 rounded mb-8">
          <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
            <h2 className="text-lg">Información Personal</h2>
          </div>
          <div className="w-full">
            <div className="overflow-x-scroll md:overflow-auto">
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1 flex items-center">
                      <p className="font-semibold flex-1 text-sm">
                        Primer Nombre:
                      </p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <input
                        {...register("firstName", {
                          required: true,
                        })}
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Ingresa el primer nombre"
                        className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                      />
                      {errors?.firstName?.type === "required" && (
                        <p className="text-red-400">
                          Este campo es obligatorio
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1 flex items-center">
                      <p className="font-semibold flex-1 text-sm">
                        Segundo Nombre:
                      </p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <input
                          {...register("middleName")}
                          type="text"
                          name="middleName"
                          id="middleName"
                          placeholder="Ingresa el segundo nombre"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1 flex items-center">
                      <p className="font-semibold flex-1">Apellidos:</p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <input
                          {...register("lastName", {
                            required: true,
                          })}
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Ingresa los apellidos"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.lastName?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1 flex">
                      <p className="font-semibold flex-1">Celular:</p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <InputPhone
                          control={control}
                          errors={errors}
                          hasHeight={"input-phone-height"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*
              TODO: BEING ABLE TO CHANGE EMAIL
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1">
                      <p className="font-semibold flex-1">Coreo Electrónico:</p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <input
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                          })}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Correo Electrónico"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.email?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                        {errors?.email?.type === "pattern" && (
                          <p className="text-red-400">
                            Correo Electrónico no es válido
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1">
                      <p className="font-semibold flex-1">Tipo de documento:</p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <select
                          className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
                          {...register("typeOfDocument", {
                            required: true,
                          })}
                        >
                          <option value="" selected disabled>
                            Por favor seleccione el tipo de documento
                          </option>
                          {ccType.map((data) => (
                            <option value={data.val}>{data.name}</option>
                          ))}
                        </select>
                        {errors?.typeOfDocument?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b-2 border-gray-200 md:w-auto">
                <div className="py-4">
                  <div className="grid md:grid-cols-3">
                    <div className="col-span-1">
                      <p className="font-semibold flex-1">
                        Número de documento:
                      </p>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <div className="flex-1">
                        <input
                          {...register("documentNumber", {
                            required: true,
                          })}
                          type="text"
                          name="documentNumber"
                          id="documentNumber"
                          placeholder="Ingresa el primer nombre"
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                        />
                        {errors?.documentNumber?.type === "required" && (
                          <p className="text-red-400">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mr-4">
            <Button
              buttonSize="btn--wide-button"
              buttonColor="thrid-button"
              onClick={() => setisEdit(!isEdit)}
            >
              Cancelar
            </Button>
          </div>
          {isDirty && (
            <div>
              <Button buttonSize="btn--wide-button" buttonColor="second-button">
                Guardar Cambios
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default ContentProfileEdit;
