import React, { useState } from "react";
import "./Login.css";
import LoginInputAdmin from "./LoginInputAdmin";
import LoginInputUser from "./LoginInputUser";

import { clearMessage } from "slices/message";
import { useDispatch } from "react-redux";

function LoginUser() {
  const dispatch = useDispatch();

  // Aux Variables
  const [isIdSelected, setisIdSelected] = useState("label-employer-id");

  const changeRole = (idName) => {
    setisIdSelected(idName);
    dispatch(clearMessage());
  };

  return (
    <div>
      <div id="message-iam">
        <p className="font-bold mt-3">Soy</p>
        <div className="grid lg:grid-cols-2">
          <div className="col-span-1">
            <div className="py-4 lg:py-4 lg:pr-4 lg:pl-0">
              <div
                className={`bor-opt p-3 md:p-4 bor-rad radio-box ${
                  isIdSelected === "label-organization-id"
                    ? "blue-bg-as"
                    : "ruj-2"
                }`}
                onClick={() => {
                  changeRole("label-organization-id");
                }}
              >
                <div className="radio">
                  <input
                    id="label-organization"
                    checked={isIdSelected === "label-organization-id"}
                    name="label-login"
                    type="radio"
                    onClick={() => {
                      changeRole("label-organization-id");
                    }}
                  />
                  <label for="label-organization" className="radio-label">
                    Empresa
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="py-4 lg:py-4 lg:pr-4 lg:pl-0">
              <div
                className={`bor-opt p-3 md:p-4 bor-rad radio-box ${
                  isIdSelected === "label-employer-id" ? "blue-bg-as" : "ruj-2"
                }`}
                onClick={() => {
                  setisIdSelected("label-employer-id");
                }}
              >
                <div className="radio">
                  <input
                    id="label-employer"
                    checked={isIdSelected === "label-employer-id"}
                    name="label-login"
                    type="radio"
                    onClick={() => {
                      setisIdSelected("label-employer-id");
                    }}
                  />
                  <label for="label-employer" className="radio-label">
                    Empleado
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={` ${
          isIdSelected === "label-organization-id" || isIdSelected === ""
            ? "block"
            : "hidden"
        }`}
      >
        <LoginInputAdmin />
      </div>
      <div
        className={` ${
          isIdSelected === "label-employer-id" ? "block" : "hidden "
        }`}
      >
        <LoginInputUser />
      </div>
    </div>
  );
}

export default LoginUser;
