import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputPhone from "components/formComponent/InputPhone";
import { Button } from "components/button/Button";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";

import { useParams } from "react-router-dom";

function ContentSettingEdit({ setisEdit, data, getHomePage }) {
  const [loading, setLoading] = useState(false);

  const { companyId } = useParams();

  const cancelCreation = () => {
    setisEdit(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: data.name,
      nit: data.nit,
      contactName: data.contactName,
      email: data.email,
      address: data.address,
      payrollSoftware: data.payrollSoftware,
      socialReason: data.socialReason,
      phone: data.phone,
      plan: data.plan,
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    companyService
      .editCompanyById(companyId, data)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setisEdit(false);
        getHomePage();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        setisEdit(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-2 mb-6  border-b-2 border-pink-600 w-full flex">
          <h2 className="text-lg">Información Empresa</h2>
        </div>
        <div className="overflow-x-scroll md:overflow-auto">
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Nombre:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("name", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.name?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">NIT:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("nit", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.nit?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Celular:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <InputPhone control={control} errors={errors} />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Razón Social:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("socialReason", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.socialReason?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Correo Electrónico:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.email?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <p className="text-red-400">
                        Correo Electrónico no es válido
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Dirección:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("address", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.address?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">Nombre de Contacto:</p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("contactName", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.contactName?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">
                    Nombre de Software de Nómina:
                  </p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <p className="flex-1 ">
                    <input
                      {...register("payrollSoftware", {
                        required: true,
                      })}
                      type="text"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.payrollSoftware?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <div className="mr-4">
              <Button
                buttonSize="btn--wide-button"
                onClick={cancelCreation}
                buttonColor="thrid-button"
              >
                Cancelar
              </Button>
            </div>
            {isDirty && (
              <div>
                <Button
                  buttonSize="btn--wide-button"
                  buttonColor="second-button"
                  type="submit"
                >
                  Guardar Cambios
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default ContentSettingEdit;
