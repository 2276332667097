import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyService from "../services/company.service";
import { setMessage } from "./message";

export const getCompanyAdmin = createAsyncThunk(
  "company/id",
  async (thunkAPI) => {
    try {
      const data = await companyService.getCompanyByAdmin();
      return { company: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { company: "" };

const companySlice = createSlice({
  name: "company",
  initialState,
  extraReducers: {
    [getCompanyAdmin.fulfilled]: (state, action) => {
      state.company = action.payload;
    },
  },
});

const { reducer, actions } = companySlice;

export const { setCompany } = actions;
export default reducer;
