import React from "react";
import { FaRegEdit } from "react-icons/fa";
import formatFunction from "const/formatFunction";
import { displayPlan } from "const/planType";

function ContentSettingDetail({ isEdit, setisEdit, data }) {
  return (
    <div>
      <div className="pb-2 mb-6  border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
        <h2 className="text-lg">Información Empresa</h2>
        <div
          className="flex justify-between items-center text-black trans-transform cursor-pointer"
          onClick={() => setisEdit(!isEdit)}
        >
          <span className="mr-4">Editar</span>
          <FaRegEdit className="text-green-600 text-xl" />
        </div>
      </div>
      <div className="overflow-x-scroll md:overflow-auto">
        <InputForm val={data?.name} field={"Nombre"} />
        <InputForm val={data?.nit} field={"NIT"} />
        <InputForm val={data?.phone} field={"Celular"} type={"PHONE"} />
        <InputForm val={data?.socialReason} field={"Razón Social"} />
        <InputForm val={displayPlan[data.plan]} field={"Modalidad de Plan"} />
        <InputForm val={data?.email} field={"Correo Electrónico"} />
        <InputForm val={data?.address} field={"Dirección"} />
        <InputForm val={data?.contactName} field={"Nombre de Contacto"} />
        <InputForm
          val={data.payrollSoftware}
          field={"Nombre de Software de Nómina"}
        />
      </div>
    </div>
  );
}

function InputForm({ val, field, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;
  return (
    <div className="border-b-2 border-gray-200 md:w-auto">
      <div className="py-4">
        <div className="grid md:grid-cols-3">
          <div className="col-span-1">
            <p className="font-semibold flex-1">{field}:</p>
          </div>
          <div className="col-span-1 md:col-span-2">
            <p className="flex-1 ">{format}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentSettingDetail;
