import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "components/button/Button";
import CurrencyInput from "react-currency-input-field";
import userService from "services/user.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";

import { useParams } from "react-router-dom";

function TabParamsEdit({ isEdit, setisEdit, data, getUserData }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      custompercentageOfAdvance:
        data.custompercentageOfAdvance || data.percentage,
    },
  });

  const [loading, setLoading] = useState(false);

  const { userId } = useParams();

  const onSubmit = (userData) => {
    setLoading(true);
    const dataUpdate = {
      user: userData,
    };

    userService
      .editUser(userId, dataUpdate)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getUserData();
        setLoading(false);
        setisEdit(!isEdit);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        setisEdit(!isEdit);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-4 mb-8 border-b-2 border-pink-600 w-full flex">
          <h2 className="text-lg">Parámetros</h2>
        </div>
        <div className="overflow-x-scroll md:overflow-auto">
          <div className="border-b-2 border-gray-200 md:w-auto">
            <div className="py-4">
              <div className="grid md:grid-cols-3">
                <div className="col-span-1">
                  <p className="font-semibold flex-1">
                    Porcentaje máximo de adelanto:
                  </p>
                </div>
                <div className="col-span-1 md:col-span-2">
                  <Controller
                    name="custompercentageOfAdvance"
                    control={control}
                    rules={{
                      required: true,
                      min: 20,
                      max: 50,
                    }}
                    render={({ field }) => (
                      <CurrencyInput
                        suffix="%"
                        onValueChange={field.onChange}
                        placeholder="Ingrese el porcentaje máximo de adelanto"
                        groupSeparator=","
                        decimalSeparator="."
                        value={field.value}
                        allowDecimals={false}
                        className="bg-white w-full md:w-1/2 p-2 overflow-hidden 
    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                        decimalsLimit={2}
                      />
                    )}
                  />
                  {errors?.custompercentageOfAdvance?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.custompercentageOfAdvance?.type === "min" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser mínimo del 20%
                    </p>
                  )}
                  {errors?.custompercentageOfAdvance?.type === "max" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser máximo del 50%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <div className="mr-4">
            <Button
              buttonSize="btn--wide-button"
              buttonColor="thrid-button"
              onClick={() => setisEdit(!isEdit)}
            >
              Cancelar
            </Button>
          </div>
          {isDirty && (
            <div>
              <Button
                buttonSize="btn--wide-button"
                type="submit"
                buttonColor="second-button"
              >
                Guardar Cambios
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default TabParamsEdit;
