import { formatValue } from "react-currency-input-field";
import dayjs from "dayjs";

// AUX FUNCTIONS

const formatInput = (type, val) => {
  if (!val && val !== 0) {
    return val;
  }
  switch (type) {
    case "PHONE":
      return phoneFormat(val);
    case "MONEY":
      return moneyFormat(val);
    case "MONEYTOP":
      return moneyFormatTop(val);
    case "MONEYDB":
      return moneyFormatString(val);
    case "DATE":
      return dateFormat(val);
    default:
      return val;
  }
};

const phoneFormat = (val) => {
  const copy = val;
  const pre = copy.slice(2, 5);
  const indicatorOne = copy.slice(5, 8);
  const indicatorTwo = copy.slice(8, 12);
  const phone = `+57 (${pre}) - ${indicatorOne} - ${indicatorTwo}`;
  return phone;
};

const moneyFormatTop = (val) => {
  const formattedValue1 = val.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
  });
  return formattedValue1;
};

const moneyFormatString = (val) => {
  const valFinal = parseFloat(val).toFixed(3);
  const formattedValue1 = valFinal.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
  });
  return formattedValue1;
};

const moneyFormat = (val) => {
  const subParse = parseFloat(val).toFixed(0);
  const parseVal = Math.ceil(subParse);

  const valParse = parseVal.toString();
  const formattedValue1 = formatValue({
    value: valParse,
    prefix: "$",
    intlConfig: {
      locale: "es-CO",
      currency: "COP",
    },
  });

  return formattedValue1;
};

const dateFormat = (val) => {
  const date = dayjs(val).format("DD/MM/YYYY");
  return date;
};

const formatFunction = {
  formatInput,
};

export default formatFunction;
