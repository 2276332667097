import React, { useMemo, useState } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Alert from "@mui/material/Alert";
import { Button } from "components/button/Button";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Loading from "components/Loading";

// ICONS
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegEye } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";

//MODALS
import AlertModal from "components/modal/AlertModal";
import InformationModal from "components/modal/InformationModal";
import { useNavigate, useLocation } from "react-router-dom";
import companyService from "services/company.service";

function TabAdmin({ adminContent, getCompanyData }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const createAdmin = () => {
    navigate("admin", { state: { from: location.pathname } });
  };

  return (
    <div className="w-full">
      <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex ">
        <h2 className="text-lg">Configuración de Administradores</h2>
      </div>
      <div className="mb-4">
        <Alert severity="warning">
          La empresa puede tener hasta 3 Administradores.
        </Alert>
      </div>
      {adminContent.length < 3 && (
        <div className="md:hidden block my-4">
          <Button
            buttonSize="btn--wide-button"
            buttonColor="second-button"
            classesCustom="w-full"
            onClick={createAdmin}
          >
            Crear Administrador
          </Button>
        </div>
      )}
      <ThemeProvider theme={createTheme(theme, esES)}>
        <TableAdmins data={adminContent} getCompanyData={getCompanyData} />
      </ThemeProvider>
    </div>
  );
}

const TableAdmins = ({ data, getCompanyData }) => {
  const [open, setOpen] = useState(false);
  const [infoAdvance, setInfoAdvance] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const location = useLocation();

  const handleOpenInfo = (info) => {
    setOpenInfo(true);
    setInfoAdvance(getDataConverted(info));
  };

  const handleOpen = (info) => {
    setOpen(true);
    setCurrentUser({
      ...currentUser,
      id: info.id,
      company: info.company,
    });
  };

  const navigate = useNavigate();
  const createAdmin = () => {
    navigate("admin", { state: { from: location.pathname } });
  };

  const deleteAdmin = () => {
    setLoading(true);
    setOpen(false);
    companyService
      .deleteAdmin(currentUser)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getCompanyData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.firstName} ${row?.middleName}`,
        header: "Nombre",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "lastName",
        header: "Apellidos",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "email",
        header: "Correo Electronico",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
    ],
    []
  );

  const modalInfo = {
    title: "Eliminar Administrador",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    body: "¿Está seguro de querer eliminar el administrador?",
    confirmation: "Eliminar",
    showCancelBtn: true,
    bgColor: "bg-red-100",
  };

  return (
    <>
      {loading && <Loading />}
      <AlertModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        info={modalInfo}
        deleteFunction={deleteAdmin}
      />
      <InformationModal
        open={openInfo}
        title={"Información Administrador"}
        setOpen={setOpenInfo}
        info={infoAdvance}
      />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowActions
        enableColumnFilterModes
        positionActionsColumn="last"
        initialState={{ showGlobalFilter: true }}
        renderTopToolbarCustomActions={() => (
          <div className="hidden md:block mb-2">
            {data.length < 3 && (
              <Button
                onClick={createAdmin}
                buttonSize="btn--wide-button"
                buttonColor="second-button"
              >
                Crear Administrador
              </Button>
            )}
          </div>
        )}
        renderRowActions={({ row }) => (
          <div style={{ display: "flex", flexWrap: "nowrap", gap: "0.5rem" }}>
            <FaRegEye
              onClick={() => handleOpenInfo(row.original)}
              className="text-xl color-mid mr-2"
            />
            {!row.original.currentUser && (
              <HiOutlineTrash
                onClick={() => handleOpen(row.original)}
                className="text-xl color-mid mr-2 red-override"
              />
            )}
          </div>
        )}
      />
    </>
  );
};

function getDataConverted(info) {
  const rows = [
    createData("Nombre", `${info?.firstName} ${info.middleName}`),
    createData("Apellidos", info.lastName),
    createData("Email", info.email),
    createData("Fecha de Creación", dayjs(info.createdAt).format("DD/MM/YYYY")),
  ];
  return rows;
}

function createData(field, val) {
  return { field, val };
}

export default TabAdmin;
