export const asuntos = {
  matterTypeA: "Crédito de Libranza",
  matterTypeB: "Embargo de salario",
  matterTypeC: "Licencia No Remunerada",
  matterTypeD: "Descuentos",
  matterTypeE: "Incapacidades Médicas",
  matterTypeF: "Otros",
};

export const tipoChart = {
  percentage: "Porcentaje",
  amount: "Valor",
};
