import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: "Inicio",
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setFocus: (state, action) => {
      state.sidebar = action.payload;
    },
  },
});

const { reducer, actions } = sidebarSlice;

export const { setFocus, isOpen } = actions;
export default reducer;
