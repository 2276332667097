import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { daysToCutOff } from "const/planType";
import { paymentDisplayPlan } from "const/paymentPlan";
import InputForm from "components/InputForm";

function TabParamsDetail({ isEdit, setisEdit, data }) {
  const isBiweekly = data.paymentType === "BIWEEKLY";
  return (
    <>
      <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
        <h2 className="text-lg">Parámetros </h2>
        <div
          className="flex justify-between items-center text-black trans-transform cursor-pointer"
          onClick={() => setisEdit(!isEdit)}
        >
          <span className="mr-4">Editar</span>
          <FaRegEdit className="text-green-600 text-xl" />
        </div>
      </div>
      <div className="overflow-x-scroll md:overflow-auto">
        <InputForm val={data?.commission} field={"Comisión"} type={"MONEY"} />
        <InputForm
          val={data?.customMaxAdvance}
          field={"Adelanto tope antes del 1%"}
          type={"MONEY"}
        />
        <InputForm
          val={`${data?.percentageOfAdvance}%`}
          field={"Porcentaje máximo de adelanto"}
        />
        <InputForm
          val={paymentDisplayPlan[data.paymentType]}
          field={"Modalidad de pago"}
        />
        <InputForm
          val={daysToCutOff[data.firstCutOffDate]}
          field={isBiweekly ? "Primera Fecha de corte" : "Fecha de corte"}
        />
        <InputForm
          val={daysToCutOff[data.firstPayDay]}
          field={
            isBiweekly
              ? " Primera Fecha de liquidación"
              : "Fecha de liquidación"
          }
        />
        {isBiweekly && (
          <InputForm
            val={daysToCutOff[data.secondCutOffDate]}
            field={"Segunda Fecha de corte"}
          />
        )}
        {isBiweekly && (
          <InputForm
            val={daysToCutOff[data.secondPayDay]}
            field={"Segunda Fecha de liquidación"}
          />
        )}
      </div>
    </>
  );
}

export default TabParamsDetail;
