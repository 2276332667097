export const paymentDisplayPlan = {
  MONTHLY: "Mensual",
  BIWEEKLY: "Quincenal",
};

export const paymentPlan = [
  {
    name: "Mensual",
    val: "MONTHLY",
  },
  {
    name: "Quincenal",
    val: "BIWEEKLY",
  },
];
