import { Popover, Transition } from "@headlessui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Fragment } from "react";

const FlyoutMenu = ({ menu }) => {
  return (
    <Popover className="relative ">
      <Popover.Button className="h-8 rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <BiDotsVerticalRounded className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-5 flex w-screen max-w-xs md:max-w-max px-4">
          <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="relative grid gap-6 bg-white p-7 sm:gap-8 sm:p-8">
              {menu.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="group -m-3 p-4 flex items-start rounded-lg hover:bg-gray-50"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white">
                    <item.icon
                      className="flex-shrink-0 h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="ml-4">
                    <p className="font-medium text-gray-900">{item.name}</p>
                    <p className="mt-1 text-sm text-gray-500">
                      {item.description}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default FlyoutMenu;
