import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "components/button/Button";
import dayjs from "dayjs";
import ResponsiveDatePickers from "components/DatePicker";
import { toast } from "react-toastify";
import adminService from "services/admin.service";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";

function AdvanceReport() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // Aux Variables
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [endDate, setEndDate] = useState(dayjs());
  const [beginDate, setBeginDate] = useState(dayjs());

  const onSubmit = (reportData) => {
    setLoading(true);
    const formattedDateBegin = reportData.beginDate.format("YYYY/MM/DD");
    const formattedDateEnd = reportData.endDate.format("YYYY/MM/DD");

    const report = {
      beginDate: formattedDateBegin,
      endDate: formattedDateEnd,
      company: reportData.company,
    };

    adminService
      .createReport(report)
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          throw new Error(res.message);
        }

        const csvBlob = new Blob([res.data], { type: "text/csv" });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${formattedDateBegin}_${formattedDateEnd}_${reportData.company}`;
        link.click();

        toast.success("Archivo Generado Correctamente");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    adminService
      .getAllCompanies()
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        const companyData = data.companies;

        setCompanies(companyData);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`/dashboard/admin/usuarios`);
      });
  }, [navigate]);

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2 mb-4 flex border-b-2 border-pink-600">
        <h1 className="text-xl">Reportes</h1>
      </div>
      <div className="bg-white w-auto p-4 rounded mb-8 light-shadow">
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-x-scroll md:overflow-auto">
              <p className="text-sm">Seleccionar filtros</p>
              <div className="grid md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label for="company">Empresa (*)</label>
                  <select
                    className="bg-white w-full select-padding overflow-hidden items-center bor-rad border-datePicker  transition-all"
                    {...register("company", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione una empresa
                    </option>
                    {companies.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.company?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div>
                  <label for="company">Fecha de Inicio (*)</label>
                  <div>
                    <ResponsiveDatePickers
                      value={beginDate}
                      control={control}
                      setValue={setBeginDate}
                      name={"beginDate"}
                    />
                    {errors?.beginDate?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.beginDate?.type === "validate" && (
                      <p className="text-red-400">
                        La fecha de Inicio no puede ser mayor que la fecha final
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label for="endDate">Fecha Final (*)</label>
                  <ResponsiveDatePickers
                    value={endDate}
                    control={control}
                    setValue={setEndDate}
                    name={"endDate"}
                  />
                  {errors?.endDate?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <Button
                  type="submit"
                  buttonSize="btn--wide-button"
                  buttonColor="second-button"
                  classesCustom={` ${loading ? "opacity-50" : ""} `}
                >
                  Generar Reporte
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdvanceReport;
