import ContentCompany from "./Company/ContentCompany";
import ContentHome from "./ContentHome";
import ContentCreateEmployee from "./Users/ContentCreateEmployee";
import ContentCreateCompany from "./Company/ContentCreateCompany";
import DetailCompany from "./Company/DetailCompany";
import DetailEditCompany from "./Company/DetailEditCompany";
import DetailEmployee from "common/Employee/DetailEmployee";
import WrapperUser from "./Users/WrapperUser";
import EditEmployee from "common/Employee/DetailEditEmployee";
import CreateCompanyAdmin from "common/CompanyAdmin/CreateCompanyAdmin";
import DetailAdvance from "./Advance/DetailAdvance";
import ContentAdvance from "./Advance/ContentAdvance";
import AdvanceReport from "./Reports/AdvanceReport";

// Admin Site
export const data = {
  home: <ContentHome />,
  company: <ContentCompany />,
  createCompanies: <ContentCreateCompany />,
  advancesGeneral: <ContentAdvance />,
  createUsers: <ContentCreateEmployee />,
  detailAdvance: <DetailAdvance />,
  users: <WrapperUser />,
  detailUser: <DetailEmployee />,
  detailCompany: <DetailCompany />,
  editDetailCompany: <DetailEditCompany />,
  editDetailUser: <EditEmployee role={1807} />,
  createAdminCompany: <CreateCompanyAdmin />,
  advanceReport: <AdvanceReport />,
};
