import React, { useMemo, useEffect, useState, useCallback } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "components/button/Button";
import { useNavigate } from "react-router-dom";
import BoxColor from "common/Boxcolor";
import AlertModal from "components/modal/AlertModal";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import adminService from "services/admin.service";
import advancesService from "services/advances.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { sendAdvanceStatus } from "const/advanceStatus";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import FormatNumber from "components/FormatNumber";

const cancelInfo = {
  title: "Cancelar Adelanto",
  logo: <AiOutlineCloseCircle className="text-6xl text-red-600" />,
  body: "¿Está seguro de querer cancelar los adelantos seleccionados?",
  confirmation: "Si, seguro",
  subTitle: "Cancelar Adelanto",
  showCancelBtn: true,
  bgColor: "bg-red-100",
};

const approveInfo = {
  title: "Aprobar Adelanto",
  logo: <AiOutlineCheckCircle className="text-6xl text-green-600" />,
  body: "¿Está seguro de querer aprobar los adelantos seleccionados?",
  confirmation: "Si, seguro",
  subTitle: "Aprobar Adelanto",
  showCancelBtn: true,
  bgColor: "bg-green-100",
};

function ContentHome() {
  const theme = useTheme();
  const [content, setContent] = useState({});
  const [advances, setAdvances] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const users = () => {
    navigate("usuarios");
  };

  const companies = () => {
    navigate("empresas");
  };

  const advancesRoute = () => {
    navigate("adelantos");
  };

  const getHomePage = useCallback(() => {
    setLoading(true);
    adminService
      .getHomePage()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setContent(data);
        setAdvances(data?.pendingAdvances);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <h1 className="text-xl">Inicio</h1>
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-4">
        <BoxColor
          title={"Empresas Activas"}
          number={content.companiesCount}
          borderColor={"border-1-as"}
          action={companies}
          showLink={true}
        ></BoxColor>
        <BoxColor
          title={"Usuarios Activos"}
          number={content.usersCount}
          borderColor={"border-2-as"}
          action={users}
          showLink={true}
        ></BoxColor>
        <BoxColor
          title={"Adelantos En Proceso"}
          number={content.inProcessAdvances}
          borderColor={"border-3-as"}
          action={advancesRoute}
          showLink={false}
        ></BoxColor>
        <BoxColor
          title={"Adelantos Completados"}
          number={content.completedAdvances}
          borderColor={"border-4-as"}
          action={advancesRoute}
          showLink={false}
        ></BoxColor>
      </div>
      <div className="mt-4 mb-4 flex flex-col">
        <h1 className="text-xl mb-1">
          Adelantos Pendientes ({content.pendingAdvances?.length})
        </h1>
      </div>
      <div>
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableAdvancePending data={advances} getHomePage={getHomePage} />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableAdvancePending = ({ data, getHomePage }) => {
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [openApproved, setOpenApproved] = useState(false);
  const [openCanceled, setOpenCanceled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCanceled = () => setOpenCanceled(true);

  const handleApproved = () => setOpenApproved(true);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        header: "Nombre",
        filterFn: "fuzzy",
        accessorFn: (row) =>
          `${row?.userInfo?.firstName} ${row?.userInfo?.lastName}`,
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "companyInfo.name",
        header: "Empresa",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "advanceAmount",
        header: "Valor de adelanto",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorKey: "finalAmount",
        header: "Valor final",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorFn: (row) =>
          new Date(dayjs.tz(row.advanceDate, "America/Panama")), //convert to Date for sorting and filtering
        header: "Fecha", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  const updateStatusAdvance = (status) => {
    setLoading(true);
    setOpenApproved(false);
    setOpenCanceled(false);
    const dataUpdate = {
      status: status,
    };
    advancesService
      .updateMultipleAdvance(dataUpdate, rowSelection)
      .then((res) => {
        setOpenApproved(false);
        if (res.status !== 200) {
          throw new Error(
            "No fue posible actualizar el estado de los adelantos"
          );
        }
        getHomePage();
        setLoading(false);
        toast.success(
          "El estado de los adelantos fue actualizado exitosamente."
        );
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <AlertModal
        open={openApproved}
        loading={loading}
        setOpen={setOpenApproved}
        info={approveInfo}
        deleteFunction={() => updateStatusAdvance(sendAdvanceStatus.ACTIVE)}
      />
      <AlertModal
        open={openCanceled}
        loading={loading}
        setOpen={setOpenCanceled}
        info={cancelInfo}
        deleteFunction={() => updateStatusAdvance(sendAdvanceStatus.DENIED)}
      />

      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowSelection
        getRowId={(row) => row.id} //give each row a more useful id
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ rowSelection }}
        enableColumnFilterModes
        positionActionsColumn="last"
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "0.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "0.8rem",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <div className="mb-2 flex flex-col md:flex-row">
            <div className="mr-0 md:mr-4 mb-4 md:mb-0">
              <Button
                onClick={handleApproved}
                buttonSize="btn--wide-button"
                buttonColor="second-button"
                classesCustom={`${
                  Object.keys(rowSelection)?.length === 0 ? "opacity-50" : ""
                }`}
                isDisabled={`${
                  Object.keys(rowSelection)?.length === 0 ? "1" : "0"
                }`}
              >
                Aprobar Selección
              </Button>
            </div>
            <div>
              <Button
                onClick={handleCanceled}
                buttonSize="btn--wide-button"
                buttonColor="thrid-button"
                classesCustom={`${
                  Object.keys(rowSelection)?.length === 0 ? "opacity-50" : ""
                }`}
                isDisabled={`${
                  Object.keys(rowSelection).length === 0 ? "1" : "0"
                }`}
              >
                Declinar Selección
              </Button>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default ContentHome;
