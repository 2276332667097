import React, { useState } from "react";
import { Button } from "components/button/Button";
import { useForm } from "react-hook-form";
import InputPhone from "components/formComponent/InputPhone";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import { ccType } from "const/documentType";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "components/Loading";

function CreateCompanyAdmin() {
  // Imports
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const success_message = "El Administrador fue creado correctamente!";

  // Aux Variables
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();

  // Function
  const cancelCreation = () => {
    navigate(-1);
  };

  const location = useLocation();

  const onSubmit = (data) => {
    setLoading(true);
    data.companyId = companyId;
    companyService
      .createCompanyAdmin(data)
      .then((res) => {
        if (res?.status !== 201) {
          throw new Error(res.message);
        }
        toast.success(success_message);
        setLoading(false);
        navigate(`${location.state.from}`);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
        navigate(`${location.state.from}`);
      });
  };

  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Empresa",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Crear Admin",
    },
  ];

  return (
    <div>
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <h1 className="text-xl font-bold">Crear Administrador</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <div className="bg-white p-4 rounded mb-8">
            <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
              <h2 className="text-lg">Información Personal</h2>
            </div>
            <div className="grid md:grid-cols-2 md:gap-4">
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="firstName">Primer Nombre</label>
                  <input
                    {...register("firstName", {
                      required: true,
                    })}
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Ingresa el primer nombre"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.firstName?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="middleName">Segundo Nombre</label>
                  <input
                    {...register("middleName")}
                    type="text"
                    name="middleName"
                    id="middleName"
                    placeholder="Ingresa el segundo nombre"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                </div>

                <div className="mb-4">
                  <label for="lastName">Apellidos</label>
                  <input
                    {...register("lastName", {
                      required: true,
                    })}
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Ingresa los apellidos"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.lastName?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="email">Correo Electrónico</label>
                  <input
                    {...register("email", {
                      required: true,
                    })}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Correo Electrónico"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.email?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="typeOfDocument">Tipo de documento</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("typeOfDocument")}
                  >
                    {ccType.map((data) => (
                      <option value={data.val}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.typeOfDocument?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="documentNumber">Número de Documento</label>
                  <input
                    {...register("documentNumber", {
                      required: true,
                      pattern: /^[0-9]+$/,
                    })}
                    type="text"
                    name="documentNumber"
                    id="documentNumber"
                    placeholder="Número de Documento de Identidad"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.documentNumber?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.documentNumber?.type === "pattern" && (
                    <p className="text-red-400">
                      El documento de identidad solo puede contener números
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="phone">Celular</label>
                  <InputPhone control={control} errors={errors} />
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div>
              <Loading />
            </div>
          )}
          <div className="flex justify-end">
            <div className="mr-4">
              <Button
                disabled={loading}
                onClick={cancelCreation}
                buttonSize="btn--wide-button"
                buttonColor="thrid-button"
                classesCustom={` ${loading ? "opacity-50" : ""} `}
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                disabled={loading}
                classesCustom={`${loading ? "opacity-50" : ""} `}
                type="submit"
                buttonColor="second-button"
                buttonSize="btn--wide-button"
              >
                Crear Admin
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateCompanyAdmin;
