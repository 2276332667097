export const plan = [
  {
    name: "Individual",
    val: "planA",
  },
  {
    name: "Empresa",
    val: "planB",
  },
];

export const displayPlan = {
  planA: "Individual",
  planB: "Empresa",
};

export const daysToCutOff = {
  1: "1.º día del mes",
  2: "2.º día del mes",
  3: "3.º día del mes",
  4: "4.º día del mes",
  5: "5.º día del mes",
  6: "6.º día del mes",
  7: "7.º día del mes",
  8: "8.º día del mes",
  9: "9.º día del mes",
  10: "10.º día del mes",
  11: "11.º día del mes",
  12: "12.º día del mes",
  13: "13.º día del mes",
  14: "14.º día del mes",
  15: "15.º día del mes",
  16: "16.º día del mes",
  17: "17.º día del mes",
  18: "18.º día del mes",
  19: "19.º día del mes",
  20: "20.º día del mes",
  21: "21.º día del mes",
  22: "22.º día del mes",
  23: "23.º día del mes",
  24: "24.º día del mes",
  25: "25.º día del mes",
  26: "26.º día del mes",
  27: "27.º día del mes",
  28: "28.º día del mes",
  29: "29.º día del mes",
  30: "30.º día del mes",
  31: "31.º día del mes",
  UDMH: "Último día del mes hábil",
};
