import { BsCheck } from "react-icons/bs";

export default function Steps({ steps }) {
  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-t-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === "complete" ? (
              <div className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-800">
                    <BsCheck
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                    <span className="text-sm font-medium font-semibold text-gray-700">
                      {step.name}
                    </span>
                    <span className="text-sm font-medium text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </span>
              </div>
            ) : step.status === "current" ? (
              <div className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-800">
                  <span className="text-indigo-800">{step.id}</span>
                </span>
                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span className="text-sm font-medium text-indigo-800">
                    {step.name}
                  </span>
                  <span className="text-sm font-medium text-gray-500">
                    {step.description}
                  </span>
                </span>
              </div>
            ) : (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                    <span className="text-sm font-medium font-semibold text-gray-700">
                      {step.name}
                    </span>
                    <span className="text-sm font-medium text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
