import React, { useEffect, useState, useCallback } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import DetailUser from "./DetailUser";
import userService from "services/user.service";
import { toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loading from "components/Loading";
import { BsTrash } from "react-icons/bs";
import AlertModal from "components/modal/AlertModal";
import { AiOutlineCloseCircle } from "react-icons/ai";

function DetailEmployee() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Usuario",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Detalle",
    },
  ];

  const [initial, setInitial] = useState("");

  const [user, setUser] = useState({});
  const [report, setReport] = useState([]);
  const [transaction, setTransaction] = useState([]);

  // Aux Variables
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  let { userId } = useParams();

  const disableUserModal = {
    title: "Eliminar Usuario",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    subTitle: "¿Está seguro de querer eliminar el usuario?",
    confirmation: "Eliminar",
    showCancelBtn: true,
    bgColor: "bg-red-100",
  };

  const getUserDetailPage = useCallback(() => {
    setLoading(true);
    userService
      .getUserById(userId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { user, transactions, reports, bankInfo } = res.data;
        user.bankInfo = { ...bankInfo };
        setUser(user);
        setReport(...report, reports);
        setTransaction(...transaction, transactions);
        setInitial(getInitial(user.firstName, user.lastName));
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`${location.state.from}`);
        setLoading(false);
      });
  }, []);

  const handleOpen = () => {
    setOpenDelete(true);
  };

  useEffect(() => {
    getUserDetailPage();
  }, [getUserDetailPage]);

  const getInitial = (firstName, lastName) => {
    const splitName = `${firstName.toUpperCase()[0]}${
      lastName.toUpperCase()[0]
    }`;
    return splitName;
  };

  const deleteUser = () => {
    setLoading(true);
    setOpenDelete(false);
    userService
      .deleteUser(userId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setLoading(false);
        navigate(`${location.state.from}`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        navigate(`${location.state.from}`);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      <AlertModal
        open={openDelete}
        setOpen={setOpenDelete}
        info={disableUserModal}
        deleteFunction={() => {
          deleteUser();
        }}
      />
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4 flex flex-col md:flex-row md:justify-between">
        <div className="flex align-items-as">
          <div className="res-circle mr-4">
            <div className="circle-txt font-bold">{initial}</div>
          </div>
          <h1 className="text-2xl font-bold">
            {user?.firstName} {user?.middleName}
          </h1>
        </div>
        <div className="mt-5  md:mt-0">
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 
            px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
            focus-visible:outline-red-400"
            onClick={() => handleOpen()}
          >
            <BsTrash className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Eliminar
          </button>
        </div>
      </div>
      <DetailUser
        dataUser={user}
        dataReport={report}
        dataTransaction={transaction}
        getUserDetailPage={getUserDetailPage}
      />
    </div>
  );
}

export default DetailEmployee;
