import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { GrNext } from "react-icons/gr";

export default function RefreshBreadcrumbs({ elements }) {
  const breadcrumbs = [];

  elements.forEach((element) => {
    if (element.link) {
      breadcrumbs.push(
        <Link
          underline={element.underline}
          key={element.key}
          href={element.href}
        >
          <p className="md:text-xl color-mid">{element.name}</p>
        </Link>
      );
    } else {
      breadcrumbs.push(
        <span key={element.key}>
          <p className="md:text-xl color-mid">{element.name}</p>
        </span>
      );
    }
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<GrNext className="text-white" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
