import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { bankInformation, displayAccountTypeInfo } from "const/account";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";
import userService from "services/user.service";
import Modal from "@mui/material/Modal";
import { MdOutlineClose } from "react-icons/md";
import { styleModal } from "const/modalStyle";
import Box from "@mui/material/Box";

function FormAddBank({ setOpenFormBank, openFormBank, userId }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Aux Variables
  const [loading, setLoading] = useState(false);
  const accountNumber = watch("accountNumber", "");

  const onSubmit = (data) => {
    setLoading(true);
    userService
      .addBankAccount(data, userId)
      .then((res) => {
        if (res?.status !== 201) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setLoading(false);
        setOpenFormBank(false);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
        setOpenFormBank(false);
        window.location.reload();
      });
  };

  function handlePaste(event) {
    event.preventDefault();
  }

  const handleCloseFormCloseBank = () => setOpenFormBank(false);

  return (
    <div>
      <Modal
        open={openFormBank}
        onClose={handleCloseFormCloseBank}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} className="absolute rounded-md w-4/5 md:w-1/3">
          <div className="flex flex-col p-3 bg-gray-100 rounded-t-md">
            <div className="flex justify-between">
              <p className="text-base">Agrega tu cuenta bancaria</p>
              <MdOutlineClose
                className="text-base"
                onClick={handleCloseFormCloseBank}
              />
            </div>
          </div>
          <div className="w-full p-4 bg-white rounded-b-md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4 flex justify-between border-b-2 border-curuba-ko">
                <h1>Información bancaria</h1>
              </div>
              <WarningAlertTailwind
                body={
                  "Por favor verifica la cuenta bancaria que ingresas. Si te equivocas no podemos hacer nada desde AndaPay"
                }
              />
              <div className="flex flex-col mt-3">
                <div className="mb-4">
                  <label className="text-sm">Banco</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("bank", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione un banco
                    </option>
                    {Object.entries(bankInformation).map(([key, value]) => {
                      return <option value={key}>{value}</option>;
                    })}
                  </select>
                  {errors?.bank?.type === "required" && (
                    <p className="text-red-400 text-sm">
                      Este campo es obligatorio
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="text-sm">Tipo de Cuenta</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("accountType", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione un tipo de cuenta
                    </option>
                    {Object.keys(displayAccountTypeInfo).map((key, index) => {
                      return (
                        <option value={key}>
                          {displayAccountTypeInfo[key]}
                        </option>
                      );
                    })}
                  </select>
                  {errors?.accountType?.type === "required" && (
                    <p className="text-red-400 text-sm">
                      Este campo es obligatorio
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="text-sm text-sm">Número de cuenta</label>
                  <input
                    {...register("accountNumber", {
                      required: true,
                      pattern: /^[0-9]+$/,
                    })}
                    type="text"
                    name="accountNumber"
                    id="accountNumber"
                    onPaste={handlePaste}
                    placeholder="Ingresa el número de cuenta"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.accountNumber?.type === "required" && (
                    <p className="text-red-400 text-sm">
                      Este campo es obligatorio
                    </p>
                  )}
                  {errors?.accountNumber?.type === "pattern" && (
                    <p className="text-red-400 text-sm">
                      El número de cuenta solo puede contener números
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="text-sm">Verificar Número de cuenta</label>
                  <input
                    {...register("accountNumberVerified", {
                      required: true,
                      pattern: /^[0-9]+$/,
                      validate: (value) => value === accountNumber,
                    })}
                    type="text"
                    onPaste={handlePaste}
                    name="accountNumberVerified"
                    id="accountNumberVerified"
                    placeholder="Verifica el número de cuenta"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.accountNumberVerified?.type === "required" && (
                    <p className="text-red-400 text-sm">
                      Este campo es obligatorio
                    </p>
                  )}
                  {errors?.accountNumberVerified?.type === "pattern" && (
                    <p className="text-red-400 text-sm">
                      El número de cuenta solo puede contener números
                    </p>
                  )}
                  {errors.accountNumberVerified?.type === "validate" && (
                    <p className="text-red-400 text-sm">
                      El número de cuenta no coincide con el ingresado
                    </p>
                  )}
                </div>
              </div>
              {loading && <Loading />}
              <div className="mt-4 md:mt-0">
                <button
                  className="cursor-pointer flex w-full items-center justify-center rounded-md 
         border bg-white px-4 py-2 font-medium bg-indigo-700 shadow-sm text-white"
                  type="submit"
                >
                  Añadir cuenta bancaria 🚀
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default FormAddBank;
