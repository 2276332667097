import React from "react";

function Badge({
  children,
  fontWeight = "font-bold",
  borderRadius = "rounded",
  paddingX = "px-2",
  paddingY = "py-1",
  type = "underReview",
}) {
  const colorMap = {
    positive: "bg-green-100 text-green-700",
    negative: "bg-red-200 text-red-600",
    inProgress: "bg-orange-200 text-orange-600",
    underReview: "bg-blue-200 text-blue-900",
  };

  return (
    <div
      className={`text-center w-fit ${fontWeight} ${borderRadius} ${paddingX} ${paddingY} ${colorMap[type]}`}
    >
      {children}
    </div>
  );
}

export default Badge;
