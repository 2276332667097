import React, { useEffect, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import whiteLogo from "assets/images/LogoColorBlanco.png";

import { logout } from "slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { setFocus } from "slices/sidebar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getMatchRoute(pathname, regexMenu) {
  for (let i = 0; i < regexMenu.length; i++) {
    const isMatched = regexMenu[i].regex.test(pathname);
    if (isMatched) {
      return regexMenu[i].name;
    }
  }
}

export default function Sidebar({
  setSidebarOpen,
  sidebarOpen,
  isActive,
  menus,
  theme,
  regexCollection,
  secondaryMenu,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sidebar } = useSelector((state) => state.sidebar);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    const match = getMatchRoute(location?.pathname, regexCollection);
    const storage = window.localStorage.getItem("isFocused");
    if (storage !== match) {
      window.localStorage.setItem("isFocused", match);
    }
    dispatch(setFocus(window.localStorage.getItem("isFocused")));
  }, []);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-dark-purple pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full 
                      focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <AiOutlineClose
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto relative"
                    src={whiteLogo}
                    alt="Logo Purple Small"
                  />
                </div>
                <nav
                  className="mt-5 h-full flex-shrink-0 divide-y divide-white overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="space-y-1 px-2">
                    {menus.map((item) => (
                      <Link
                        key={item.title}
                        to={`${item.route}`}
                        className={classNames(
                          sidebar === item.title
                            ? "bg-indigo-600 text-white"
                            : "text-indigo-100 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        onClick={() => {
                          dispatch(setFocus(`${item.title}`));
                          window.localStorage.setItem(
                            "isFocused",
                            `${item.title}`
                          );
                          setSidebarOpen(false);
                        }}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.src
                          className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                          aria-hidden="true"
                        />
                        {item.title}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="space-y-1 px-2">
                      {secondaryMenu.map((item) => (
                        <Link
                          key={item.title}
                          to={`${item.route}`}
                          className={classNames(
                            sidebar === item.title
                              ? "bg-indigo-600 text-white"
                              : "text-indigo-100 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          onClick={() => {
                            dispatch(setFocus(`${item.title}`));
                            window.localStorage.setItem(
                              "isFocused",
                              `${item.title}`
                            );
                            setSidebarOpen(false);
                          }}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.src
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          {item.title}
                        </Link>
                      ))}
                      <div
                        id="logOutMob"
                        onClick={logOut}
                        className="cursor-pointer group flex items-center rounded-md px-2 py-2 text-sm font-medium 
                    leading-6 text-indigo-100  hover:text-white hover:bg-indigo-500"
                      >
                        <FiLogOut
                          className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                          aria-hidden="true"
                        />
                        Cerrar Sesión
                      </div>
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/** DESKTOP */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col h-full">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div
          className={`${
            isActive ? "hidden" : ""
          } flex flex-grow flex-col overflow-y-auto bg-dark-purple pt-5 pb-4`}
        >
          <nav
            className="mt-14 flex flex-1 flex-col divide-y divide-white overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="space-y-1 px-2">
              {menus.map((item) => (
                <Link
                  key={item.title}
                  to={`${item.route}`}
                  onClick={() => {
                    dispatch(setFocus(`${item.title}`));
                    window.localStorage.setItem("isFocused", `${item.title}`);
                  }}
                  className={classNames(
                    sidebar === item.title
                      ? "bg-indigo-600 text-white"
                      : "text-indigo-100 hover:text-white hover:bg-indigo-500",
                    "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.src
                    className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                    aria-hidden="true"
                  />
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="mt-6 pt-6">
              <div className="space-y-1 px-2">
                {secondaryMenu.map((item) => (
                  <Link
                    key={item.title}
                    to={`${item.route}`}
                    onClick={() => {
                      dispatch(setFocus(`${item.title}`));
                      window.localStorage.setItem("isFocused", `${item.title}`);
                    }}
                    className={classNames(
                      sidebar === item.title
                        ? "bg-indigo-600 text-white"
                        : "text-indigo-100 hover:text-white hover:bg-indigo-500",
                      "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.src
                      className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                      aria-hidden="true"
                    />
                    {item.title}
                  </Link>
                ))}
                <div
                  id="logOutDes"
                  onClick={logOut}
                  className="cursor-pointer group flex items-center rounded-md px-2 py-2 text-sm font-medium 
                    leading-6 text-indigo-100  hover:text-white hover:bg-indigo-500"
                >
                  <FiLogOut
                    className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                    aria-hidden="true"
                  />
                  Cerrar Sesión
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
