import axios from "axios";
import authHeader from "./auth-header";

const getAllUsers = async () => {
  try {
    const res = await axios.get("/users", { headers: authHeader() });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const deleteUser = async (userId) => {
  try {
    const res = await axios.delete(`/users/${userId}`, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const changeStatusUser = async (userId, status) => {
  try {
    const actionTaken = status ? "enable" : "disable";
    console.log(actionTaken);
    const res = await axios.get(`/users/${userId}/action/${actionTaken}`, {
      headers: authHeader(),
    });
    return res;
  } catch (err) {
    return err.response?.data?.error;
  }
};

const getAllUsersByCompany = async (companyId) => {
  try {
    const res = await axios.get(`/companies/${companyId}/users`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const createUser = async (data) => {
  try {
    const res = await axios.post("/users", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const createReportUser = async (userId, data) => {
  try {
    const res = await axios.post(`/users/${userId}/report`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const createReport = async (data) => {
  try {
    const res = await axios.post("/reports", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const getUserById = async (userId) => {
  try {
    const res = await axios.get(`/users/${userId}`, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const editUser = async (userId, updateInfo) => {
  try {
    const res = await axios.put(`/users/${userId}`, updateInfo, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response?.data?.error;
  }
};

const getHomePage = async () => {
  try {
    const res = await axios.get("user/home", {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const needSignTerms = async () => {
  try {
    const res = await axios.get("/userTerms", {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const acceptTermsAndConditions = async (data) => {
  try {
    const res = await axios.post("/userTerms", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const addBankAccount = async (data, userId) => {
  try {
    const res = await axios.post(`/users/${userId}/addBankInfo`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const deleteUsers = async (data) => {
  try {
    const res = await axios.post(
      `/users/deleteBatch`,
      { users: data },
      {
        headers: authHeader(),
      }
    );
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const userService = {
  getAllUsers,
  getAllUsersByCompany,
  createUser,
  getUserById,
  deleteUser,
  deleteUsers,
  getHomePage,
  needSignTerms,
  acceptTermsAndConditions,
  createReport,
  createReportUser,
  editUser,
  changeStatusUser,
  addBankAccount,
};

export default userService;
