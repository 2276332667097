import { createSlice } from "@reduxjs/toolkit";
import formatFunction from "const/formatFunction";

const initialState = {
  contentUser: {},
  contentBank: {},
  contentCompany: {},
  contentActivity: [],
  maxAdvance: 0,
  advanceAmount: "",
  fee: "",
  totalAmount: 0,
  isDisabled: false,
  percentage: 100,
};

function calculateFee(amount, commission, maxAdvance) {
  if (amount < maxAdvance) {
    return commission;
  }

  const base = parseFloat(amount - maxAdvance);
  const percentageBase = parseFloat(base * 0.01);
  const baseFee = parseFloat(commission + percentageBase);

  return baseFee;
}

function totalAmount(amount, fee) {
  if (amount === 0) {
    return 0;
  }
  return amount + fee + fee * 0.19;
}

const advanceUserSlice = createSlice({
  name: "advanceUser",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const { user, bank, company, activity, maxAdvanceToday, isDisabled } =
        action.payload;
      state.contentUser = user;
      state.contentBank = bank;
      state.contentCompany = company;
      state.contentActivity = activity;
      state.maxAdvance = maxAdvanceToday;
      state.isDisabled = isDisabled;
    },
    setAdvanceInitial: (state, action) => {
      const { maxAdvanceToday } = action.payload;
      const planCompany = state.contentCompany.plan === "planB";

      const valAdvance = maxAdvanceToday;
      const display = formatFunction.formatInput("MONEY", valAdvance);
      state.advanceAmount = display;

      const feeMax = planCompany
        ? 0
        : calculateFee(
            valAdvance,
            state.contentCompany.commission,
            state.contentCompany.customMaxAdvance
          );
      const displayFeeMax = formatFunction.formatInput("MONEY", feeMax);

      state.fee = planCompany ? "$ 0" : `${displayFeeMax} + IVA`;
      state.totalAmount = totalAmount(valAdvance, feeMax);
    },
    onChangeAmount: (state, action) => {
      const planCompany = state.contentCompany.plan === "planB";

      const valAdvance = action.payload;
      const display = formatFunction.formatInput("MONEY", valAdvance);
      state.advanceAmount = display;
      const feeMax = planCompany
        ? 0
        : calculateFee(
            valAdvance,
            state.contentCompany.commission,
            state.contentCompany.customMaxAdvance
          );
      const displayFeeMax = formatFunction.formatInput("MONEY", feeMax);
      state.fee = planCompany ? "$ 0" : `${displayFeeMax} + IVA`;
      state.totalAmount = totalAmount(valAdvance, feeMax);
    },
    onPercentageChange: (state, action) => {
      state.percentage = action.payload;
    },
  },
});

const { reducer, actions } = advanceUserSlice;

export const {
  setUserInfo,
  setAdvanceInitial,
  onChangeAmount,
  onPercentageChange,
} = actions;
export default reducer;
