import React, { useState } from "react";
import TabParamsDetail from "./TabParamsDetail";
import TabParamsEdit from "./TabParamsEdit";

function TabParams({ dataParams, getCompanyData, isCompanyAdmin }) {
  const [edit, setEdit] = useState(false);
  return (
    <div className="w-full">
      {edit ? (
        <TabParamsEdit
          isEdit={edit}
          setisEdit={setEdit}
          data={dataParams}
          getCompanyData={getCompanyData}
          isCompanyAdmin={isCompanyAdmin}
        />
      ) : (
        <TabParamsDetail
          isEdit={edit}
          setisEdit={setEdit}
          data={dataParams}
          isCompanyAdmin={false}
        />
      )}
    </div>
  );
}

export default TabParams;
