import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import Missing from "./pages/Missing";
import DashboardAdmin from "./pages/dashboardAdmin/DashboardAdmin";
import DashboardCompany from "./pages/dashboardCompany/DashboardCompany";
import PoliticaDeDatos from "./pages/politicas/PoliticasDeDatos";
import Dashboard from "./pages/dashboard/Dashboard";
import axios from "axios";
import RequireAuth from "./components/RequireAuth";
import { roles } from "./const/roles";
import DashboardContent from "pages/dashboard/DashboardContent";
import Landing from "pages/landing/Landing";
import { ImportGuide } from "pages/importGuide";
import { Faq } from "pages/faq";

axios.defaults.baseURL = process.env.REACT_APP_MAIN_BACK_CALL;

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/" element={<Landing />} />
      <Route path="/login/admin" element={<Login role="admin" />} />
      <Route path="/recuperacionclave" element={<Login role="reset" />} />
      <Route path="/login/empresa" element={<Login role="company" />} />
      <Route path="/habilitar" element={<Login role="enable" />} />
      <Route path="/login" element={<Login role="user" />} />
      <Route path="/login/cpk" element={<DashboardContent />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/import_guide" element={<ImportGuide />} />
      <Route path="/politicas" element={<PoliticaDeDatos />} />
      {/* ADMIN */}
      <Route element={<RequireAuth allowedRoles={roles.Admin} />}>
        <Route
          path="/dashboard/admin/usuarios"
          element={<DashboardAdmin action="users" />}
        />
        <Route
          path="/dashboard/admin"
          element={<DashboardAdmin action="home" />}
        />
        <Route
          path="/dashboard/admin/empresas"
          element={<DashboardAdmin action="company" />}
        />
        <Route
          path="/dashboard/admin/empresas/:companyId"
          element={<DashboardAdmin action="detailCompany" />}
        />
        <Route
          path="/dashboard/admin/empresas/:companyId/admin"
          element={<DashboardAdmin action="createAdminCompany" />}
        />
        <Route
          path="/dashboard/admin/empresas/:companyId/editar"
          element={<DashboardAdmin action="editDetailCompany" />}
        />
        <Route
          path="/dashboard/admin/empresas/crear"
          element={<DashboardAdmin action="createCompanies" />}
        />
        <Route
          path="/dashboard/admin/usuarios/crear"
          element={<DashboardAdmin action="createUsers" />}
        />
        <Route
          path="/dashboard/admin/usuarios/:userId"
          element={<DashboardAdmin action="detailUser" />}
        />
        <Route
          path="/dashboard/admin/usuarios/:userId/editar"
          element={<DashboardAdmin action="editDetailUser" />}
        />
        <Route
          path="/dashboard/admin/adelantos"
          element={<DashboardAdmin action="advancesGeneral" />}
        />
        <Route
          path="/dashboard/admin/reportes"
          element={<DashboardAdmin action="advanceReport" />}
        />
        <Route
          path="/dashboard/admin/adelantos/:advanceId"
          element={<DashboardAdmin action="detailAdvance" />}
        />
      </Route>
      {/* EMPRESA */}
      <Route element={<RequireAuth allowedRoles={roles.AdminCompany} />}>
        <Route path="/dashboard" element={<DashboardCompany action="home" />} />
        <Route
          path="/dashboard/:companyId/usuarios"
          element={<DashboardCompany action="users" />}
        />
        <Route
          path="/dashboard/:companyId/adelantos"
          element={<DashboardCompany action="advancesGeneral" />}
        />
        <Route
          path="/dashboard/:companyId/usuarios/crear"
          element={<DashboardCompany action="createUsers" />}
        />
        <Route
          path="/dashboard/:companyId/usuarios/:userId/editar"
          element={<DashboardCompany action="editUser" />}
        />
        <Route
          path="/dashboard/:companyId/usuarios/:userId"
          element={<DashboardCompany action="detailUser" />}
        />
        <Route
          path="/dashboard/perfil"
          element={<DashboardCompany action="profile" />}
        />
        <Route
          path="/dashboard/:companyId/adelantos/:advanceId"
          element={<DashboardCompany action="detailAdvance" />}
        />
        <Route
          path="/dashboard/:companyId"
          element={<DashboardCompany action="settings" />}
        />
        <Route
          path="/dashboard/import"
          element={<DashboardCompany action="import" />}
        />
        <Route
          path="/dashboard/ayuda"
          element={<DashboardCompany action="help" />}
        />
        <Route
          path="/dashboard/:companyId/admin"
          element={<DashboardCompany action="createAdminCompany" />}
        />
      </Route>
      {/* USUARIO */}
      <Route element={<RequireAuth allowedRoles={roles.User} />}>
        <Route path="/adelantos" element={<Dashboard action="home" />} />
        <Route path="/historial" element={<Dashboard action="advances" />} />
        <Route
          path="/superadelantos"
          element={<Dashboard action="superadvances" />}
        />
        <Route
          path="/historial/:advanceId"
          element={<Dashboard action="detailAdvance" />}
        />
        <Route path="/ayuda" element={<Dashboard action="help" />} />
      </Route>
      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
