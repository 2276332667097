import plusSVG from "assets/plusSVG.svg";
import deleteSVG from "assets/deleteSVG.svg";

export const stepSilder = [
  {
    title: "Tipo de documento",
    description: "Seleccione el tipo de documento que desea subir",
  },
  {
    title: "Subir un documento",
    description:
      "Antes de subir un documento asegúrate que cuente con los estandáres del archivo de importación deseado *",
  },
];

export const boxes = [
  {
    key: "addEmployees",
    title: "Agregar/Editar empleados",
    description: "Añade nuevos empleados a la plataforma.",
    imageRender: (
      <div>
        <img className="relative w-auto h-24" src={plusSVG} alt="Plus icon" />
      </div>
    ),
  },
  {
    key: "deleteEmployees",
    title: "Eliminar empleados",
    description: "Elimina empleados de la plataforma.",
    imageRender: (
      <div>
        <img className="relative w-auto h-24" src={deleteSVG} alt="Plus icon" />
      </div>
    ),
  },
];
