import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GrNext } from "react-icons/gr";

export default function CustomizedBreadcrumbs({ elements }) {
  const breadcrumbs = [];
  const navigate = useNavigate();
  const oneBack = () => {
    navigate(-1);
  };

  elements.forEach((element) => {
    if (element.link) {
      breadcrumbs.push(
        <span
          key={element.key}
          onClick={oneBack}
          className="cursor-pointer underline"
        >
          <p className="text-base color-mid">{element.name}</p>
        </span>
      );
    } else {
      breadcrumbs.push(
        <span key={element.key}>
          <p className="text-base color-mid">{element.name}</p>
        </span>
      );
    }
  });

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<GrNext className="text-white" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
