import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimplePieChart from "components/chart/SimplePieChart";
import SimpleBarChart from "components/chart/SimpleBarChart";
import SimpleAreaChart from "components/chart/SimpleAreaChart";
import BoxColor from "common/Boxcolor";
import companyService from "services/company.service";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import WarningAlert from "components/alerts/warningAlert";

function ContentHome() {
  const navigate = useNavigate();

  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [contentTransaction, setContentTransaction] = useState({});
  const [showCharts, setShowCharts] = useState(0);

  const { company: curentCompany } = useSelector((state) => state.company);

  const processInformation = (info) => {
    const daysInMonth = dayjs().daysInMonth();

    const data = [];
    for (let i = 1; i <= daysInMonth; i++) {
      data.push({
        name: i.toString(),
        "Número de Adelantos": 0,
        "Valor Adelanto": 0,
      });
    }
    const duplicates = [];
    info.forEach((element) => {
      const dateDay = parseInt(element.advanceDate.split("-")[2]);
      data[dateDay - 1]["Número de Adelantos"] += 1;
      data[dateDay - 1]["Valor Adelanto"] += element.amount;

      if (duplicates.indexOf(element.user) < 0) {
        duplicates.push(element.user);
      }
    });

    return {
      duplicates: duplicates.length,
      dataInfo: data,
    };
  };

  const getHomePage = useCallback(() => {
    setLoading(true);
    companyService
      .getHomePage()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        setLoading(false);
        const { data } = res;
        const { transaction, ...rest } = data;
        setShowCharts(transaction.length > 0 ? 1 : 2);
        setContent(rest);
        const info = processInformation(transaction);
        setContentTransaction(info);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  const users = () => {
    navigate(`/dashboard/${curentCompany.company}/usuarios`);
  };

  const advances = () => {
    navigate(`${curentCompany.company}/adelantos`);
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <h1 className="text-xl">Inicio</h1>
      </div>
      <div className="grid md:grid-cols-4 gap-4 mt-4">
        <BoxColor
          title={"Usuarios"}
          number={content?.users}
          borderColor={"border-1-as"}
          action={users}
          showLink={true}
        ></BoxColor>
        <BoxColor
          title={"Adelantos Completados"}
          number={content.advancesCompleted}
          borderColor={"border-2-as"}
          action={advances}
          showLink={false}
        ></BoxColor>
        <BoxColor
          title={"Adelantos Pendientes"}
          number={content.advancesPending}
          borderColor={"border-3-as"}
          action={advances}
          showLink={false}
        ></BoxColor>
        <BoxColor
          title={"Adelantos Cancelados"}
          number={content.advancesCancel}
          borderColor={"border-4-as"}
          action={advances}
          showLink={false}
        ></BoxColor>
      </div>
      <div className="my-4">
        <h1 className="text-xl">Dashboard</h1>
      </div>
      <RenderContentBody
        option={showCharts}
        contentTransaction={contentTransaction}
        content={content}
      />
    </div>
  );
}

function RenderContentBody({ option, contentTransaction, content }) {
  const noInfoMonth = {
    body: "No hemos recibido adelantos este mes por parte de tus trabajadores.",
    title: "No se han solicitado adelantos este mes",
  };

  if (option === 0) {
    return <div></div>;
  }

  if (option === 1) {
    return (
      <div className="bg-white">
        <div className="p-6 md:p-10 grid  md:gap-4 md:grid-cols-3">
          <div className="col-span-1 w-11/12 md:w-full">
            <div className="mb-4">
              <p className="text-sm text-gray-600">Monto vs Dias del mes</p>
            </div>
            <SimpleAreaChart dataMonthly={contentTransaction} />
          </div>
          <div className="col-span-1 w-11/12 md:w-full">
            <div className="mb-4 mt-8 md:mt-0">
              <p className="text-sm text-gray-600">
                Empleados que han adelantado en el mes
              </p>
            </div>
            <SimplePieChart
              dataMonthly={contentTransaction}
              users={content?.users}
            />
          </div>
          <div className="col-span-1 w-11/12 md:w-full">
            <div className="mb-4 mt-8 md:mt-0">
              <p className="text-sm text-gray-600">
                Número de adelantos vs Dias del mes
              </p>
            </div>
            <SimpleBarChart dataMonthly={contentTransaction} />
          </div>
        </div>
      </div>
    );
  }

  if (option === 2) {
    return <WarningAlert body={noInfoMonth.body} title={noInfoMonth.title} />;
  }
}

export default ContentHome;
