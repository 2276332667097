import axios from "axios";
import authHeader from "./auth-header";

const demoForm = async (data) => {
  try {
    const res = await axios.post(`/sendDemoForm`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const contactDashboardForm = async (data) => {
  try {
    const res = await axios.post(`/sendContactDashboardForm`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const suscribeEmail = async (data) => {
  try {
    const res = await axios.post("/suscribeToEmail", data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const contactForm = async (data) => {
  try {
    const res = await axios.post(`/sendContactForm`, data, {
      headers: authHeader(),
    });
    return res;
  } catch (error) {
    return error.response?.data?.error;
  }
};

const commonService = {
  contactForm,
  demoForm,
  contactDashboardForm,
  suscribeEmail,
};

export default commonService;
