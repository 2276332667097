import React, { useMemo, useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

import { useParams } from "react-router-dom";
import advancesService from "services/advances.service";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";

import { advanceStatus } from "const/advanceStatus";
import Loading from "components/Loading";
import FormatNumber from "components/FormatNumber";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

function ContentAdvance() {
  const theme = useTheme();

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const { companyId } = useParams();

  const getHomePage = useCallback(() => {
    setLoading(true);
    advancesService
      .getAllAdvancesByCompany(companyId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setContent(data.transactions);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  console.log(content);

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2 mb-4 flex justify-between border-b-2 border-pink-600">
        <h1 className="text-xl">Adelantos</h1>
      </div>
      <div>
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableEmployee
            data={content}
            getHomePage={getHomePage}
            setLoading={setLoading}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableEmployee = ({ data, setLoading, getHomePage }) => {
  const navigate = useNavigate();

  const detailTransaction = (data) => {
    navigate(`${data.id}`, { state: data });
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "userName",
        header: "Nombre Usuario",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorFn: (row) =>
          new Date(dayjs.tz(row.advanceDate, "America/Panama")), //convert to Date for sorting and filtering
        header: "Fecha", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "advanceAmount",
        header: "Valor de adelanto",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorFn: (row) => row.status, //convert to Date for sorting and filtering
        header: "Estado", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Pendiente", value: "PENDING" },
          { text: "En Progreso", value: "ACTIVE" },
          { text: "Cancelado", value: "DENIED" },
          { text: "Completado", value: "COMPLETED" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => advanceStatus[cell.getValue()], //render Date as a string
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowActions
        enableColumnFilterModes
        positionActionsColumn="last"
        initialState={{ showGlobalFilter: true }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "0.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "0.8rem",
          },
        }}
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "nowrap",
              gap: "0.5rem",
            }}
          >
            {!row.original.deleteUser ? (
              <FaRegEye
                onClick={() => detailTransaction(row.original)}
                className="text-xl color-mid mr-2"
              />
            ) : (
              <span>Usuario Eliminado</span>
            )}
          </div>
        )}
      />
    </>
  );
};

export default ContentAdvance;
