import React, { useState } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import { Button } from "components/button/Button";

import InputCurrency from "components/formComponent/InputCurrency";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { plan } from "const/planType";
import { paymentPlan } from "const/paymentPlan";
import Loading from "components/Loading";
import { daysToCutOff } from "const/planType";
import CurrencyInput from "react-currency-input-field";
import MaskedInput from "react-input-mask";
import InputPhone from "components/formComponent/InputPhone";
import companyService from "services/company.service";
import { toast } from "react-toastify";

function ContentCreateCompany() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Empresa",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Crear",
    },
  ];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const watchSelectedPlan = watch("plan");

  const watchSelectedPaymentPlan = watch("paymentType");

  const cancelCreation = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    setLoading(true);
    companyService
      .createCompany(data)
      .then((res) => {
        if (res.status !== 201) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        navigate("/dashboard/admin/empresas");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <h1 className="text-xl font-bold">Crear Nueva Empresa</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <div className="bg-white p-4 rounded mb-8">
            <div className=" pb-4 mb-8 border-b-2 border-pink-600 w-full">
              <h2 className="text-2xl">Información General</h2>
            </div>
            <div className="grid md:grid-cols-2 md:gap-4">
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="nit">NIT</label>
                  <Controller
                    name="nit"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: /^([0-9]{3}).([0-9]{3}).([0-9]{3})-([0-9])$/,
                    }}
                    render={({ field }) => (
                      <MaskedInput
                        mask="999.999.999-9"
                        maskChar=""
                        value={field.value}
                        onChange={field.onChange}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            placeholder="Ejemplo: XXX.XXX.XXX-Y"
                            className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                            type="text"
                          />
                        )}
                      </MaskedInput>
                    )}
                  />
                  {errors?.nit?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.nit?.type === "pattern" && (
                    <p className="text-red-400">
                      El valor ingresado no es correcto
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="name">Nombre</label>
                  <input
                    {...register("name", {
                      required: true,
                    })}
                    placeholder="Ingresa el nombre de la empresa"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.name?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="email">Correo Electrónico</label>
                  <input
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                    })}
                    placeholder="Ingrese un correo electrónico"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.email?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <p className="text-red-400">
                      Correo Electrónico no es válido
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="address">Dirección</label>
                  <input
                    {...register("address", {
                      required: true,
                    })}
                    placeholder="Dirección de la empresa"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.address?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="mb-4 mt-3 md:mt-0">
                  <label for="payrollSoftware">
                    Nombre de Software de Nómina
                  </label>
                  <input
                    {...register("payrollSoftware", {
                      required: true,
                    })}
                    placeholder="Nombre de Software de Nómina"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.payrollSoftware?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="socialReason">Razón Social</label>
                  <input
                    {...register("socialReason", {
                      required: true,
                    })}
                    placeholder="Razón Social"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.socialReason?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>

                <div className="mb-4">
                  <label for="contactName">Nombre de Contacto</label>
                  <input
                    {...register("contactName", {
                      required: true,
                    })}
                    placeholder="Ingrese un nombre de contacto"
                    className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                  />
                  {errors?.contactName?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="phone">Celular</label>
                  <InputPhone control={control} errors={errors} />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded mb-8">
            <div className=" pb-4 mb-8 border-b-2 border-pink-600 w-full">
              <h2 className="text-2xl">Información Operacional</h2>
            </div>
            <div className="grid md:grid-cols-2 md:gap-4">
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="plan">Modalidad de Plan</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("plan", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione una modalidad de plan
                    </option>
                    {plan.map((data) => (
                      <option value={data.val}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.plan?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="firstPayDay">Primera Fecha de liquidación</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("firstPayDay", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione la primera fecha de liquidación
                    </option>
                    {Object.entries(daysToCutOff).map(([key, value]) => {
                      return <option value={key}>{value}</option>;
                    })}
                  </select>
                  {errors?.firstPayDay?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                {watchSelectedPaymentPlan === "BIWEEKLY" && (
                  <div className="mb-4">
                    <label for="secondPayDay">
                      Segunda Fecha de liquidación
                    </label>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("secondPayDay", {
                        required: true,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione la segunda fecha de liquidación
                      </option>
                      {Object.entries(daysToCutOff).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {errors?.secondPayDay?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
                {watchSelectedPlan === "planB" && (
                  <div className="mb-4">
                    <label for="numberMaxOfUsers">
                      Número máximo de usuarios
                    </label>
                    <input
                      {...register("numberMaxOfUsers", {
                        required: true,
                        min: 1,
                      })}
                      type="number"
                      placeholder="Ingrese el número máximo de usuarios permitidos"
                      className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                    />
                    {errors?.numberMaxOfUsers?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                    {errors?.numberMaxOfUsers?.type === "min" && (
                      <p className="text-red-400">
                        Debes contar con al menos un empleado
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="paymentType">Modalidad de Pago</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("paymentType", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione una modalidad de pago
                    </option>
                    {paymentPlan.map((data) => (
                      <option value={data.val}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.plan?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="firstCutOffDate">Primera fecha de corte</label>
                  <select
                    className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                    {...register("firstCutOffDate", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione el primer día de corte
                    </option>
                    {Object.entries(daysToCutOff).map(([key, value]) => {
                      return <option value={key}>{value}</option>;
                    })}
                  </select>
                  {errors?.firstCutOffDate?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                {watchSelectedPaymentPlan === "BIWEEKLY" && (
                  <div className="mb-4">
                    <label for="secondCutOffDate">Segunda fecha de corte</label>
                    <select
                      className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                      {...register("secondCutOffDate", {
                        required: true,
                      })}
                    >
                      <option value="" selected disabled>
                        Por favor seleccione el segundo día de corte
                      </option>
                      {Object.entries(daysToCutOff).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {errors?.cutOffDate?.type === "required" && (
                      <p className="text-red-400">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded mb-8">
            <div className=" pb-4 mb-8 border-b-2 border-pink-600 w-full">
              <h2 className="text-2xl">Campos Personalizados </h2>
            </div>
            <div className="grid md:grid-cols-2 md:gap-4">
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="commission">Comisión</label>
                  <InputCurrency
                    control={control}
                    name={"commission"}
                    placeHolder={"Comisión de la empresa"}
                  />
                  {errors?.commission?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
                <div className="mb-4">
                  <label for="customMaxAdvance">
                    Adelanto tope antes del 1%
                  </label>
                  <InputCurrency
                    control={control}
                    name={"customMaxAdvance"}
                    placeHolder={"Comisión de la empresa"}
                  />
                  {errors?.customMaxAdvance?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="mb-4">
                  <label for="percentageOfAdvance">
                    Porcentaje de adelanto
                  </label>
                  <Controller
                    name="percentageOfAdvance"
                    control={control}
                    rules={{
                      required: true,
                      min: 20,
                      max: 50,
                    }}
                    render={(props) => {
                      return (
                        <CurrencyInput
                          suffix="%"
                          onValueChange={(e) => props.field.onChange(e)}
                          placeholder="Ingrese el porcentaje máximo de adelanto"
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={false}
                          className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                          decimalsLimit={2}
                        />
                      );
                    }}
                  />
                  {errors?.percentageOfAdvance?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                  {errors?.percentageOfAdvance?.type === "min" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser mínimo del 20%
                    </p>
                  )}
                  {errors?.percentageOfAdvance?.type === "max" && (
                    <p className="text-red-400">
                      El valor del porcentaje debe ser máximo del 50%
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="mr-4">
              <Button
                buttonSize="btn--wide-button"
                onClick={cancelCreation}
                buttonColor="thrid-button"
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                buttonSize="btn--wide-button"
                buttonColor="second-button"
              >
                Crear Empresa
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContentCreateCompany;
