import React, { useMemo, useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { FaRegEye } from "react-icons/fa";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { advanceStatus } from "const/advanceStatus";
import advancesService from "services/advances.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormatNumber from "components/FormatNumber";

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

function HistoryAdvances() {
  const error_message = "No fue posible recuperar la información";

  const theme = useTheme();
  const [content, setContent] = useState([]);

  useEffect(() => {
    advancesService
      .getTransactionsByUser()
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setContent(data.transactions);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(error_message);
      });
  }, []);

  return (
    <div>
      <div className="my-2 curuba-border">
        <h1 className="text-xl font-bold">Historial de adelantos</h1>
      </div>
      <div>
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableAdvance data={content} />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableAdvance = ({ data }) => {
  const navigate = useNavigate();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const openDetailTransaction = (data) => {
    navigate(`${data.id}`, { state: data });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "advanceAmount",
        header: "Valor de adelanto",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ cell }) => <FormatNumber>{cell.getValue()}</FormatNumber>,
      },
      {
        accessorKey: "comissionFee",
        header: "Comisión",
        enableColumnFilterModes: false,
        filterVariant: "range",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "column",
            }}
          >
            <FormatNumber>{row.original.comissionFee}</FormatNumber>
            <p>{row.original.comissionFee > 0 ? "+ IVA" : ""}</p>
          </div>
        ),
      },
      {
        accessorFn: (row) =>
          new Date(dayjs.tz(row.advanceDate, "America/Panama")), //convert to Date for sorting and filtering
        header: "Fecha", //only allow these filter modes
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        enableColumnFilterModes: false,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "120px" }}
                  helperText={"Default: Menor a"}
                  variant="standard"
                />
              )}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => row.status, //convert to Date for sorting and filtering
        header: "Estado", //only allow these filter modes
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Pendiente", value: "PENDING" },
          { text: "En Progreso", value: "ACTIVE" },
          { text: "Cancelado", value: "DENIED" },
          { text: "Completado", value: "COMPLETED" },
        ],
        filterVariant: "multi-select",
        Cell: ({ cell }) => advanceStatus[cell.getValue()], //render Date as a string
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowNumbers
      rowNumberMode="original" //default
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      localization={MRT_Localization_ES}
      enableRowActions
      enableColumnFilterModes
      positionActionsColumn="last"
      initialState={{ showGlobalFilter: true }}
      renderRowActions={({ row }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "nowrap",
            gap: "0.5rem",
          }}
        >
          <FaRegEye
            onClick={() => openDetailTransaction(row.original)}
            className="text-xl color-mid"
          />
        </div>
      )}
    />
  );
};

export default HistoryAdvances;
