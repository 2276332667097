import React, { useState, useEffect, useCallback } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

import { TabPanel, a11yProps } from "components/tabPanel/TabPanelFunction";

// ROUTES
import { useNavigate, useParams } from "react-router-dom";
import companyService from "services/company.service";
import { displayPlan } from "const/planType";
import TabAdmin from "common/Company/TabAdmin";
import InputForm from "components/InputForm";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import TabParams from "common/Company/TabParams";

import { companyStatus } from "const/advanceStatus";

function DetailCompany() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Empresa",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Detalle",
    },
  ];

  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const [content, setContent] = useState([]);
  const [initial, setInitial] = useState("");
  const [adminContent, setAdminContent] = useState([]);

  let { companyId } = useParams();

  const getHomePage = useCallback(() => {
    companyService
      .getCompanyById(companyId)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { company, admins } = res.data;
        setContent(company);
        setAdminContent(admins);
        setInitial(getInitial(company.name));
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`/dashboard/admin/empresas`);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getInitial = (name) => {
    const nameSpace = name.split(" ");
    const companyName = nameSpace[0];
    const secondWordIfExist = nameSpace[1];

    let splitName = `${companyName.toUpperCase()[0]}`;
    if (secondWordIfExist) {
      splitName += `${secondWordIfExist.toUpperCase()[0]}`;
    } else {
      splitName += `C`;
    }

    return splitName;
  };

  return (
    <div>
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <div className="flex align-items-as">
          <div className="res-circle mr-2">
            <div className="circle-txt font-bold">{initial}</div>
          </div>
          <h1 className="text-2xl font-bold">{content.name}</h1>
        </div>
      </div>

      <div className="mb-6">
        <div className="bg-white p-4 rounded mb-8">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Información" {...a11yProps(0)} />
                <Tab label="Administradores" {...a11yProps(1)} />
                <Tab label="Parámetros" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="w-full">
                <div className="overflow-x-scroll md:overflow-auto">
                  <InputForm val={content.name} field={"Nombre"} />
                  <InputForm val={content.nit} field={"NIT"} />
                  <InputForm
                    val={content.phone}
                    field={"Celular"}
                    type={"PHONE"}
                  />
                  <InputForm
                    val={content.socialReason}
                    field={"Razón Social"}
                  />
                  <InputForm
                    val={displayPlan[content.plan]}
                    field={"Tipo de Plan"}
                  />
                  {content.plan === "planB" && (
                    <InputForm
                      val={content?.numberMaxOfUsers}
                      field={"Número máximo de usuarios"}
                    />
                  )}
                  <InputForm val={content.email} field={"Correo Electrónico"} />
                  <InputForm val={content.address} field={"Dirección"} />
                  <InputForm
                    val={content.contactName}
                    field={"Nombre de Contacto"}
                  />
                  <InputForm
                    val={content.payrollSoftware}
                    field={"Nombre de Software de Nómina"}
                  />
                  <InputForm
                    val={companyStatus[content.status]}
                    field={"Estado"}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TabAdmin
                adminContent={adminContent}
                getCompanyData={getHomePage}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TabParams dataParams={content} getCompanyData={getHomePage} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default DetailCompany;
