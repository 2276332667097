import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const data = await AuthService.login(userData);

      if (!data?.status && !data.token) {
        throw new Error(
          "Sin conexión para acceder a la aplicación. Por favor intente más tarde"
        );
      }

      if (data.status !== 200 && !data.token) {
        throw new Error(data.message);
      }

      return { user: data };
    } catch (error) {
      const message = error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  reducers: {
    setAcceptTerms: (state, action) => {
      state.user.legkgtrm = action.payload;
    },
  },
});

const { reducer, actions } = authSlice;

export const { setAcceptTerms } = actions;
export default reducer;
