import React from "react";

import Footer from "pages/landing/FooterSocialMedia";
import LogoMorado from "assets/images/LogoColorMorado.png";

import img1 from "assets/images/dashboard_1.PNG";
import img2 from "assets/images/select_item_2.PNG";
import img3 from "assets/images/arrastra_documentos.PNG";

import { TbPoint } from "react-icons/tb";
import {
  AiOutlineUsergroupAdd,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";

function ImportGuide() {
  return (
    <div>
      <div className="p-6 flex justify-between">
        <div className="flex align-items-as">
          <div className="mr-8 hidden md:block">
            <img
              src={LogoMorado}
              alt="logo"
              className="relative w-auto md:cursor-pointer h-10"
            />
          </div>
        </div>
      </div>
      <div className="bg-white px-6 py-2 mb-10 lg:px-8">
        <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
          <p className="font-semibold leading-7 text-indigo-600">Importación</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Guía de Importación de Usuarios en AndaPay
          </h1>
          <p className="mt-6 leading-8">
            Bienvenido a la guía de importación de usuarios de AnadaPay. Este
            módulo ha sido diseñado para facilitarte la administración de
            usuarios, permitiéndote agregar, editar y eliminar usuarios de
            manera eficiente y segura.
          </p>
          <div className="mt-4 max-w-3xl">
            <p className="mt-6 leading-8">Operaciones disponibles:</p>
            <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <AiOutlineUsergroupAdd
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Agregar Usuarios.
                  </strong>
                </span>
              </li>
              <li className="flex gap-x-3">
                <AiOutlineEdit
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Editar Usuarios.
                  </strong>
                </span>
              </li>
              <li className="flex gap-x-3">
                <AiOutlineDelete
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Eliminar Usuarios.
                  </strong>
                </span>
              </li>
            </ul>
            <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
              Paso a Paso:
            </h2>
            <div className="mt-6 p-4 border-l-4 border-indigo-600 bg-indigo-50 rounded-md w-full">
              <p className="text-lg font-semibold">
                <span className="text-indigo-600">1.</span> Acceso al Módulo de
                Importación
              </p>
            </div>
            <p className="mt-2 text-gray-700">
              En el sidebar del lado izquierdo, localiza el ícono o bullet
              etiquetado como "Importación" y haz clic en él.
            </p>
            <div className="w-full flex justify-center ">
              <img className="relative w-auto h-64" src={img1} alt="sidebar" />
            </div>
            <div className="mt-6 p-4 border-l-4 border-indigo-600 bg-indigo-50 rounded-md w-full">
              <p className="text-lg font-semibold">
                <span className="text-indigo-600">2.</span> Selección de
                Operación:
              </p>
            </div>
            <p className="mt-2 text-gray-700">
              En el sidebar del lado izquierdo, localiza el ícono o bullet
              etiquetado como "Importación" y haz clic en él.
            </p>
            <div className="my-12">
              <img
                className="relative w-auto h-56 md:h-auto"
                src={img2}
                alt="import"
              />
            </div>
            Una vez seleccionada tu preferencia, haz clic en el botón
            "Siguiente".
            <div className="mt-6 p-4 border-l-4 border-indigo-600 bg-indigo-50 rounded-md w-full">
              <p className="text-lg font-semibold">
                <span className="text-indigo-600">3.</span> Preparación para la
                Importación:
              </p>
            </div>
            <p className="mt-2 text-gray-700">
              Se te mostrarán dos secciones principales:
            </p>
            <ul className="list-disc pl-5 mt-4 space-y-2">
              <li>
                <strong>- Archivo de Ejemplo de Importación:</strong> Este
                archivo te proporciona una estructura clara de cómo deben estar
                formateados tus datos.
              </li>
              <li>
                <strong>- File Input: </strong>Una interfaz donde podrás
                arrastrar y soltar el archivo .csv que deseas importar desde tu
                ordenador.
              </li>
            </ul>
            <figure className="ml-4 mt-10 border-l border-indigo-600 pl-4">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  Nota: La aplicación solo admite archivos con extensión .csv.
                  Asegúrate de que tu archivo cumpla con este formato.
                  Adicionalmente, la importación está limitada a 60 entradas por
                  operación. Si tu archivo tiene más de 60 filas, deberás
                  dividirlo en múltiples archivos o lotes.
                </p>
              </blockquote>
            </figure>
            <div className="my-12">
              <img
                className="relative w-auto h-56 md:h-auto"
                src={img3}
                alt="documents"
              />
            </div>
            <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
              Detalles del Archivo de Ejemplo:
            </h2>
            <p className="mt-2">
              Al descargar el Archivo de Ejemplo, encontrarás cuatro pestañas o
              hojas distintas en él:
            </p>
            <ul className="list-disc pl-5 mt-4 space-y-2">
              <li>
                <strong>- Glosario:</strong> Esta sección te brinda una visión
                detallada de cada columna necesaria para la importación. Aquí
                encontrarás:
                <ul className="list-disc pl-5 mt-4 space-y-2">
                  <li className="flex underline">
                    <TbPoint
                      className="mt-1 h-5 w-5 flex-none"
                      aria-hidden="true"
                    />
                    Nombre de la columna.
                  </li>
                  <li className="flex underline">
                    <TbPoint
                      className="mt-1 h-5 w-5 flex-none"
                      aria-hidden="true"
                    />
                    Si es un campo obligatorio o no.
                  </li>
                  <li className="flex underline">
                    <TbPoint
                      className="mt-1 h-5 w-5 flex-none"
                      aria-hidden="true"
                    />
                    Descripción del campo.
                  </li>
                  <li className="flex underline">
                    <TbPoint
                      className="mt-1 h-5 w-5 flex-none"
                      aria-hidden="true"
                    />
                    Ejemplo de cómo debe ser completado.
                  </li>
                </ul>
              </li>
              <li>
                <strong>- Ejemplo: </strong>Una hoja completa mostrando un
                ejemplo de cómo deben ser presentados y organizados los datos
                para asegurar una importación exitosa.
              </li>
              <li>
                <strong>- Columnas: </strong>Considera esta hoja como tu
                plantilla principal. Puedes usarla para ingresar o copiar los
                datos de tu empresa que deseas importar.
              </li>
              <li>
                <strong>- Bancos: </strong>Una lista de todos los bancos que son
                aceptados por AnadaPay. Asegúrate de referirte a esta lista si
                es necesario incluir información bancaria.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImportGuide;
