import React from "react";
import { Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";

function InputCurrency({ control, name, placeHolder, fullSalary = 0 }) {
  const ruleValidate = {
    required: true,
  };

  if (name === "netSalary") {
    ruleValidate.validate = (value) => value <= parseFloat(fullSalary.current);
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={(props) => {
          return (
            <CurrencyInput
              prefix="$"
              onValueChange={(e) => props.field.onChange(e)}
              placeholder={placeHolder}
              groupSeparator=","
              decimalSeparator="."
              allowDecimals={false}
              className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
              decimalsLimit={2}
            />
          );
        }}
        rules={ruleValidate}
      />
    </>
  );
}

export default InputCurrency;
