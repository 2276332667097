import React from "react";
import { Box } from "@mui/material";

function FormatNumber({ children }) {
  const formattedValue1 = children.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
  });
  return <Box>{formattedValue1}</Box>;
}

export default FormatNumber;
