import React, { useState, useEffect } from "react";
import logo from "assets/images/LogoColorBlanco.png";

import { useNavigate } from "react-router-dom";
import { setAcceptTerms } from "slices/auth";
import { useDispatch } from "react-redux";

import { ipLink } from "config/links";

import Loading from "components/Loading";
import userService from "services/user.service";

function TermsAndConditions({ user }) {
  const dispatch = useDispatch();

  const [isCheckbox, setisCheckbox] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetch(ipLink)
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error(error));
  }, []);

  const acceptedTerms = () => {
    setLoading(true);
    const dataSend = {
      userId: user,
      ipUser: ip,
    };
    userService
      .acceptTermsAndConditions(dataSend)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const user = JSON.parse(localStorage.getItem("user"));
        user.legkgtrm = true;
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setAcceptTerms(true));
        setLoading(false);
        navigate("/adelantos");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
        navigate("/login");
      });
  };

  return (
    <div className="w-screen">
      {loading && <Loading />}
      <div className="grid md:grid-cols-3 h-screen">
        <div className="col-span-1 gradient-as h-full flex justify-center align-items-as p-12">
          <img className="h-auto relative w-8/12 " src={logo} alt="logo" />
        </div>
        <div className="col-span-1 md:col-span-2 bg-gray-50 flex justify-center h-full align-items-as">
          <div className="p-8 w-screen md:w-4/6 mb-24 md:mb-0 ">
            <div id="message-login" className="mb-8">
              <p className="font-extrabold text-2xl text-center lg:text-3xl">
                Aceptar Términos y Condiciones
              </p>
            </div>
            <div className="mt-8 pr-0 md:pr-2 md:mt-2 w-full h-60-as overflow-y-scroll">
              <div className="text-center py-6">
                <h1>TÉRMINOS Y CONDICIONES</h1>
                <h1>ANDAPAY S.A.S.</h1>
              </div>
              <h3 className="my-1 font-semibold">1. ALCANCE</h3>
              <div className="text-justify">
                <p className="mb-2">
                  ANDAPAY S.A.S. (en adelante, “AndaPay”), sociedad identificada
                  con NIT. 901646843-3; a través de su Página Web (tal y como
                  será definido más adelante) brinda la plataforma tecnológica
                  para efectuar Solicitudes de Adelanto o de Super-Adelanto (tal
                  y como serán definidos más adelante), las cuales son
                  canceladas a través de la modalidad descuento directo
                  autorizado por los Usuarios (tal y cómo se definirá más
                  adelante).
                </p>
                <p className="mb-2">
                  AndaPay solicita a los visitantes y Usuarios (tal y cómo se
                  definirá más adelante) de la Página Web (tal y cómo se
                  definirá más adelante) la lectura de estos términos y
                  condiciones (los “T&C”) antes de iniciar su navegación o
                  utilizar los servicios ofrecidos a través de esta Página Web
                  (tal y cómo se definirá más adelante). El acceso y navegación
                  en la Página Web, así como el registro en la plataforma
                  tecnológica de AndaPay, se encuentra precedido de un “click”
                  en el botón acepto, lo cual constituye el conocimiento y la
                  aceptación expresa del Usuario (tal y cómo se definirá más
                  adelante) de estos T&C. En caso de no estar de acuerdo con
                  estos T&C sugerimos al visitante y al Usuario (tal y cómo se
                  definirá más adelante) que se abstengan de acceder o navegar
                  por la Página Web (tal y cómo se definirá más adelante).
                </p>
                <p className="mb-2">
                  Los presentes T&C regulan: (i) el uso que el Usuario (tal y
                  cómo se definirá más adelante) haga de la Página Web (tal y
                  cómo se definirá más adelante) de propiedad de AndaPay; y (ii)
                  el servicio de la Solicitud de Adelanto o de Super Adelantos
                  que se prestará en virtud de las solicitudes que los Empleados
                  y/o Contratistas (tal y como se definirán más adelante) del
                  Empleador (tal y como se definirá más adelante) y/o
                  Contratante (tal y como se definirá a continuación) realice en
                  calidad de Usuario (tal y cómo se definirá más adelante) a
                  través de la Página Web (tal y cómo se definirá más adelante);
                  y (iii) el uso que el Empleador y/o Contratista (tal y como se
                  definirán a continuación) realice de la Página Web (tal y cómo
                  se definirá más adelante), para todos los Usuarios (tal y cómo
                  se definirá más adelante) en Colombia.
                </p>
              </div>
              <h3 className="my-1 font-semibold">2. DEFINICIONES</h3>
              <div className="text-justify">
                <p>
                  Los términos que a continuación se mencionan, se interpretan
                  de acuerdo al siguiente alcance:
                </p>
                <ul className="ml-4 ">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold"> “AndaPay”:</span> tiene
                      el significado indicado en el Alcance de los presentes
                      Términos y Condiciones.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        “Autorización de Descuento de Nómina”:{" "}
                      </span>
                      es el acuerdo celebrado entre el Trabajador y la Empresa,
                      el cual autoriza que la Empresa descuente el valor
                      contemplado en la Solicitud de Adelanto de la nómina u
                      honorarios del mes en el cual se requirió la Solicitud de
                      Adelanto o de Super-Adelanto.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        “Autorización de Datos Personales”:{" "}
                      </span>
                      es la autorización de datos personales a través de la cual
                      el Usuario autoriza a AndaPay el tratamiento de sus datos
                      personales de acuerdo con la Ley 1581 de 2012.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">“Contratista”: </span>
                      es una persona natural que suscribió un contrato de
                      prestación de servicios con la Empresa.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        “Contrato de Colaboración”:{" "}
                      </span>
                      es una persona natural que suscribió un contrato de
                      prestación de servicios con la Empresa.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        “Contrato de Mutuo”:{" "}
                      </span>
                      es el acuerdo privado celebrado entre AndaPay y el
                      Trabajador y/o Contratista.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">“Empresa”: </span>es la
                      persona jurídica que suscribió un Contrato de Colaboración
                      con AndaPay, por regla general, el empleador o
                      contratante, según el caso, de los Usuarios
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Fecha de Desembolso":
                      </span>{" "}
                      es la fecha en la cual AndaPay desembolsa el dinero
                      solicitado por parte del Empleado y/o Contratista.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Información de la Nómina":
                      </span>{" "}
                      es toda la información necesaria y relativa al personal
                      vinculado a través de contrato laboral o de prestación de
                      servicios, indicando a lo sumo por cada Trabajador y/o
                      Contratista reportado: (i) la fecha de inicio del contrato
                      laboral o de prestación de servicios; (ii) la fecha de
                      culminación del contrato, en caso de que el Trabajador
                      esté vinculado bajo un contrato laboral a término fijo o
                      sometido a plazo y/o condición; (iii) valor de la nómina;
                      (iv) número y tipo de cuenta bancaria asociada con el
                      Trabajador y/o Contratista, y (v) entre otros datos que
                      AndaPay requiera para el cumplimiento del objeto del
                      presente Contrato.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Información Inicial":
                      </span>{" "}
                      es la información requerida por AndaPay a la Empresa y/o
                      Contratante.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"KYC":</span> es el
                      proceso de Know Your Client, a través del cual se realizan
                      las investigaciones y debida diligencia para conocer al
                      cliente, en este caso, a la Empresa.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Solicitud de Adelanto" o "Solicitud de Adelanto
                        Tradicional":
                      </span>{" "}
                      es la manifestación expresa y formal a través de la Página
                      Web o App, por parte del Trabajador o Contratista, de un
                      desembolso anticipado de máximo el 50% de la nómina
                      trabajada al día de la solicitud. La suma debida incluye:
                      (i) el valor del adelanto (el “Adelanto”); (ii) Comisión
                      de Tecnología y Administración (la “Comisión”); (iii)
                      intereses; e (iv) IVA de la Comisión.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Solicitud de Super-Adelanto":
                      </span>{" "}
                      es la manifestación expresa y formal a través de la Página
                      Web o App, por parte del Trabajador o Contratista, de un
                      desembolso anticipado de hasta quinientos mil (500.000)
                      pesos colombianos. La suma debida incluye: (i) el valor
                      del adelanto (el “Super-Adelanto”); (ii) Comisión de
                      Tecnología y Administración (la “Comisión”) ; (iii)
                      intereses; e (iv) IVA sobre el valor de la Comisión.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Página Web" o las "Plataformas":
                      </span>
                      <ul>
                        <li>
                          a. El sitio web www.andapay.com.co y sus subdominios;
                        </li>
                        <li>
                          b. Cualquier otro sitio web, interfaz, aplicación u
                          otros medios en los que AndaPay pone a disposición sus
                          recursos, incluidas nuestras aplicaciones móviles,
                          tabletas u otros dispositivos electrónicos;
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">
                        "Política de Tratamiento de Datos Personales":
                      </span>{" "}
                      es la política de tratamiento de datos personales y de
                      cookies de AndaPay.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"Trabajador":</span> es
                      una persona natural que suscribió un contrato de trabajo
                      con la Empresa.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"Usuario":</span> es el
                      Contratista y/o Empleado, vinculado a una Empresa, que es
                      usuario de la plataforma de AndaPay.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"T&C":</span> son estos
                      términos y condiciones, para todos los Usuarios en
                      Colombia.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"Titular":</span> persona
                      natural cuyos datos personales sean objeto de tratamiento.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">"Visitante":</span> tiene
                      el significado indicado en el numeral 4° de los Términos y
                      Condiciones.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">3. USO DE LA PLATAFORMA</h3>
              <div className="text-justify">
                <p className="mb-2">
                  Estos T&C constituyen un acuerdo vinculante entre los Usuarios
                  y las Empresas y AndaPay. A su vez rige el acceso y uso de la
                  Página Web por parte de los Usuarios y las Empresas.{" "}
                </p>
                <p className="mb-2">
                  Al aceptar estos T&C, los Usuarios y las Empresas declaran que
                  conocen y están de acuerdo con su contenido y con las demás
                  políticas de AndaPay aplicables, incluida la Política de
                  Tratamiento de Datos Personales.
                </p>
                <p className="mb-2">
                  Estos T&C y sus actualizaciones también prevalecen sobre todas
                  las propuestas, entendimientos o acuerdos anteriores, verbales
                  o escritos, que puedan existir entre los Usuarios, las
                  Empresas y AndaPay.
                </p>
                <p className="mb-2">
                  El respeto de las condiciones de estos T&C es fundamental para
                  el uso legítimo de los servicios prestados por AndaPay.
                </p>
                <p className="mb-2">
                  Los Usuarios o Empresas que hagan uso de los servicios de
                  AndaPay de manera incompatible con estos T&C, serán objeto de
                  diferentes medidas por parte de AndaPay, quien podrá
                  aplicarlas en cualquier momento, con o sin previo aviso. Estas
                  medidas pueden incluir, sin perjuicio de otras:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      La suspensión del acceso a una Solicitud de Adelanto o
                      Super-Adelanto
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      La suspensión del acceso a su cuenta,
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      La cancelación de su registro de la Página Web, entre
                      otras.
                    </p>
                  </li>
                </ul>
                <p className="mb-2">
                  Al utilizar los servicios de AndaPay con relación a cualquier
                  producto, los Usuarios y las Empresas contratantes son
                  responsables de identificar, comprender y cumplir todas las
                  leyes, reglas y regulaciones aplicables; esto puede incluir,
                  entre otras, reglas de carácter civil, reglas sobre propiedad
                  intelectual, privacidad, y uso de datos personales.
                </p>
                <p className="mb-2">
                  Si AndaPay no ejerce de inmediato algún derecho que sea
                  aplicable según la ley o estos T&C, por cualquier motivo, o
                  incluso no ejerce ningún derecho en un caso determinado, no
                  significa que AndaPay renuncie al ejercicio de esos derechos.
                </p>
                <p className="mb-2">
                  AndaPay podrá ejercer sus derechos en cualquier momento, a su
                  discreción, y si AndaPay renuncia al ejercicio de cualquiera
                  de sus derechos individualmente, esto no significa que estará
                  renunciando al ejercicio de sus derechos en su conjunto.
                </p>
              </div>
              <h3 className="my-1 font-semibold">4. REGISTRO</h3>
              <div className="text-justify">
                <p className="mb-2">
                  Las Empresas se comprometen a brindar información fidedigna y
                  completa, así como a actualizarla cuando ello sea pertinente
                  y/o se encuentre obligado a actualizarla de acuerdo con el
                  Contrato de Colaboración.
                </p>
                <p className="mb-2">
                  Dentro de la información solicitada para el registro se
                  encuentran los datos personales del Usuario, los cuales serán
                  tratados conforme a la Autorización de Datos Personales
                  otorgada por su parte y a la Política de Tratamiento de Datos
                  Personales de AndaPay. Si la Empresa no efectúa el registro de
                  cada Usuario , podrá navegar por las diferentes secciones de
                  la Página Web y explorar su contenido, sin poder acceder a los
                  servicios ofrecidos (el “Visitante”).
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">Usuario: </span>
                      Las Empresas registrarán a cada uno de los Empleados y/o
                      Contratistas en la Página Web de AndaPay para el
                      otorgamiento de un usuario que constituye su única
                      identificación en el sistema.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">Empresa: </span>
                      AndaPay registrará a cada Empresa que suscriba un Contrato
                      de Colaboración con AndaPay en la Página Web de AndaPay
                      para el otorgamiento de un usuario que constituye su única
                      identificación en el sistema.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      <span className="font-semibold">Contraseña: </span>A su
                      vez, dentro del proceso de registro, las Empresas podrán
                      elegir una clave o contraseña de acceso a la cuenta.
                    </p>
                  </li>
                </ul>
                <p className="mb-2">
                  En el evento de no poder acceder a la cuenta por olvido de su
                  contraseña, el Usuario podrá ir a la sección de “Iniciar
                  Sesión” y seleccionar la opción de “Olvidé mi contraseña”,
                  siguiendo el procedimiento establecido por AndaPay para la
                  asignación y/o restablecimiento de la nueva contraseña.
                </p>
                <p className="mb-2">
                  En cumplimiento de los T&C, la Política de Tratamiento de
                  Datos Personales y cualquier otra política establecida por
                  AndaPay, se establecen las siguientes directrices que regirán
                  la conducta del Usuario y la Empresa en la Plataforma ofrecida
                  por AndaPay:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Utilizar el contenido y servicios de la Página Web
                      diligentemente y para fines lícitos.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Tomar medidas de seguridad adecuadas para prevenir el
                      acceso no autorizado a la cuenta del Usuario en la Página
                      Web.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Abstenerse de usar la Página Web para realizar actividades
                      ilegales.
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      No enviar correos electrónicos no solicitados a otros
                      Usuarios de la Página Web, ni transmitir virus u otro tipo
                      de código dañino por medio de la misma.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">5. INGRESO Y USO</h3>
              <div className="text-justify">
                <p className="mb-2">
                  Las Empresas tienen la obligación de informar a AndaPay de
                  inmediato cualquier actividad sospechosa o uso no autorizado
                  de su información de identificación, incluyendo su nombre de
                  usuario y/o contraseña, correo electrónico o número de
                  identificación. El acceso a la Plataforma se realizará a
                  través del botón de inicio de sesión habilitado para este fin.
                </p>

                <p className="mb-2">
                  Los Usuarios podrán solicitar adelantos y otros servicios
                  proporcionados por AndaPay, así como consultar las condiciones
                  específicas de cada servicio a través de su cuenta.
                </p>

                <p className="mb-2">
                  La Empresa tendrá la capacidad de monitorear las Solicitudes
                  de Adelanto o Super-Adelanto hechas por sus Empleados y/o
                  Contratistas, y actualizar su información en caso de ser
                  requerido por el Contrato de Colaboración celebrado con
                  AndaPay.
                </p>

                <p className="mb-2">
                  Las Empresas podrán acceder a su perfil y actualizar su
                  información en cualquier momento para garantizar la correcta
                  prestación de los servicios ofrecidos por AndaPay.
                </p>
              </div>
              <h3 className="my-1 font-semibold">6. OBLIGACIONES DE ANDAPAY</h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay se obliga a cumplir las siguientes obligaciones
                  respecto de los Usuarios:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Recibida la Solicitud de Adelanto o Super-Adelanto,
                      AndaPay realizará el estudio interno correspondiente para
                      la aprobación de la Solicitud de Adelanto o
                      Super-Adelanto;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      La presente solicitud únicamente podrá realizarse si el
                      Usuario tiene un contrato laboral o de prestación de
                      servicios vigente al momento de la Solicitud de Adelanto o
                      Super-Adelanto suscrito con la Empresa;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      AndaPay realizará el desembolso del monto aprobado de la
                      Solicitud de Adelanto o Super-Adelanto, por cuenta de la
                      Empresa, a la cuenta bancaria asociada a la nómina y/u
                      honorarios de la Empresa a nombre del Usuario;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Prestar los demás servicios ofrecidos por la Página Web de
                      forma oportuna y con la mayor calidad.
                    </p>
                  </li>
                </ul>
                <p className="mb-2">
                  AndaPay se obliga a cumplir las siguientes obligaciones
                  respecto de las Empresas:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Aportar todos sus conocimientos y experiencia para el
                      adecuado cumplimiento de la Solicitud de Adelanto o
                      Super-Adelanto con la máxima diligencia posible;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Aportar sus capacidades técnicas, administrativas y
                      financieras y colaborar recíprocamente para el normal e
                      idóneo desarrollo y ejecución de las Solicitudes de
                      Adelanto o Super-Adelanto;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Cumplir con las disposiciones contenidas en la Ley 581 de
                      2012 y demás disposiciones que la complementen o
                      modifiquen respecto del tratamiento de los datos
                      personales proporcionados por la Empresa sobre sus
                      Empleados y/o Contratistas;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Realizar el proceso estandarizado de KYC de acuerdo con la
                      Información Inicial suministrada por la Empresa;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Realizar el registro de la Empresa en la Página Web de
                      acuerdo con la Información Inicial suministrada por la
                      Empresa;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Realizar la cancelación del registro de la Empresa en la
                      Página Web, una vez terminada la relación contractual con
                      AndaPay;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Poner a disposición de la Empresa y Trabajadores y/o
                      Contratistas, su Página Web para resolver las Solicitudes
                      de Adelanto o Super-Adelanto;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Dar respuesta a la Solicitud de Adelanto o Super-Adelanto,
                      en un término no mayor a veinticuatro (24) horas contadas
                      a partir de la recepción de la Solicitud de Adelanto o
                      Super-Adelanto en mención;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Contar con la liquidez necesaria para desembolsar el monto
                      solicitado a la cuenta bancaria a nombre del Trabajador
                      y/o Contratista; desembolso sujeto a los límites descritos
                      en estos T&C;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Las demás obligaciones que se desprendan de los Contratos
                      de Colaboración celebrados, y de los Contratos de Mutuo
                      celebrados en virtud de las Solicitudes de Adelanto o
                      Super-Adelanto efectuadas por los Usuarios.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">
                7. OBLIGACIONES DE LA EMPRESA
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  Las Empresas se obligan a cumplir las siguientes obligaciones
                  respecto de AndaPay:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Proporcionar de forma completa y oportuna a AndaPay la
                      información que esta solicite;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Realizar el registro de todos los Trabajadores y/o
                      Contratistas de la Empresa en la Página Web incluyendo: el
                      nombre, documento de identificación, entidad financiera,
                      tipo de cuenta, número de cuenta y salario u honorarios;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Verificar que la información suministrada a AndaPay por
                      parte de los Usuarios sea veraz y correcta;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Notificar a AndaPay, por medio de los canales de
                      comunicación presentados por AndaPay la terminación de los
                      contratos laborales y/o de prestación de servicios de
                      alguno de los Usuarios, así como de las licencias,
                      incapacidades, suspensiones, inasistencias, vacaciones no
                      remuneradas, y entre otros factores que determinen o
                      condicionen el salario devengado o los honorarios causados
                      a la fecha de la Solicitud de Adelanto o Super-Adelanto de
                      alguno de los Usuarios;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Cumplir a cabalidad las demás obligaciones que se
                      requieran para el adecuado y cabal cumplimiento de la
                      Solicitud de Adelanto o Super-Adelanto, detalladas en los
                      Contratos de Colaboración Empresarial, sus anexos,
                      enmiendas y/o cualquier otro acuerdo privado suscrito
                      entre la Empresa y AndaPay.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">
                8. OBLIGACIONES DE LOS USUARIOS
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  Los Usuarios se obligan a cumplir las siguientes obligaciones
                  respecto de AndaPay:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Proporcionar de forma completa y oportuna a AndaPay la
                      información que esta solicite;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Realizar el registro de todos los Trabajadores y/o
                      Contratistas de la Empresa en la Página Web incluyendo: el
                      nombre, documento de identificación, entidad financiera,
                      tipo de cuenta, número de cuenta y salario u honorarios;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Verificar que la información suministrada a AndaPay por
                      parte de los Usuarios sea veraz y correcta;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Notificar a AndaPay, por medio de los canales de
                      comunicación presentados por AndaPay la terminación de los
                      contratos laborales y/o de prestación de servicios de
                      alguno de los Usuarios, así como de las licencias,
                      incapacidades, suspensiones, inasistencias, vacaciones no
                      remuneradas, y entre otros factores que determinen o
                      condicionen el salario devengado o los honorarios causados
                      a la fecha de la Solicitud de Adelanto o Super-Adelanto de
                      alguno de los Usuarios;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Cumplir a cabalidad las demás obligaciones que se
                      requieran para el adecuado y cabal cumplimiento de la
                      Solicitud de Adelanto o Super-Adelanto, detalladas en los
                      Contratos de Colaboración Empresarial, sus anexos,
                      enmiendas y/o cualquier otro acuerdo privado suscrito
                      entre la Empresa y AndaPay.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">
                9. CONDICIONES PARA LAS SOLICITUDES DE ADELANTO
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  Para realizar las Solicitudes de Adelanto o Super-Adelanto,
                  los Usuarios deberán asegurarse de cumplir con las siguientes
                  condiciones:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Que el Usuario se encuentre bajo un contrato laboral o de
                      prestación de servicios vigente con alguna de las
                      Empresas;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Que el Usuario sea mayor de edad, es decir, mayor de
                      dieciocho (18) años;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Que el Usuario esté dentro de la nómina del Empleador;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Que el Usuario tenga cuenta bancaria de nómina activa;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Que el Usuario haya aceptado estos T&C, la Política de
                      Tratamiento de Datos Personales y la política de cookies,
                      entre otras políticas de AndaPay.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">
                10. SOLICITUDES DE ADELANTO
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  Los Trabajadores y/o Contratistas podrán solicitar dos tipos
                  de solicitudes de adelanto:
                </p>
                <ul className="list-decimal list-inside ml-4 ">
                  <li className="auto-li">
                    <span className="font-semibold">
                      Solicitud de Adelanto Tradicional:
                    </span>
                    <p className="mb-2">
                      Los Trabajadores y/o Contratistas podrán realizar una
                      Solicitud de Adelanto por medio de la App y/o en la Página
                      Web hasta el monto equivalente a las percepciones
                      salariales devengadas o de los honorarios causados,
                      determinadas por la Empresa, en el mes en curso y en
                      proporción al trabajo o servicio realizado en el marco de
                      la relación laboral o contractual, según corresponda.
                    </p>
                    <p className="mb-2">
                      Las Solicitudes de Adelanto realizadas a AndaPay no podrán
                      superar el porcentaje definido por la empresa del salario
                      devengado o los honorarios causados para el mes en curso,
                      establecido por cada Empresa, so pena de la negativa de la
                      Solicitud de Adelanto por parte de AndaPay.
                    </p>
                    <p className="mb-2">
                      De modo que, AndaPay sólo prestará su servicio con base en
                      la Información de la Nómina, Información Inicial y demás
                      información proporcionada por la Empresa o por el
                      Trabajador y/o Contratistas a AndaPay.
                    </p>
                    <p className="mb-2">
                      Sin perjuicio de lo anterior, el porcentaje máximo a
                      adelantar se encuentra en el rango entre el{" "}
                      <span className="font-semibold">30% al 60%</span> del
                      salario trabajado del Trabajador o los honorarios causados
                      del Contratista y, en todo caso, el monto máximo a
                      desembolsar será{" "}
                      <span className="font-semibold">
                        CINCO MILLONES DE PESOS (COP$5.000.000).
                      </span>
                    </p>
                  </li>
                  <li className="auto-li">
                    <span className="font-semibold">
                      Solicitud de Super-Adelanto:
                    </span>
                    <p>
                      Los Trabajadores y/o Contratistas podrán realizar una
                      Solicitud de Super-Adelanto por medio de la App y/o en la
                      Página Web, hasta quinientos mil (500.000) pesos
                      colombianos, observando las siguientes condiciones:
                    </p>
                    <ul className="list-none list-inside ml-4">
                      <li className="auto-li">
                        a. Para los trabajadores que su salario sea de hasta{" "}
                        <span className="font-semibold">
                          DOS MILLONES DE PESOS (COP$2.000.000),
                        </span>{" "}
                        deberán acogerse a los montos máximos de Solicitud de
                        Súper Adelantos, a saber:
                        <ul className="list-none list-inside ml-4">
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              i. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              tres (3) meses, podrán efectuar la Solicitud de
                              Super-Adelanto, por una sola vez, durante el mes,
                              y hasta un monto máximo de{" "}
                              <span className="font-semibold">
                                CIEN MIL PESOS (COP $100.000).
                              </span>
                            </p>
                          </li>
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              ii. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              cuatro (4) meses, podrán efectuar la Solicitud
                              Super-Adelanto, hasta un monto máximo de{" "}
                              <span className="font-semibold">
                                CIENTO CINCUENTA MIL PESOS (COP $150.000).
                              </span>
                            </p>
                            <p className="mt-2 mb-4">
                              En caso de que de efectuarse una (1) o más
                              Solicitudes de Super-Adelanto durante el mes,la
                              Solicitud subsecuente podrá efectuarse hasta un
                              monto máximo de{" "}
                              <span className="font-semibold">
                                DOSCIENTOS MIL PESOS (COP $200.000).
                              </span>
                            </p>
                          </li>
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              iii. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              seis (6) meses, podrán efectuar la Solicitud
                              Super-Adelanto, hasta un monto máximo{" "}
                              <span className="font-semibold">
                                TRESCIENTOS MIL PESOS (COP $300.000).{" "}
                              </span>
                            </p>
                            <p className="mt-2 mb-4">
                              En caso de que de efectuarse una (1) o más
                              Solicitudes de Super-Adelanto durante el mes,la
                              Solicitud subsecuente podrá efectuarse hasta un
                              monto máximo de{" "}
                              <span className="font-semibold">
                                TRESCIENTOS CINCUENTA MIL PESOS (COP $350.000).
                              </span>
                            </p>
                          </li>
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              iv. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              superior a los seis (6) meses, podrán efectuar la
                              Solicitud Super-Adelanto, hasta un monto máximo de
                              <span className="font-semibold">
                                {" "}
                                TRESCIENTOS CINCUENTA MIL PESOS (COP $350.000).
                              </span>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="auto-li">
                        b. Para los trabajadores que su salario sea superior a
                        los{" "}
                        <span className="font-semibold">
                          DOS MILLONES DE PESOS (COP$2.000.000)
                        </span>
                        , deberán acogerse a los montos máximos de Solicitud de
                        Súper-Adelantos, a saber:
                        <ul className="list-none list-inside ml-4">
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              i. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              tres (3) meses, podrán efectuar la Solicitud de
                              Super-Adelanto, por una sola vez, durante el mes,
                              y hasta un monto máximo de
                              <span className="font-semibold">
                                DOSCIENTOS MIL PESOS (COP $200.000).
                              </span>
                            </p>
                          </li>
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              ii. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              cuatro (4) meses, podrán efectuar la Solicitud
                              Super-Adelanto, hasta un monto máximo de
                              <span className="font-semibold">
                                DOSCIENTOS CINCUENTA MIL PESOS (COP $250.000).
                              </span>
                            </p>
                            <p className="mt-2 mb-4">
                              En caso de que de efectuarse una (1) o más
                              Solicitudes de Super-Adelanto durante el mes,la
                              Solicitud subsecuente podrá efectuarse hasta un
                              monto máximo de{" "}
                              <span className="font-semibold">
                                TRESCIENTOS CINCUENTA MIL PESOS (COP $350.000).
                              </span>
                            </p>
                          </li>
                          <li className="auto-li">
                            <p className="mt-2 mb-4">
                              iii. Para los trabajadores cuya antigüedad en la
                              Empresa (desde la fecha en que se firmó el
                              contrato laboral o de prestación de servicios) sea
                              cinco (5) meses, o más, podrán efectuar la
                              Solicitud Super-Adelanto, hasta un monto máximo de
                              <span className="font-semibold">
                                QUINIENTOS MIL PESOS (COP $500.000).
                              </span>
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p className="my-4">
                  <span className="font-semibold">PARÁGRAFO: </span>
                  El monto correspondiente a la Solicitud de Super-Adelanto
                  podrá bloquear al monto habilitado para las Solicitudes de
                  Adelanto tradicionales, siempre y cuando el monto del Super
                  Adelanto sea superior al habilitado para las Solicitudes de
                  Adelanto Tradicionales.
                </p>
              </div>
              <h3 className="my-1 font-semibold">
                11. MODIFICACIONES A LOS T&C
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay se reserva el derecho de modificar los presentes T&C
                  en cualquier momento. En caso de una modificación, AndaPay
                  publicará los T&C revisados en la Página Web y notificará a
                  los Usuarios y Empresas contratantes de las modificaciones
                  realizadas antes de la fecha de su entrada en vigencia. La
                  versión actualizada contendrá la fecha de la última
                  actualización indicada al principio del texto.
                </p>
                <p className="mb-2">
                  Si un Usuario o Empresa contratante no está de acuerdo con la
                  nueva versión de los T&C, tendrán la opción de rescindir los
                  contratos celebrados con AndaPay de acuerdo con el Contrato de
                  Colaboración. Si no rescinden el contrato antes de la fecha de
                  vigencia de la nueva versión de los T&C, se considerará que
                  aceptan los cambios a los T&C al continuar accediendo o
                  utilizando la Página Web.
                </p>
              </div>
              <h3 className="my-1 font-semibold">
                12. PROMOCIONES Y BENEFICIOS
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay puede ofrecer a los Usuarios descuentos, beneficios
                  exclusivos de programas específicos dentro de la plataforma,
                  créditos, cashbacks y otros incentivos con el objetivo de
                  fomentar el uso de la plataforma por parte de los Usuarios.
                </p>
                <p className="mb-2">
                  Se debe tener en cuenta que la redención de estos incentivos
                  es opcional y que los Usuarios que decidan utilizarlos estarán
                  sujetos a los términos y condiciones específicos de cada
                  incentivo, que estarán disponibles en la Página Web de
                  AndaPay.
                </p>
              </div>
              <h3 className="my-1 font-semibold">
                13. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDADES
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay no se responsabilizará de ningún daño o perjuicio de
                  ningún tipo que pudiera surgir en los siguientes casos:
                </p>
                <ul className="ml-4 list-disc">
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      La Página Web no esté disponible, no se mantenga o no
                      funcione continuamente debido a causas ajenas a AndaPay;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Cuando los servicios ofrecidos no estén disponibles,
                      AndaPay informará con antelación sobre cualquier
                      interrupción, limitación o intermitencia en el
                      funcionamiento de las plataformas, siempre que sea
                      posible;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      El uso negligente, ilegal, fraudulento o contrario a los
                      términos y condiciones establecidos por uno o varios
                      usuarios. El incumplimiento por parte de terceros de sus
                      obligaciones o compromisos relacionados con los servicios
                      prestados a través de la Página Web;
                    </p>
                  </li>
                  <li className="auto-li">
                    <p className="mt-2 mb-4">
                      Cualquier disputa que surja entre las Empresas y sus
                      Empleados o Contratistas, quienes sean Usuarios de la
                      Plataforma.
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="my-1 font-semibold">
                14. PROPIEDAD INDUSTRIAL Y DERECHOS DE AUTOR
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  Todas las marcas, enseñas, logos, nombres, material
                  informático, gráfico, publicitario, fotográfico, de
                  multimedia, audiovisual y/o de diseño, así como todos los
                  contenidos, textos, bases de datos y cualesquiera otros signos
                  distintivos, puestos a su disposición en este sitio, así como
                  los modelos de utilidad y/o diseños industriales y demás
                  elementos de propiedad industrial o intelectual insertados,
                  usados y/o desplegados en este sitio son propiedad exclusiva
                  de AndaPay.
                </p>
              </div>
              <h3 className="my-1 font-semibold">
                15. USO DE LA INFORMACIÓN Y POLÍTICA DE PRIVACIDAD
              </h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay está altamente preocupado por la privacidad de sus
                  usuarios y se compromete a mantenerla protegida. Toda la
                  información proporcionada por los Usuarios y las Empresas
                  contratantes a través de la Página Web será utilizada
                  exclusivamente para atender las solicitudes de adelanto de los
                  Usuarios y para la prestación de los servicios ofrecidos por
                  AndaPay, de acuerdo con los T&C de AndaPay.
                </p>
                <p className="mb-2">
                  Las políticas de AndaPay son una parte esencial de estos T&C y
                  se incluyen por referencia, aunque se presentan en textos
                  separados.
                </p>
                <p className="mb-2">
                  En caso de alguna discrepancia, las reglas establecidas en
                  estos T&C prevalecerán sobre cualquier otra política de
                  AndaPay, a menos que existan disposiciones específicas que
                  indiquen lo contrario.
                </p>
                <p className="mb-2">
                  En cualquier caso, antes de llevar a cabo cualquier
                  tratamiento de los datos personales de los usuarios, AndaPay
                  solicitará la autorización del titular de dichos datos
                  personales para que este pueda conocer las finalidades del
                  tratamiento de sus datos y sus derechos como titular de los
                  mismos, en cumplimiento con la Ley 1581 de 2012, sus decretos
                  reglamentarios y demás normas que lo complementen o lo
                  modifiquen.
                </p>
              </div>
              <h3 className="my-1 font-semibold">16. SEGURIDAD</h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay se compromete a hacer todo lo posible (Good Will) para
                  mantener la seguridad y la privacidad de su Página Web. Sin
                  embargo, AndaPay no puede garantizar de ninguna manera la
                  ausencia de códigos maliciosos u otros elementos dañinos en
                  los contenidos de su Página Web que puedan aparecer a pesar de
                  que se hayan cumplido todos los deberes y precauciones
                  necesarios. En tal caso, AndaPay no será responsable de
                  cualquier daño o alteración causada en el sistema informático
                  del usuario (software y hardware) o en los documentos
                  electrónicos almacenados en el mismo.
                </p>
              </div>
              <h3 className="my-1 font-semibold">17. COOKIES</h3>
              <div className="text-justify">
                <p className="mb-2">
                  AndaPay cuenta con la autorización de los Usuarios para
                  utilizar cookies persistentes que les permitan reconocer la
                  información previamente proporcionada y así facilitar el uso
                  de los servicios sin la necesidad de volver a iniciar sesión
                  en la cuenta del Usuario.
                </p>
                <p className="mb-2">
                  Además, AndaPay puede emplear cookies de sesión para hacer un
                  seguimiento de los movimientos del Usuario en la Página Web y
                  evitar solicitar información que ya ha sido suministrada
                  anteriormente.
                </p>
                <p className="mb-2">
                  Es importante mencionar que el uso de cookies requiere la
                  autorización expresa del Usuario, quien debe hacer clic en el
                  botón "acepto" que se encuentra en la Página Web para indicar
                  su conocimiento y aceptación de esta funcionalidad.
                </p>
              </div>
              <h3 className="my-1 font-semibold">18. AYUDA AL USUARIO</h3>
              <div className="text-justify">
                <p className="mb-2">
                  El Usuario podrá solicitar asistencia o elevar peticiones,
                  quejas o reclamos a través del siguiente correo electrónico:
                  soporte@andapay.com.co
                </p>
              </div>
              <h3 className="my-1 font-semibold">19. LEY APLICABLE</h3>
              <div className="text-justify">
                <p className="mb-2">
                  Para todos los efectos, este acuerdo se regirá en todos sus
                  aspectos por las leyes de la República de Colombia.
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex">
                <div className="mr-4">
                  <input
                    type="checkbox"
                    onClick={() => setisCheckbox(!isCheckbox)}
                    id="scales"
                    name="scales"
                  />
                </div>
                <div>
                  <label for="scales">
                    Acepto términos y condiciones y política de privacidad
                  </label>
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={acceptedTerms}
                  disabled={isCheckbox}
                  className={`py-2 px-4 w-full md:w-1/2 ${
                    isCheckbox ? "bg-red-300" : "bg-red-400 "
                  } text-white 
                rounded-lg shadow-lg hover:bg-red-300 focus:outline-none`}
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
