import React, { useMemo, useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Button } from "components/button/Button";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import AlertModal from "components/modal/AlertModal";
import adminService from "services/admin.service";
import { toast } from "react-toastify";
import companyService from "services/company.service";
import { companyStatus } from "const/advanceStatus";
import Loading from "components/Loading";
import Chip from "@mui/material/Chip";

function ContentCompany() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHomePage = useCallback(() => {
    setLoading(true);
    adminService
      .getAllCompanies()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        setContent(data.companies);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  const createCompany = () => {
    navigate("crear");
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="pb-2 items-center flex justify-between border-b-2 border-pink-600">
        <h1 className="text-xl">Empresas</h1>
        <div className="md:mt-2 mb-2 md:hidden block">
          <Button
            buttonSize="btn--wide-button"
            buttonColor="second-button"
            className="button-animation-as-header"
            onClick={createCompany}
          >
            Crear Empresa
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <ThemeProvider theme={createTheme(theme, esES)}>
          <TableEmployee
            funOnClick={createCompany}
            data={content}
            getHomePage={getHomePage}
            setLoading={setLoading}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

const TableEmployee = ({ funOnClick, data, setLoading, getHomePage }) => {
  const [openDisable, setOpenDisable] = useState(false);
  const [openEnable, setOpenEnable] = useState(false);

  const [currentCompany, setCurrentCompany] = useState("");

  const navigate = useNavigate();

  const detailCompany = (companyId) => {
    navigate(companyId);
  };

  const editDetailCompany = (company) => {
    navigate(`${company.id}/editar`, { state: company });
  };

  const handleOpenDisable = (companyId) => {
    setOpenDisable(true);
    setCurrentCompany(companyId);
  };

  const handleOpenEnable = (companyId) => {
    setOpenEnable(true);
    setCurrentCompany(companyId);
  };

  const changeStatusCompany = (status) => {
    setLoading(true);
    setOpenDisable(false);
    setOpenEnable(false);
    companyService
      .changeStatusCompany(currentCompany, status)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        getHomePage();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Nombre",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "email",
        header: "Correo Electrónico",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "contactName",
        header: "Nombre de Contacto",
        filterFn: "fuzzy",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "status",
        header: "Estado",
        enableColumnFilterModes: false,
        filterSelectOptions: [
          { text: "Desactiva", value: "INACTIVE" },
          { text: "Activa", value: "ACTIVE" },
        ],
        Cell: ({ cell }) => companyStatus[cell.getValue()], //render Date as a string
        filterVariant: "multi-select",
      },
    ],
    []
  );

  const disableCompanyInfo = {
    title: "Desactivar Empresa",
    logo: <AiOutlineCloseCircle className="text-6xl text-red-300" />,
    subTitle: "¿Está seguro de querer desactivar la empresa?",
    body: "Esta acción desactivará a todos los usuarios pertenecientes a la empresa",
    confirmation: "Desactivar",
    showCancelBtn: true,
    bgColor: "bg-red-100",
  };

  const enableCompanyInfo = {
    title: "Activar Empresa",
    subTitle: "¿Está seguro de querer activar la empresa?",
    logo: <AiOutlineCheckCircle className="text-5xl text-green-400" />,
    body: "Esta acción activará a todos los usuarios pertenecientes a la empresa",
    confirmation: "Activar",
    showCancelBtn: true,
    bgColor: "bg-green-100",
  };

  return (
    <>
      <AlertModal
        open={openDisable}
        setOpen={setOpenDisable}
        info={disableCompanyInfo}
        deleteFunction={() => {
          changeStatusCompany(true);
        }}
      />
      <AlertModal
        open={openEnable}
        setOpen={setOpenEnable}
        info={enableCompanyInfo}
        deleteFunction={() => {
          changeStatusCompany(false);
        }}
      />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        rowNumberMode="original" //default
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        localization={MRT_Localization_ES}
        enableRowActions
        enableColumnFilterModes
        positionActionsColumn="last"
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "0.8rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "0.8rem",
          },
        }}
        initialState={{ showGlobalFilter: true }}
        renderRowActions={({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              gap: "0.5rem",
            }}
          >
            <FaRegEye
              onClick={() => detailCompany(row.original?.id)}
              className="text-xl color-mid mr-2"
            />
            <FaRegEdit
              onClick={() => editDetailCompany(row?.original)}
              className="text-xl color-mid mr-2"
            />
            {row.original.status === "ACTIVE" ? (
              <div className="mr-2">
                <Chip
                  label="Desactivar"
                  onClick={() => handleOpenDisable(row.original?.id)}
                  color="error"
                  variant="outlined"
                />
              </div>
            ) : (
              <div className="mr-2">
                <Chip
                  label="Activar"
                  onClick={() => handleOpenEnable(row.original?.id)}
                  color="success"
                  variant="outlined"
                />
              </div>
            )}
          </div>
        )}
        renderTopToolbarCustomActions={() => (
          <div className="hidden md:block mb-2">
            <Button
              buttonSize="btn--wide-button"
              onClick={funOnClick}
              buttonColor="second-button"
            >
              Crear Empresa
            </Button>
          </div>
        )}
      />
    </>
  );
};

export default ContentCompany;
