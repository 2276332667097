import React from "react";
import { FaRegEdit } from "react-icons/fa";
import formatFunction from "const/formatFunction";

function ContentProfileDetail({ isEdit, setisEdit, data }) {
  return (
    <div>
      <div className="bg-white w-auto p-4 rounded mb-8">
        <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full flex justify-between flex-col md:flex-row">
          <h2 className="text-lg">Información Personal</h2>
          <div
            className="flex justify-between items-center text-black trans-transform cursor-pointer"
            onClick={() => setisEdit(!isEdit)}
          >
            <span className="mr-4">Editar</span>
            <FaRegEdit className="text-green-600 text-xl" />
          </div>
        </div>
        <div className="w-full">
          <div className="overflow-x-scroll md:overflow-auto">
            <InputFormInfo val={data?.firstName} field={"Primer Nombre"} />
            {data?.middleName && (
              <InputFormInfo val={data?.middleName} field={"Segundo Nombre"} />
            )}
            <InputFormInfo val={data?.lastName} field={"Apellidos"} />
            <InputFormInfo val={data?.phone} field={"Celular"} type={"PHONE"} />
            <InputFormInfo val={data?.email} field={"Coreo Electrónico"} />
            <InputFormInfo
              val={data?.documentType}
              field={"Tipo de documento"}
            />
            <InputFormInfo val={data?.document} field={"Número de documento"} />
          </div>
        </div>
      </div>
    </div>
  );
}

function InputFormInfo({ val, field, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;
  return (
    <div className="border-b-2 border-gray-200 md:w-auto">
      <div className="py-4">
        <div className="grid md:grid-cols-3">
          <div className="col-span-1">
            <p className="font-semibold flex-1">{field}:</p>
          </div>
          <div className="col-span-1 md:col-span-2">
            <p className="flex-1">{format}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentProfileDetail;
