import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { Button } from "components/button/Button";
import { styled } from "@mui/material/styles";
import formatFunction from "const/formatFunction";
import { useDispatch } from "react-redux";
import { onChangeAmount, onPercentageChange } from "slices/advanceUser";
import FormAddBank from "components/Form/FormAddBank";
import WarningAlertTailwind from "components/alerts/WarningAlertTailwind";

const PrettoSlider = styled(Slider)({
  color: "#ff6d4e",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#ff6d4e",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function MainAdvance({
  dataUser,
  maxAdvance,
  valueAdvance,
  dataCompany,
  finalAmount,
  fee,
  setOpenConfirmation,
  isDisabled,
  percentageInverse,
  dataBank,
}) {
  const dispatch = useDispatch();

  const display = formatFunction.formatInput("MONEY", maxAdvance);
  const [openFormBank, setOpenFormBank] = useState(false);

  const handleOpenConfirmation = () => {
    if (!dataBank && !dataBank?.accountNumber) {
      setOpenFormBank(true);
    } else {
      setOpenConfirmation(true);
    }
  };

  const marks = [
    {
      value: 0,
      label: "$0",
    },
    {
      value: 100,
      label: display,
    },
  ];

  const handleChange = (event, newValue) => {
    const percentageForm = parseFloat((maxAdvance * newValue) / 100);
    const inverse = (percentageForm / maxAdvance) * 100;

    dispatch(onChangeAmount(percentageForm));
    dispatch(onPercentageChange(Math.round(inverse)));
  };

  const onInputChange = (e) => {
    let target = Number(e.target.value.replace(/[^0-9]+/g, ""));

    if (target > maxAdvance) {
      target = maxAdvance;
    }
    if (target < 0) {
      target = 0;
    }

    const inverse = (target / maxAdvance) * 100;
    dispatch(onPercentageChange(Math.round(inverse)));
    dispatch(onChangeAmount(target));
  };

  return (
    <div className="col-span-1 lg:col-span-2">
      <FormAddBank
        openFormBank={openFormBank}
        setOpenFormBank={setOpenFormBank}
        userId={dataUser.id}
      />
      <div>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div
            key="maxAdvance"
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-gray-500">
              Adelanto Máximo
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-800">
              <InputFormInfo val={maxAdvance} type={"MONEY"} />
            </dd>
          </div>
          <div
            key="Salary"
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-gray-500">
              Salario Neto
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-800">
              <InputFormInfo val={dataUser?.netSalary} type={"MONEY"} />
            </dd>
          </div>
          <div
            key="percentage"
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-gray-500">
              Porcentaje Permitido
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-indigo-800">
              {dataUser.custompercentageOfAdvance ||
                dataCompany?.percentageOfAdvance}
              %
            </dd>
          </div>
        </dl>
      </div>
      <div className="bg-white mt-7 rounded-md  light-shadow">
        <RenderWarningError
          isDisabled={isDisabled}
          dataBank={dataBank}
          setOpenFormBank={setOpenFormBank}
        />

        <div id="advance-info" className=" pt-4 lg:pt-8">
          <p className="text-center mb-2 md:mb-7 text-indigo-800 font-bold text-2xl lg:text-3xl">
            Adelanta tu pago 👋
          </p>
          <div className="grid md:grid-cols-2 md:gap-8">
            <div className="col-span-1 text-center md:text-right">
              <p className="font-extrabold text-xl lg:text-2xl">
                Valor de adelanto:
              </p>
            </div>
            <div
              className="col-span-1 text-center md:text-left"
              id="valueAdvance"
            >
              <input
                className="curuba-color font-extrabold text-xl lg:text-4xl text-center md:text-left bg-transparent"
                type="text"
                autoFocus
                value={valueAdvance}
                onChange={onInputChange}
                id="inputAdvance"
                name="inputAdvance"
              />
            </div>
          </div>
        </div>

        <div id="slider-info">
          <div className="flex justify-center px-4 md:px-0">
            <div className="w-8 mt-10 md:mt-12">
              {!isDisabled && (
                <PrettoSlider
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={100}
                  value={percentageInverse}
                  step={10}
                  marks={marks}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="ruj flex justify-center text-left">
            <div className="w-4/5 lg:w-2/4 grid grid-cols-2">
              <div className="col-span-1 color-v font-bold text-lg">
                Detalle
              </div>
              <div className="col-span-1 text-right color-v font-bold text-lg">
                Valor
              </div>
            </div>
          </div>
          <div className="ruj flex justify-center text-left">
            <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
              <div className="col-span-1">Salario Disponible:</div>
              <div className="col-span-1 text-right">
                <InputFormInfo
                  val={dataUser?.monthlyDynamicNetSalary}
                  type={"MONEY"}
                />
              </div>
            </div>
          </div>
          <div className="ruj flex justify-center text-left">
            <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
              <div className="col-span-1">Adelanto:</div>
              <div className="col-span-1 text-right">{valueAdvance}</div>
            </div>
          </div>
          <div className="ruj flex justify-center text-left">
            <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
              <div className="col-span-1">Comisión:</div>
              <div className="col-span-1 text-right">{fee}</div>
            </div>
          </div>
          <div className="ruj flex justify-center text-left">
            <div className="w-4/5 lg:w-2/4 grid grid-cols-2 pt-2 pb-2 text-base">
              <div className="col-span-1">Monto total a descontar:</div>
              <div className="col-span-1 text-right">
                <InputFormInfo val={finalAmount} type={"MONEY"} />
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center px-4 md:px-0 my-2 md:my-4">
              <Button
                buttonSize="btn--wide-button"
                classesCustom={`w-full lg:w-3/5 bg-indigo-700 ${
                  isDisabled || valueAdvance === "$ 0" ? "opacity-50" : ""
                }`}
                onClick={handleOpenConfirmation}
                isDisabled={isDisabled || valueAdvance === "$ 0" ? "1" : "0"}
              >
                🚀 Realizar Adelanto
              </Button>
            </div>
          </div>
          <div className="pb-4">
            {dataCompany.plan === "planB" && (
              <p className="text-sm text-center">
                * Recuerda que tienes 3 adelantos en el mes
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function InputFormInfo({ val, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;
  return format;
}

function RenderWarningError({ isDisabled, setOpenFormBank, dataBank }) {
  if (isDisabled) {
    return <WarningAlertTailwind body={isDisabled} />;
  }

  if (!dataBank && !dataBank?.accountNumber) {
    return (
      <div
        className="container cursor-pointer"
        onClick={() => setOpenFormBank(true)}
      >
        <WarningAlertTailwind
          textStyle="underline"
          body={
            "Por favor agrega una cuenta bancaria para realizar tu primer adelanto"
          }
        />
      </div>
    );
  }
}

export default MainAdvance;
