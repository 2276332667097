import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import Loading from "components/Loading";
import { useForm } from "react-hook-form";
import { login } from "slices/auth";
import { endpoints } from "const/roles";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function LoginInputAdmin() {
  // Imports
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const onSubmit = (data, event) => {
    setLoading(true);
    event.preventDefault();

    const userData = {
      email: data.email,
      password: data.password,
      role: endpoints.AdminCompany,
    };

    dispatch(login(userData))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });

    if (isLoggedIn) {
      return <Navigate to="/dashboard" />;
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label for="email">Correo Electrónico</label>
          <input
            {...register("email", {
              required: true,
              pattern: /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
            })}
            placeholder="Ingrese un correo electrónico"
            className="bg-white w-full p-3 overflow-hidden items-center bor-rad ruj-2 transition-all flex focus-within:shadow-lg"
          />
          {errors?.email?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
          {errors?.email?.type === "pattern" && (
            <p className="text-red-400">El correo electrónico no es válido</p>
          )}
        </div>
        <div className="mb-4">
          <label>Contraseña</label>
          <input
            {...register("password", {
              required: true,
            })}
            type="password"
            placeholder="Ingresa una contraseña"
            className="bg-white w-full p-3 overflow-hidden items-center bor-rad ruj-2 transition-all flex focus-within:shadow-lg"
          />
          {errors?.password?.type === "required" && (
            <p className="text-red-400">Este campo es obligatorio</p>
          )}
        </div>
        <div className="flex justify-end">
          <Link to="/recuperacionclave" className="text-red-500 underline">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        <div className="mt-4">
          <button
            disabled={loading}
            type="submit"
            className={`py-3 px-8 w-full ${
              loading ? "bg-red-300" : "bg-red-400"
            } text-white 
      rounded-lg shadow-lg hover:bg-red-300 focus:ring-4 
      focus:ring-red-100 focus:outline-none`}
          >
            Ingresar
          </button>
        </div>
      </form>
      {message && (
        <div className="my-4">
          <p className="text-lg text-red-400 font-semibold">{message}</p>
        </div>
      )}
    </div>
  );
}

export default LoginInputAdmin;
