import { BiGitCompare } from "react-icons/bi";
import { AiOutlineLineChart } from "react-icons/ai";
import { MdOutlineStackedLineChart } from "react-icons/md";
import companyBg from "../../assets/images/companyClass.webp";

const supportLinks = [
  {
    name: "Mejora tu ambiente laboral",
    href: "#",
    description:
      "La flexibilidad de cobro promueve el bienestar laboral de tus colaboradores, aumentando su compromiso y maximizando su productividad. Esto te ayudará a atraer y retener talento.",
    icon: AiOutlineLineChart,
  },
  {
    name: "Aumenta tu liquidez",
    href: "#",
    description:
      "Nuestro servicio no afecta el flujo de caja de tu empresa, nosotros ponemos el capital para realizar los adelantos.",
    icon: MdOutlineStackedLineChart,
  },
  {
    name: "Tú mantienes el control",
    href: "#",
    description:
      "Nos integramos a tu software de nómina para garantizar una fácil implementación. Trabajamos de la mano para establecer las carácteristicas de los adelantos.",
    icon: BiGitCompare,
  },
];

function Company() {
  return (
    <div className="bg-white" id="empresa">
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <img className="h-full w-full object-cover" src={companyBg} alt="" />
          <div
            className="absolute inset-0 bg-gray-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
            Beneficios para tu empresa.
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            Transforma tu empresa en un lugar de trabajo excepcional fomentando
            la salud financiera de tus colaboradores.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="relative z-10 mx-auto -mt-32 max-w-7xl px-6 pb-32 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col rounded-2xl bg-white shadow-xl"
            >
              <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-indigo-600 p-5 shadow-lg">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Company;
