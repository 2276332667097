import cx from "classnames";

export default function ButtonCustom({
  backgroundColor = ["bg-indigo-600"],
  border = ["border-none"],
  width,
  margin,
  visibility,
  padding = ["px-3 py-2"],
  disabled = false,
  textColor = ["text-white"],
  borderRadius = ["rounded-md"],
  hover = ["hover:bg-indigo-500", "hover:text-white"],
  onClick,
  type = "button",
  children,
  icon,
  iconPosition = "left",
  position = ["flex"],
  textAlign = "justify-between",
}) {
  const buttonClassNames = cx(
    position,
    "items-center gap-x-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
    backgroundColor,
    border,
    width,
    margin,
    visibility,
    padding,
    disabled && "opacity-50 cursor-not-allowed",
    textColor,
    borderRadius,
    hover
  );
  return (
    <button
      type={type}
      className={buttonClassNames}
      disabled={disabled}
      {...(onClick && { onClick: onClick })}
    >
      <div className={cx("flex", textAlign, "w-full", "items-center")}>
        {icon && iconPosition === "left" && icon}
        {children}
        {icon && iconPosition === "right" && icon}
      </div>
    </button>
  );
}
