import React, { useState, useEffect } from "react";
import CustomizedBreadcrumbs from "components/breadCrumbs/CustomizedBreadcrumbs";
import CreateUser from "common/Employee/CreateUser";
import userService from "services/user.service";
import { toast } from "react-toastify";
import adminService from "services/admin.service";
import { useNavigate } from "react-router-dom";

function ContentCreateEmployee() {
  const breadcrumbs = [
    {
      underline: "hover",
      key: "1",
      name: "Usuarios",
      link: true,
    },
    {
      link: false,
      key: "2",
      name: "Crear",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setLoading(true);

    let missingFields = false;
    if (data.bank && (!data.accountType || !data.accountNumber)) {
      missingFields = true;
    }
    if (data.accountType && (!data.accountNumber || !data.bank)) {
      missingFields = true;
    }
    if (data.accountNumber && (!data.accountType || !data.bank)) {
      missingFields = true;
    }

    if (missingFields) {
      setLoading(false);
      toast.error(
        "La información bancaria no puede estar vacía. Por favor ingrese todos los campos de la cuenta bancaria."
      );
      return;
    }

    userService
      .createUser(data)
      .then((res) => {
        if (res?.status !== 201) {
          throw new Error(res.message);
        }
        const { data } = res;
        toast.success(data);
        setLoading(false);
        navigate(`/dashboard/admin/usuarios`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        navigate(`/dashboard/admin/usuarios`);
      });
  };

  useEffect(() => {
    adminService
      .getAllCompanies()
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        const companyData = data.companies;

        setCompanies(companyData);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`/dashboard/admin/usuarios`);
      });
  }, []);

  return (
    <div>
      <div className="mt-2">
        <CustomizedBreadcrumbs elements={breadcrumbs} />
      </div>
      <div className="mt-4 mb-4">
        <h1 className="text-xl font-bold">Crear Usuario</h1>
      </div>
      <CreateUser
        isAdmin={true}
        onSubmit={onSubmit}
        companies={companies}
        loading={loading}
      />
    </div>
  );
}

export default ContentCreateEmployee;
