import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

let validPhoneNumber = false;
const validatePhoneNumber = (inputNumber, country, isDirty, phoneLength) => {
  if (
    inputNumber &&
    inputNumber?.replace(country.dialCode, "")?.trim() === ""
  ) {
    validPhoneNumber = false;

    return false;
  }
  if (inputNumber.length < phoneLength) {
    validPhoneNumber = false;
    return false;
  }

  const inputString = inputNumber.toString();
  const sliceInput = inputString.slice(0, 2);
  if (sliceInput !== "57") {
    validPhoneNumber = false;
    return false;
  }

  const firstInt = inputString.slice(2, 3);
  if (firstInt !== "3") {
    validPhoneNumber = false;
    return false;
  }
  validPhoneNumber = true;
  return true;
};

function InputPhone({ control, errors, hasHeight }) {
  return (
    <>
      <Controller
        name="phone"
        control={control}
        render={(props) => {
          return (
            <PhoneInput
              onChange={(e) => {
                props.field.onChange(e);
              }}
              inputProps={{
                id: "phone",
                autoComplete: "none",
                "data-testid": "input-id",
              }}
              inputClass={`w-100 ${hasHeight ? `${hasHeight}` : ""}`}
              country={"co"}
              onlyCountries={["co"]}
              enableAreaCodes={["co"]}
              placeholder={"+57 3XX XXX XXX"}
              value={props.field.value}
              isValid={(inputNumber, country) => {
                return validatePhoneNumber(
                  inputNumber,
                  country,
                  props.formState.isDirty,
                  12
                );
              }}
              specialLabel=""
            />
          );
        }}
        rules={{
          validate: () => validPhoneNumber,
        }}
      />
      {errors?.phone?.type === "validate" && (
        <p className="text-red-400">El número de celular no es válido</p>
      )}
    </>
  );
}

export default InputPhone;
