import React, { useRef } from "react";
import { Button } from "components/button/Button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputPhone from "components/formComponent/InputPhone";
import InputCurrency from "components/formComponent/InputCurrency";
import { ccType } from "const/documentType";
import Loading from "components/Loading";
import { bankInformation, displayAccountTypeInfo } from "const/account";

function CreateUser({ isAdmin, onSubmit, companies, loading }) {
  // Imports
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  // Functions
  const cancelCreation = () => {
    navigate(-1);
  };

  const salaryFull = useRef({});
  salaryFull.current = watch("fullIncome", "");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <div className="bg-white p-4 rounded mb-8">
          <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
            <h2 className="text-lg">Información Personal</h2>
          </div>
          <div className="grid md:grid-cols-2 md:gap-4">
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="firstName">Primer Nombre (*)</label>
                <input
                  {...register("firstName", {
                    required: true,
                  })}
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Ingresa el primer nombre"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
                {errors?.firstName?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
              <div className="mb-4">
                <label for="middleName">Segundo Nombre</label>
                <input
                  {...register("middleName")}
                  type="text"
                  name="middleName"
                  id="middleName"
                  placeholder="Ingresa el segundo nombre"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
              </div>

              <div className="mb-4">
                <label for="lastName">Apellidos (*)</label>
                <input
                  {...register("lastName", {
                    required: true,
                  })}
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Ingresa los apellidos"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
                {errors?.lastName?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
              <div className="mb-4">
                <label for="email">Correo Electrónico (*)</label>
                <input
                  {...register("email", {
                    required: true,
                  })}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Correo Electrónico"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
                {errors?.email?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="typeOfDocument">Tipo de documento (*)</label>
                <select
                  className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
                  {...register("typeOfDocument", {
                    required: true,
                  })}
                >
                  <option value="" selected disabled>
                    Por favor seleccione el tipo de documento
                  </option>
                  {ccType.map((data) => (
                    <option value={data.val}>{data.name}</option>
                  ))}
                </select>
                {errors?.typeOfDocument?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
              <div className="mb-4">
                <label for="documentNumber">Número de Documento (*)</label>
                <input
                  {...register("documentNumber", {
                    required: true,
                    pattern: /^[0-9]+$/,
                  })}
                  type="text"
                  name="documentNumber"
                  id="documentNumber"
                  placeholder="Número de Documento de Identidad"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
                {errors?.documentNumber?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
                {errors?.documentNumber?.type === "pattern" && (
                  <p className="text-red-400">
                    El documento de identidad solo puede contener números
                  </p>
                )}
              </div>
              {isAdmin && (
                <div className="mb-4">
                  <label for="company">Empresa (*)</label>
                  <select
                    className="bg-white w-full select-padding overflow-hidden items-center bor-rad ruj-2 transition-all"
                    {...register("company", {
                      required: true,
                    })}
                  >
                    <option value="" selected disabled>
                      Por favor seleccione una empresa
                    </option>
                    {companies.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                  </select>
                  {errors?.company?.type === "required" && (
                    <p className="text-red-400">Este campo es obligatorio</p>
                  )}
                </div>
              )}
              <div className="mb-4">
                <label for="phone" className="mb-1">
                  Celular (*)
                </label>
                <InputPhone
                  control={control}
                  errors={errors}
                  hasHeight={"input-phone-height"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded mb-8">
          <div className=" pb-2 mb-6 border-b-2 border-pink-600 w-full">
            <h2 className="text-lg">Información Bancaria</h2>
          </div>
          <div className="grid md:grid-cols-2 md:gap-4">
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="accountType">Tipo de Cuenta</label>
                <select
                  className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                  {...register("accountType", {
                    required: false,
                  })}
                >
                  <option value="" selected disabled>
                    Por favor seleccione un tipo de cuenta
                  </option>
                  {Object.keys(displayAccountTypeInfo).map((key, index) => {
                    return (
                      <option value={key}>{displayAccountTypeInfo[key]}</option>
                    );
                  })}
                </select>
                {errors?.accountType?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
              <div className="mb-4">
                <label for="accountNumber">Número de cuenta</label>
                <input
                  {...register("accountNumber", {
                    required: false,
                    pattern: /^[0-9]+$/,
                  })}
                  type="text"
                  name="accountNumber"
                  id="accountNumber"
                  placeholder="Ingresa el número de cuenta"
                  className="bg-white w-full p-2 overflow-hidden items-center bor-rad ruj-2 transition-all"
                />
                {errors?.accountNumber?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
                {errors?.accountNumber?.type === "pattern" && (
                  <p className="text-red-400">
                    El número de cuenta solo puede contener números
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="bank">Banco</label>
                <select
                  className="bg-white w-full p-2 overflow-hidden 
                    items-center bor-rad ruj-2 transition-all focus-within:shadow-lg"
                  {...register("bank", {
                    required: false,
                  })}
                >
                  <option value="" selected disabled>
                    Por favor seleccione un banco
                  </option>
                  {Object.entries(bankInformation).map(([key, value]) => {
                    return <option value={key}>{value}</option>;
                  })}
                </select>
                {errors?.bank?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded mb-8">
          <div className="pb-2 mb-6 border-b-2 border-pink-600 w-full">
            <h2 className="text-lg">Salario</h2>
          </div>
          <div className="grid md:grid-cols-2 md:gap-4">
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="fullIncome">Salario Bruto (*)</label>
                <InputCurrency
                  control={control}
                  name={"fullIncome"}
                  placeHolder={"Salario Bruto"}
                />
                {errors?.fullIncome?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
              </div>
            </div>
            <div className="col-span-1 ">
              <div className="mb-4">
                <label for="netSalary">Salario Neto (*)</label>
                <InputCurrency
                  control={control}
                  name={"netSalary"}
                  placeHolder={"Salario Neto"}
                  fullSalary={salaryFull}
                />
                {errors?.netSalary?.type === "required" && (
                  <p className="text-red-400">Este campo es obligatorio</p>
                )}
                {errors?.netSalary?.type === "validate" && (
                  <p className="text-red-400">
                    El salario neto no puedo ser mayor que el salario bruto
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {loading && <Loading />}
        <div className="flex justify-end">
          <div className="mr-4">
            <Button
              onClick={cancelCreation}
              disabled={loading}
              buttonSize="btn--wide-button"
              buttonColor="thrid-button"
              classesCustom={` ${loading ? "opacity-50" : ""} `}
            >
              Cancelar
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              buttonSize="btn--wide-button"
              buttonColor="second-button"
              classesCustom={` ${loading ? "opacity-50" : ""} `}
            >
              Crear Usuario
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateUser;
