import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdOutlineClose } from "react-icons/md";
import { Button } from "../button/Button";

const style = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function InformationModal({ open, setOpen, info, title }) {
  const handleClose = () => setOpen(false);
  const sizeReal = info.length;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="absolute rounded-md w-4/5 md:w-1/3">
          <div className="flex justify-between p-4 bg-gray-100 rounded-t-md align-items-as">
            <p className="text-base">{title}</p>
            <MdOutlineClose className="text-base" onClick={handleClose} />
          </div>
          <div className="px-6 py-2 text-base">
            <div className="w-full">
              <div className="overflow-x-scroll md:overflow-auto">
                {info.map((elem, index) => (
                  <div
                    className={`${
                      index === sizeReal - 1 ? "" : "border-b-2 border-gray-200"
                    } md:w-auto`}
                  >
                    <div className="py-4">
                      <div className="grid md:grid-cols-2">
                        <div className="col-span-1">
                          <p className="font-semibold flex-1">{elem.field}:</p>
                        </div>
                        <div className="col-span-1">
                          <p className="flex-1">{elem.val}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end  p-4 bg-gray-100 rounded-b-md ">
            <div className="md:mr-4">
              <Button
                buttonSize="btn--wide-button"
                buttonColor="second-button"
                classesCustom="w-full md:w-auto"
                onClick={handleClose}
              >
                OK
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default InformationModal;
