import React, { useEffect, useState, useCallback } from "react";
import MainFill from "./MainFill";
import MainAdvance from "./MainAdvance";
import userService from "services/user.service";
import advancesService from "services/advances.service";
import formatFunction from "const/formatFunction";
import img1 from "assets/images/igual2.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { styleModal } from "const/modalStyle";
import { MdOutlineClose } from "react-icons/md";
import { Button } from "components/button/Button";
import Loading from "components/Loading";
import { error_message } from "const/messages";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, setAdvanceInitial } from "slices/advanceUser";
import { logout } from "slices/auth";

import { onPercentageChange } from "slices/advanceUser";

import { ipLink } from "config/links";

function MainWrapper() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [ip, setIp] = useState("");

  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const getHomePage = useCallback(() => {
    setLoading(true);
    userService
      .getHomePage()
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error(res.message);
        }
        const { data } = res;
        dispatch(setUserInfo(data));

        const info = {
          netSalary: data.user.netSalary,
          maxAdvanceToday: data.maxAdvanceToday,
        };
        dispatch(setAdvanceInitial(info));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(error_message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  useEffect(() => {
    fetch(ipLink)
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error(error));
  }, []);

  const {
    contentUser,
    contentBank,
    contentCompany,
    contentActivity,
    maxAdvance,
    advanceAmount,
    fee,
    totalAmount,
    isDisabled,
    percentage,
  } = useSelector((state) => state.advanceUser);

  const ConfirmationModal = () => {
    return (
      <Modal
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} className="absolute rounded-md w-4/5 md:w-1/3">
          <div className="flex flex-col rounded-t-md">
            <div className="flex p-2 bg-gray-100 rounded-t-md justify-between items-center">
              <p className="text-sm text-gray-800">Confirmación de adelanto</p>
              <MdOutlineClose
                className="text-lg md:text-2xl"
                onClick={handleCloseConfirmation}
              />
            </div>
            <div className="flex p-6 items-center flex-col">
              <div>
                <div className="res-circle-orange mb-4">
                  <div className="circle-txt-orange font-bold">
                    <img
                      className="relative h-auto w-24"
                      src={img1}
                      alt="Logo Purple Small"
                    />
                  </div>
                </div>
              </div>
              <p className="font-semibold text-lg">¿Estás seguro?</p>
              <p className="my-2 text-justify md:text-left">
                Vas a recibir un adelanto de {advanceAmount} COP a&nbsp; tu
                número de cuenta&nbsp;
                <InputFormInfo
                  val={contentBank?.accountNumber}
                  type={"ACCOUNT"}
                />
              </p>
              <Button
                buttonSize="btn--wide-button"
                buttonColor="purple-btn"
                classesCustom="w-full mb-4"
                onClick={createAdvance}
              >
                Si, seguro
              </Button>
              <Button
                onClick={handleCloseConfirmation}
                buttonColor="outline-orange-btn"
                classesCustom="w-full"
              >
                No, corregir monto
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const createAdvance = () => {
    setOpenConfirmation(false);
    setLoading(true);
    const advance = {
      bankAccount: contentBank.id,
      company: contentCompany.id,
      socialReason: contentCompany.socialReason,
      percentage: contentCompany.percentageOfAdvance,
      netSalary: contentUser.netSalary,
      user: contentUser.id,
      amount: Number(advanceAmount.replace(/[^0-9]+/g, "")),
      comissionFee: Number(fee.replace(/[^0-9]+/g, "")),
      principalAmount: totalAmount,
      monthlyDynamicNetSalary: contentUser.monthlyDynamicNetSalary,
      contentUser: contentUser,
      ip: ip,
    };

    advancesService
      .createAdvance(advance)
      .then((res) => {
        if (res.status === 403) {
          dispatch(logout());
          return <Navigate to={"/login"} replace />;
        }

        if (res.status !== 201) {
          throw new Error(
            "No fue posible crear el adelanto. Por favor intente más tarde."
          );
        }

        getHomePage();
        dispatch(onPercentageChange(100));
        toast.success("Has solicitado un adelanto!.");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <ConfirmationModal />
      <div className="grid lg:grid-cols-3 gap-4">
        <MainAdvance
          dataUser={contentUser}
          maxAdvance={maxAdvance}
          valueAdvance={advanceAmount}
          dataCompany={contentCompany}
          finalAmount={totalAmount}
          fee={fee}
          setOpenConfirmation={setOpenConfirmation}
          isDisabled={isDisabled}
          percentageInverse={percentage}
          dataBank={contentBank}
        />
        <MainFill
          dataUser={contentUser}
          dataBank={contentBank}
          activity={contentActivity}
          dataCompany={contentCompany}
        />
      </div>
    </>
  );
}

function InputFormInfo({ val, type }) {
  const format = type ? formatFunction.formatInput(type, val) : val;
  return format;
}

export default MainWrapper;
